import { DefaultCellTypes } from '@silevis/reactgrid';
import React from 'react';
import { WdpVersionReadDTO } from '../../../api/dto';
import { DropdownsCell, MultiDropdownCell } from '../../../components/spreadsheet/MultiDropdownCellTemplate';
import { PersonCell } from '../../../components/spreadsheet/PersonAutocompleteTemplate';

export interface MenuEntry {
    label: string;
    Icon: React.FC;
    onClick: () => void;
    tooltip?: string;
}

export interface TableHeader {
    name: string;
    label: string;
    width?: number;
    readonly?: boolean | ((row: any) => boolean);
    type?: CellTypes['type'];
    customType?: FieldMetadata;
    idKey?: string;
}

export interface WdpTabsProps {
    wdp: WdpVersionReadDTO;
    setWdp: React.Dispatch<React.SetStateAction<WdpVersionReadDTO>>;
}

export type Fields = 'teams' | 'participants' | 'positions';

export type CellTypes = DefaultCellTypes | DropdownsCell | PersonCell | MultiDropdownCell;

export type WdpDataKey = keyof {
    [K in keyof WdpVersionReadDTO as WdpVersionReadDTO[K] extends unknown[] ? K : never]: unknown; // Value doesn't matter
} &
    string;

export type WdpIdKey<T extends WdpDataKey> = keyof {
    [K in keyof WdpVersionReadDTO[T][number] as WdpVersionReadDTO[T][number][K] extends number ? K : never]: unknown;
} &
    string;

export interface FieldMetadata {
    label: string;
    dataKey: string;
    idKey: string;
    labelKey: string;
}

interface FieldMetadataInternal<T extends WdpDataKey, U extends WdpIdKey<T>> extends FieldMetadata {
    dataKey: T;
    idKey: U;
}

const field = <T extends WdpDataKey, U extends WdpIdKey<T>>(o: FieldMetadataInternal<T, U>) => o;

export const fieldsMetadata = {
    teams: field({
        label: 'team',
        dataKey: 'wdpWorkingTeams',
        idKey: 'teamsIdx',
        labelKey: 'name',
    }),
    participants: field({
        label: 'participant',
        dataKey: 'wdpParticipants',
        idKey: 'participantsIdx',
        labelKey: 'person.searchLabel',
    }),
    positions: field({
        label: 'working position',
        dataKey: 'wdpWorkingPositions',
        idKey: 'workingPositionsIdx',
        labelKey: 'description',
    }),
} as const;
