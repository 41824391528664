import { ArrowDropDown, Check, EditOff, Policy, Refresh } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Chip,
    Container,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DocumentType, PersonReadDTO } from '../../api/dto';
import { useAdminRole } from '../../hooks/useAdminRole';
import { useDeviceType } from '../../hooks/useDeviceType';
import { formatDateTime } from '../../lib/date';
import { DocumentStatus } from '../../lib/status';
import { AuditData } from '../audit/AuditData';
import { StatusChip } from '../StatusChip';
import { DocumentButtonsStack } from './DocumentButtonsStack';

interface DocumentVersionSelectProps {
    currentVersionNumber: number;
    versions: { versionNumber: number; status: DocumentStatus }[];
    onSelect: (versionNumber: number) => void;
}

const DocumentVersionSelect: React.FC<DocumentVersionSelectProps> = ({ currentVersionNumber, versions, onSelect }) => {
    const intl = useIntl();
    const { isMobile } = useDeviceType();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOptionClick = (versionNumber: number) => {
        if (versionNumber !== currentVersionNumber) {
            onSelect(versionNumber);
        }
        handleClose();
    };
    const sortedVersions = useMemo(
        () => versions.slice().sort((a, b) => b.versionNumber - a.versionNumber),
        [versions]
    );
    const latestVersionNumber: number | undefined = useMemo(
        () => sortedVersions.map((v) => v.versionNumber)[0],
        [sortedVersions]
    );
    return (
        <>
            {versions.length > 1 && (
                <Button size="small" color="inherit" onClick={handleClick} endIcon={<ArrowDropDown />}>
                    {!isMobile
                        ? intl.formatMessage({ id: 'document.versionN' }, { version: currentVersionNumber })
                        : currentVersionNumber}
                </Button>
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: 48 * 4.5,
                        },
                    },
                }}
            >
                {sortedVersions.map((option) => (
                    <MenuItem
                        key={option.versionNumber}
                        selected={option.versionNumber === currentVersionNumber}
                        onClick={() => handleOptionClick(option.versionNumber)}
                        sx={{ justifyContent: 'flex-end' }}
                    >
                        <ListItemIcon sx={{ flexGrow: 1 }}>
                            {option.versionNumber === currentVersionNumber && <Check />}
                        </ListItemIcon>
                        <Stack direction="row" spacing={2}>
                            <StatusChip {...option.status} size="small" />
                            <span>{option.versionNumber}</span>
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

interface DocumentEditabilityChipProps {
    editable: boolean;
}

const DocumentEditabilityChip: React.FC<DocumentEditabilityChipProps> = ({ editable }) => {
    const intl = useIntl();
    return !editable ? (
        <Chip icon={<EditOff />} variant="outlined" label={intl.formatMessage({ id: 'document.cannotEdit' })} />
    ) : null;
};

interface DocumentHeaderProps {
    documentType: DocumentType;
    documentId: number | null;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
    icon: React.ReactNode;
    title: string;
    version?: DocumentVersionSelectProps;
    status?: DocumentStatus;
    creation?: { createdBy: PersonReadDTO | null; createdDate: string | Date | null };
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
}

export const DocumentHeader = ({
    documentType,
    documentId,
    Main,
    Left,
    Right,
    icon,
    title,
    version,
    status,
    creation,
    editable,
    isLoading,
    onRefreshClick,
}: DocumentHeaderProps): React.ReactElement => {
    const intl = useIntl();
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const { isMobile } = useDeviceType();
    const isOlderVersion = useMemo(
        () => (version ? version.versions.some((v) => v.versionNumber > version.currentVersionNumber) : false),
        [version]
    );
    const [openAuditDialog, setOpenauditDialog] = useState(false);
    const isAdmin = useAdminRole();

    const renderRefreshButton = () => (
        <Tooltip title={intl.formatMessage({ id: 'document.action.refresh' })}>
            <span>
                <IconButton size="small" onClick={onRefreshClick} disabled={isLoading}>
                    <Refresh />
                </IconButton>
            </span>
        </Tooltip>
    );

    const renderAuditButton = () =>
        isAdmin &&
        documentId !== null && (
            <>
                <Tooltip title={intl.formatMessage({ id: 'document.action.audit' })}>
                    <span>
                        <IconButton size="small" onClick={() => setOpenauditDialog(true)}>
                            <Policy />
                        </IconButton>
                    </span>
                </Tooltip>
                {openAuditDialog && (
                    <AuditData
                        documentType={documentType}
                        documentId={documentId}
                        open={openAuditDialog}
                        onClose={() => setOpenauditDialog(false)}
                    />
                )}
            </>
        );

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ px: 2, pb: 1 }}>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={{ xs: 1, md: 4 }}
                    alignItems={{ md: 'center' }}
                    justifyContent="space-between"
                    overflow="hidden"
                    width="100%"
                >
                    <Stack direction="row" spacing={{ xs: 1, sm: 2 }} alignItems="center" overflow="hidden">
                        <Stack direction="row" spacing={1} alignItems="center">
                            {icon}
                            <Typography sx={{ whiteSpace: 'nowrap' }}>{title}</Typography>
                            {!!version && <DocumentVersionSelect {...version} />}
                        </Stack>
                        {status !== undefined && <StatusChip {...status} size="small" sx={{ minWidth: 0 }} />}
                    </Stack>
                    {creation !== undefined && creation.createdBy !== null && creation.createdDate && (
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: { xs: 9, sm: '0.75rem' },
                                direction: { xs: 'rtl', sm: 'ltr' },
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: 'left',
                            }}
                        >
                            <FormattedMessage
                                id="document.createdBy"
                                values={{
                                    person: creation.createdBy.searchLabel,
                                    date: formatDateTime(new Date(creation.createdDate), intl),
                                }}
                            />
                        </Typography>
                    )}
                </Stack>
                <Stack direction="row" spacing={1}>
                    {!isMobile && renderAuditButton()}
                    {!isMobile && <Box minHeight={34}>{onRefreshClick !== undefined && renderRefreshButton()}</Box>}
                </Stack>
            </Stack>
            <Divider />
            <Container disableGutters sx={{ mt: { xs: 1, sm: 2 }, mb: { xs: -1, sm: 0 }, px: 2 }}>
                <DocumentButtonsStack disabled={isLoading}>
                    <Stack direction="column" flexGrow={1} spacing={{ xs: 1, sm: 2 }}>
                        <Stack direction="row" justifyContent="space-between" spacing={1}>
                            <Stack direction="row" spacing={1} alignItems="baseline">
                                {!isXs && <DocumentEditabilityChip editable={editable} />}
                                {Left}
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="baseline">
                                {Right}
                                {isMobile && renderAuditButton()}
                                {isMobile && renderRefreshButton()}
                            </Stack>
                        </Stack>
                        {isOlderVersion && (
                            <Alert severity="warning">{intl.formatMessage({ id: 'document.oldVersion' })}</Alert>
                        )}
                        {isXs && <DocumentEditabilityChip editable={editable} />}
                        {Main}
                    </Stack>
                </DocumentButtonsStack>
            </Container>
        </>
    );
};
