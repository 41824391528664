import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getActivity } from '../../../api/activities';
import { getLockout } from '../../../api/documents/lockouts';
import { DocumentType } from '../../../api/dto';
import { LockoutForm } from '../../../forms/documents/LockoutForm';
import { LockoutPageVariant } from './LockoutPageVariant';

interface LockoutPageProps {
    variant: LockoutPageVariant;
}

export const LockoutPage: React.FC<LockoutPageProps> = ({ variant }) => {
    const { id: lockoutId } = useParams();
    const [params] = useSearchParams();

    const {
        data: lockoutData,
        isLoading: lockoutDataLoading,
        isError: lockoutDataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.LOK, lockoutId], () => getLockout({ id: String(lockoutId) }), {
        enabled: lockoutId !== undefined,
        onError: () => toast.error('There was an error fetching the Lockout'),
    });

    const activityId = variant === LockoutPageVariant.CREATE ? String(params.get('activity')) : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled:
            (variant === LockoutPageVariant.CREATE || variant === LockoutPageVariant.CLONE) && activityId !== undefined,
    });

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if ((lockoutDataLoading && lockoutId !== undefined) || (isActivityDataLoading && activityId !== undefined)) {
        return <LinearProgress />;
    } else if (lockoutDataFailed || activityDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                There has been an error fetching document data
            </Alert>
        );
    } else if (
        (variant === LockoutPageVariant.CREATE && activityData) ||
        (variant === LockoutPageVariant.CLONE && lockoutData && activityData) ||
        (variant === LockoutPageVariant.READ && lockoutData)
    ) {
        return (
            <LockoutForm
                data={
                    variant === LockoutPageVariant.CREATE
                        ? { variant, activityData: activityData! }
                        : variant === LockoutPageVariant.CLONE
                        ? { variant, activityData: activityData!, lockoutData: lockoutData! }
                        : { variant, lockoutData: lockoutData! }
                }
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};
