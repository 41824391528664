import { VicStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const VicStatusColorMapping: Record<VicStatusCode, StatusColor> = {
    [VicStatusCode.VIC_STATUS_NEW]: StatusColor.COLOR_PRIMARY,
    [VicStatusCode.VIC_STATUS_SAVE]: StatusColor.COLOR_PRIMARY,
    [VicStatusCode.VIC_STATUS_EDH_APPROVAL]: StatusColor.COLOR_WARNING,
    [VicStatusCode.VIC_STATUS_APPROVED]: StatusColor.COLOR_SUCCESS,
    [VicStatusCode.VIC_STATUS_REJECTED]: StatusColor.COLOR_ERROR,
    [VicStatusCode.VIC_STATUS_CANCELLED]: StatusColor.COLOR_ERROR,
};
