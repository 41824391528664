import { TableBody, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { getRoute, ROUTES } from '../../../routes/routes';
import { ImpactLink } from '../../ImpactLink';

interface AccessGridBodyProps {
    rows: any[];
    prepareRow: (row: any) => void;
}
export const AccessGridBody: React.FC<AccessGridBodyProps> = ({ rows, prepareRow }) => {
    const isIdCell = (cell: any) => cell.column.Header === 'Activity';

    return (
        <TableBody>
            {rows.map((row: any) => {
                prepareRow(row);

                return (
                    <TableRow hover {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                            return (
                                <TableCell {...cell.getCellProps()} sx={{}}>
                                    {isIdCell(cell) ? (
                                        <ImpactLink
                                            to={`${getRoute({
                                                path: ROUTES.activities.view,
                                                params: { id: cell.value },
                                            })}`}
                                        >
                                            {cell.render('Cell')}
                                        </ImpactLink>
                                    ) : (
                                        <>{cell.render('Cell')}</>
                                    )}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
