import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApplicationSettingsProvider } from './components/application/ApplicationSettingsProvider';
import { EnvironmentThemeProvider } from './components/application/EnvironmentThemeProvider';
import { PageContextProvider } from './components/application/PageContextProvider';
import { ConfirmationDialogContextProvider } from './components/ConfirmationDialogContext';
import { DocumentDrawerProvider } from './components/documents/DocumentDrawerStructure';
import { ImpactHelmet } from './components/ImpactHelmet';
import { LayoutProvider } from './components/layout/LayoutProvider';
import { MapDialogContextProvider } from './components/MapDialogContext';
import { TaskContextProvider } from './components/TaskContext';
import { AuthWrapper } from './components/wrappers/AuthWrapper';
import { BackendInterceptorWrapper } from './components/wrappers/BackendInterceptorWrapper';
import { RedirectionToOIMWrapper } from './components/wrappers/RedirectionToOIMWrapper';
import { I18nProvider } from './i18n/I18nProvider';
import { ActivityPage } from './pages/activities/ActivityPage';
import { ActivitySearchPage } from './pages/activities/ActivitySearchPage';
import { ClustersPage } from './pages/activities/ClustersPage';
import { FavouritesPage } from './pages/activities/FavouritesPage';
import { CrashPage } from './pages/CrashPage';
import { DIMRPage } from './pages/documents/dimr/DIMRPage';
import { DIMRPageVariant } from './pages/documents/dimr/DIMRPageVariant';
import { DIMRSearchPage } from './pages/documents/dimr/DIMRSearchPage';
import { FirePermitPage } from './pages/documents/firePermit/FirePermitPage';
import { FirePermitPageVariant } from './pages/documents/firePermit/FirePermitPageVariant';
import { FirePermitSearchPage } from './pages/documents/firePermit/FirePermitSearchPage';
import { IS37Page } from './pages/documents/is37/IS37Page';
import { IS37PageVariant } from './pages/documents/is37/IS37PageVariant';
import { IS37SearchPage } from './pages/documents/is37/IS37SearchPage';
import { LockoutPage } from './pages/documents/lockout/LockoutPage';
import { LockoutPageVariant } from './pages/documents/lockout/LockoutPageVariant';
import { LockoutSearchPage } from './pages/documents/lockout/LockoutSearchPage';
import { NdcSearchPage } from './pages/documents/ndc/NdcSearchPage';
import { NdcPageVariant, NoteDeCoupurePage } from './pages/documents/ndc/NoteDeCoupurePage';
import { VicPage } from './pages/documents/vic/VicPage';
import { VicPageVariant } from './pages/documents/vic/VicPageVariant';
import { VicSearchPage } from './pages/documents/vic/VicSearchPage';
import { WdpPage } from './pages/documents/wdp/WdpPage';
import { WdpPageVariant } from './pages/documents/wdp/WdpPageVariant';
import { WdpSearchPage } from './pages/documents/wdp/WdpSearchPage';
import { Homepage } from './pages/Homepage';
import { NewsPage } from './pages/NewsPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { AccessControlPage } from './pages/reports/AccessControlPage';
import { LocationsPage } from './pages/reports/LocationsPage';
import { RadiationDosesPage } from './pages/reports/RadiationDosesPage';
import { TasksPage } from './pages/TasksPage';
import { ROUTES } from './routes/routes';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            cacheTime: 0,
            refetchOnWindowFocus: false,
        },
    },
});

const router = createBrowserRouter([
    {
        element: (
            <QueryClientProvider client={queryClient}>
                <BackendInterceptorWrapper>
                    <AuthWrapper>
                        <ApplicationSettingsProvider>
                            <EnvironmentThemeProvider>
                                <I18nProvider>
                                    <RedirectionToOIMWrapper>
                                        <HelmetProvider>
                                            <PageContextProvider>
                                                <TaskContextProvider>
                                                    <CssBaseline />
                                                    <ToastContainer position="bottom-right" />
                                                    <LayoutProvider>
                                                        <MapDialogContextProvider>
                                                            <ConfirmationDialogContextProvider>
                                                                <DocumentDrawerProvider>
                                                                    <ImpactHelmet />
                                                                    <Outlet />
                                                                </DocumentDrawerProvider>
                                                            </ConfirmationDialogContextProvider>
                                                        </MapDialogContextProvider>
                                                    </LayoutProvider>
                                                </TaskContextProvider>
                                            </PageContextProvider>
                                        </HelmetProvider>
                                    </RedirectionToOIMWrapper>
                                </I18nProvider>
                            </EnvironmentThemeProvider>
                        </ApplicationSettingsProvider>
                    </AuthWrapper>
                </BackendInterceptorWrapper>
            </QueryClientProvider>
        ),
        children: [
            { path: ROUTES.homepage, element: <Homepage /> },
            { path: ROUTES.activities.search, element: <ActivitySearchPage /> },
            { path: ROUTES.activities.favourites, element: <FavouritesPage /> },
            { path: ROUTES.activities.clusters, element: <ClustersPage /> },
            { path: ROUTES.activities.create, element: <ActivityPage key={ROUTES.activities.create} /> },
            { path: ROUTES.activities.view, element: <ActivityPage key={ROUTES.activities.view} /> },
            { path: ROUTES.news, element: <NewsPage /> },
            { path: ROUTES.dimr.search, element: <DIMRSearchPage /> },
            { path: ROUTES.dimr.view, element: <DIMRPage variant={DIMRPageVariant.READ} /> },
            { path: ROUTES.dimr.viewLatest, element: <DIMRPage variant={DIMRPageVariant.READ} /> },
            { path: ROUTES.dimr.create, element: <DIMRPage variant={DIMRPageVariant.CREATE} /> },
            { path: ROUTES.wdp.search, element: <WdpSearchPage /> },
            { path: ROUTES.wdp.create, element: <WdpPage variant={WdpPageVariant.CREATE} /> },
            { path: ROUTES.wdp.view, element: <WdpPage variant={WdpPageVariant.READ} /> },
            { path: ROUTES.wdp.viewLatest, element: <WdpPage variant={WdpPageVariant.READ} /> },
            { path: ROUTES.is37.create, element: <IS37Page variant={IS37PageVariant.CREATE} /> },
            { path: ROUTES.vic.search, element: <VicSearchPage /> },
            { path: ROUTES.vic.create, element: <VicPage variant={VicPageVariant.CREATE} /> },
            { path: ROUTES.vic.view, element: <VicPage variant={VicPageVariant.READ} /> },
            { path: ROUTES.lockout.search, element: <LockoutSearchPage /> },
            { path: ROUTES.lockout.create, element: <LockoutPage variant={LockoutPageVariant.CREATE} /> },
            { path: ROUTES.lockout.clone, element: <LockoutPage variant={LockoutPageVariant.CLONE} /> },
            { path: ROUTES.lockout.view, element: <LockoutPage variant={LockoutPageVariant.READ} /> },
            { path: ROUTES.firePermit.search, element: <FirePermitSearchPage /> },
            { path: ROUTES.firePermit.create, element: <FirePermitPage variant={FirePermitPageVariant.CREATE} /> },
            { path: ROUTES.firePermit.view, element: <FirePermitPage variant={FirePermitPageVariant.READ} /> },
            { path: ROUTES.firePermit.clone, element: <FirePermitPage variant={FirePermitPageVariant.CLONE} /> },
            { path: ROUTES.is37.search, element: <IS37SearchPage /> },
            { path: ROUTES.is37.create, element: <IS37Page variant={IS37PageVariant.CREATE} /> },
            { path: ROUTES.is37.clone, element: <IS37Page variant={IS37PageVariant.CLONE} /> },
            { path: ROUTES.is37.view, element: <IS37Page variant={IS37PageVariant.READ} /> },
            { path: ROUTES.noteDeCoupure.search, element: <NdcSearchPage /> },
            { path: ROUTES.noteDeCoupure.create, element: <NoteDeCoupurePage variant={NdcPageVariant.CREATE} /> },
            { path: ROUTES.noteDeCoupure.clone, element: <NoteDeCoupurePage variant={NdcPageVariant.CLONE} /> },
            { path: ROUTES.noteDeCoupure.view, element: <NoteDeCoupurePage variant={NdcPageVariant.READ} /> },
            { path: ROUTES.tasks, element: <TasksPage /> },
            { path: ROUTES.radiationDoses, element: <RadiationDosesPage /> },
            { path: ROUTES.accessControl, element: <AccessControlPage /> },
            { path: ROUTES.locations, element: <LocationsPage /> },
            { path: ROUTES.notFound, element: <NotFoundPage /> }, // A utility route that we use when the backend returns 404
            { path: '*', element: <NotFoundPage /> }, // When no (frontend) route matches
        ],
        errorElement: <CrashPage />,
    },
]);

export const App: React.FunctionComponent = () => {
    return <RouterProvider router={router} />;
};
