import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { AccessPointDTO } from './dto';
import { GetManyPaged } from './types';

export const ACCESS_POINTS_PATH = 'access-points';

export const getAccessPoints: GetManyPaged<AccessPointDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACCESS_POINTS_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response) => response.data);
