import { isValid } from 'date-fns';
import { SpringFilterQueryBuilder as SFQB } from 'spring-filter-query-builder';
import { TFilterElement } from '../../forms/types';
import { makeActivityDefaultFilter } from '../../lib/filters/activity';
import { escapeSfqbString } from '../../lib/sfqb';
import { Nullable } from '../../lib/types';
import { FilterOperatorEnum, SearchFilter, SearchFilterTyped } from './filters';

export type DocType = 'activities';

export const filterQueryParam = 'filterBy';
export const searchQueryParam = 'searchBy';
export const pageQueryParam = 'page';

const activityFilters = [
    { label: 'New', value: 'new' },
    { label: 'Draft', value: 'draft' },
    { label: 'Saved', value: 'saved' },
    { label: 'Waiting system approval', value: 'waitingSystemApproval' },
    { label: 'Waiting for Approval', value: 'waitingForApproval' },
    { label: 'Waiting Surface Approval', value: 'waitingSurfaceApproval' },
    { label: 'Waiting Visit Safety Approval', value: 'waitingVisitSafetyApproval' },
    { label: 'Approved', value: 'approved' },
    { label: 'In progress', value: 'inProgress' },
    { label: 'Late', value: 'late' },
    { label: 'Interrupted', value: 'interrupted' },
    { label: 'Closed', value: 'closed' },
    { label: 'Cancelled', value: 'cancelled' },
    { label: 'Rejected', value: 'rejected' },
];

export const getDocType: () => DocType = () => {
    // TODO: properly implement this function
    return 'activities';
};

export const makeBaseQuery = (docType: DocType, titleSearch: string) => {
    switch (docType) {
        case 'activities':
            return makeActivityDefaultFilter(titleSearch);
        default:
            return '';
    }
};
export const addFilterToQuery = (docType: DocType, baseQuery: string, filterValue: string) => {
    switch (docType) {
        case 'activities':
            return SFQB.and(baseQuery, SFQB.equal('status.label', escapeSfqbString(filterValue))).toString();
        default:
            return '';
    }
};
export const makeQuery = (titleSearch: string, filterValue?: string) => {
    const docType: DocType = getDocType();
    const baseQuery = makeBaseQuery(docType, titleSearch);
    return filterValue ? addFilterToQuery(docType, baseQuery, filterValue) : baseQuery;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFilters = (docType: DocType) => {
    // TODO: properly implement this function
    return activityFilters;
};

export const findSelectedFilterValue = (docType: DocType) => {
    const selectedLabel = new URLSearchParams(location.search).get(filterQueryParam);
    let selectedValue: number | null = null;
    // TODO: properly set filters const and properly scan it
    const filters = getFilters(docType);

    for (const i in filters) {
        const idx: number = parseInt(i);
        if (filters[idx].label === selectedLabel) {
            selectedValue = idx;
            break;
        }
    }
    return selectedValue;
};

export const sanitizeFiltersBySchema = (
    filters: Nullable<SearchFilter>[] | undefined | null,
    filterSchemas: TFilterElement[]
) =>
    (filters ?? [])
        .filter((filter): filter is SearchFilter => {
            return (
                filter.field !== null &&
                filter.operator !== null &&
                (!!filter.value ||
                    typeof filter.value === 'boolean' ||
                    [FilterOperatorEnum.IS_EMPTY, FilterOperatorEnum.IS_NOT_EMPTY].includes(filter.operator)) &&
                !(filter.value instanceof Date && !isValid(filter.value))
            );
        })
        .flatMap((filter): SearchFilterTyped[] => {
            const schema = filterSchemas.find((f) => f.id === filter.field);
            if (schema !== undefined) {
                const valueGetter = schema.valueGetter ?? ((original: any) => original);
                const newValue = Array.isArray(filter.value)
                    ? (filter.value as any[]).map((v) => valueGetter(v))
                    : valueGetter(filter.value);
                if (Array.isArray(newValue) && newValue.length === 0) {
                    return [];
                }
                const newFilter = { ...filter, value: newValue, type: schema.type };
                return [newFilter];
            } else {
                // Could happen with saved filter if we remove a feature
                return [];
            }
        });
