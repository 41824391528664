import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingPage } from '../../pages/LoadingPage';
import { findOIMRedirectionFor, REDIRECT_NOT_IMPLEMENTED_TO_OIM } from '../../routes/oimRoutes';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';

interface RedirectionToOIMProps {
    children: React.ReactNode;
}

export const RedirectionToOIMWrapper: React.FC<RedirectionToOIMProps> = ({ children }) => {
    const location = useLocation();
    const settings = useApplicationSettings();
    const redirection = useMemo(
        () =>
            !REDIRECT_NOT_IMPLEMENTED_TO_OIM
                ? undefined
                : findOIMRedirectionFor(location.pathname, window.location.origin, settings),
        [location, settings]
    );

    useEffect(() => {
        if (redirection !== undefined) {
            // Redirect
            window.location.replace(redirection);
        }
    }, [redirection]);

    return redirection === undefined ? <>{children}</> : <LoadingPage />;
};
