import { Error, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';

interface DefaultAccordionProps {
    title: string;
    error?: boolean;
    icon?: React.ReactNode;
    disabled?: boolean;
    disableGutters?: boolean;
    collapsed?: boolean;
    children: React.ReactNode;
}
export const DefaultAccordion: React.FC<DefaultAccordionProps> = ({
    title,
    error,
    icon,
    disabled,
    disableGutters,
    collapsed,
    children,
}) => {
    const theme = useTheme();

    return (
        <Accordion defaultExpanded={!collapsed} disableGutters>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                style={{ backgroundColor: theme.palette.grey.A200 }}
                disabled={disabled}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    {icon}
                    <Typography>{title}</Typography>
                    {error && <Error color="error" />}
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ mt: disableGutters ? undefined : 1, p: disableGutters ? 0 : undefined }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};
