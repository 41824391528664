import { Badge } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { VicGridReadDTO, VicReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { formatDate } from '../date';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getVicStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const VicIcon = Badge;

export const getVicInformation = (vic: VicReadDTO | VicGridReadDTO, intl: IntlShape): EntityInformation => {
    return {
        url: getRoute({
            path: ROUTES.vic.view,
            params: { id: `${vic.id}` },
        }),
        name: intl.formatMessage({ id: 'lockout.titleExisting' }, { id: vic.id }),
        title: vic.title,
        status: getVicStatus(vic, intl),
        details: [
            {
                labelKey: 'document.field.facility',
                value: getLovLabel(translateValue(vic.facility, intl.locale)),
                type: true,
            },
            {
                labelKey: 'vic.field.scheduledStart',
                value: vic.scheduledStartDate ? formatDate(vic.scheduledStartDate, intl) : '',
            },
            {
                labelKey: 'vic.field.scheduledEnd',
                value: vic.scheduledEndDate ? formatDate(vic.scheduledEndDate, intl) : '',
            },
            {
                labelKey: 'document.field.creator',
                value: vic.createdBy?.searchLabel ?? '',
                italic: true,
            },
        ],
    };
};
