import { Box, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { BundledEditor } from './BundledEditor';

interface RichTextEditorProps {
    label: string;
    name: string;
    value: string;
    setValue: UseFormSetValue<FieldValues>;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    error?: FieldError | undefined;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
    label,
    name,
    value,
    setValue,
    disabled = false,
    readOnly = false,
    required = false,
    error,
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const [editorFocus, setEditorFocus] = React.useState(false);

    // The following CSS attempts to replicate MUI's floating label with perfect accuracy
    const labelProps = {
        variant: 'body2',
        sx: {
            ml: 1.8,
            fontSize: '0.75rem',
            fontWeight: 400,
            letterSpacing: '0.00938em',
            color: editorFocus ? theme.palette.primary.main : error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            transform: 'translateY(-8px)',
            zIndex: 2,
            userSelect: 'none',
        },
    } as const;

    const onEditorFocusStyle = {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        p: 0,
    } as const;

    const decoratedLabel = required ? `${label} *` : label;

    return (
        <Box id={name} sx={{ position: 'relative' }}>
            <Typography
                {...labelProps}
                sx={{
                    ...labelProps.sx,
                    borderTop: 2,
                    borderColor: 'white',
                    transform: undefined,
                    ml: 1.2,
                    pr: 1.2,
                }}
            >
                <span style={{ visibility: 'hidden' }}>{decoratedLabel}</span>
            </Typography>
            <Typography {...labelProps} style={{ opacity: disabled ? 0.6 : 1 }}>
                {decoratedLabel}
            </Typography>
            <Box
                sx={{
                    p: '1px',
                    border: '1px solid',
                    borderRadius: 1,
                    borderColor: error ? theme.palette.error.main : disabled ? grey[800] : grey[400],
                    opacity: disabled ? 0.38 : 1,
                    ...(editorFocus ? onEditorFocusStyle : {}),
                    ...(!error && !editorFocus && !disabled
                        ? {
                              '&:hover': { borderColor: '#000' },
                          }
                        : {}),
                }}
            >
                <BundledEditor
                    value={value}
                    tagName={name}
                    init={{
                        language: { en: undefined, fr: 'fr_FR' }[intl.locale],
                        min_height: 200,
                        autoresize_bottom_margin: 0,
                        menubar: false,
                        statusbar: false,
                        default_link_target: '_blank',
                        link_title: false,
                        plugins: ['autoresize', 'lists', 'autolink'],
                        toolbar:
                            'undo redo | formatselect | bold italic underline | bullist numlist | align | styles | forecolor backcolor | removeformat',
                        content_style: `
                            :root { font-family: "Roboto", "Helvetica", "Arial", sans-serif; color: rgba(0, 0, 0, 0.87) }
                            p, pre { font-size: 16px; }
                            h1 { font-size: 24px; }
                            h2 { font-size: 20px; }
                            h3 { font-size: 18px; }
                        `,
                    }}
                    onEditorChange={(content) => {
                        if (!disabled && !readOnly) {
                            setValue(name, content, { shouldDirty: true });
                        }
                    }}
                    disabled={disabled || readOnly}
                    onFocusIn={() => setEditorFocus(true)}
                    onFocusOut={() => setEditorFocus(false)}
                />
            </Box>
            {error?.message && (
                <Typography variant="caption" color="error" sx={{ ml: 1 }}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
};
