import { CellChange, CellLocation, Id, MenuOption, ReactGrid, Row } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import React from 'react';
import { WdpVersionReadDTO } from '../../api/dto';
import { accessValue, tableCellStyle, tableHeaderStyle } from '../../pages/documents/wdp/tabs/WdpUtils';
import { CellTypes, TableHeader } from '../../pages/documents/wdp/WdpInterfaces';
import { MenuEntry } from '../../pages/documents/wdp/WdpTabsRegion';
import {
    BaseDropdownCell,
    DropdownCell,
    MultiDropdownCell,
    MultiDropdownCellTemplate,
} from './MultiDropdownCellTemplate';
import { PersonAutocompleteTemplate } from './PersonAutocompleteTemplate';
import './reactgridstyles.css';

export type SpreadsheetProps = {
    headers: TableHeader[];
    data: unknown[];
    setData: (callbackOrData: ((prevData: any[]) => any[]) | unknown[]) => void;
    keyField: string;
    onFocus?: (cellLocation: CellLocation) => void;
    menuEntries?: MenuEntry[];
    onCellsChanged?: (cellChanges: CellChange[]) => void;
    headerHeight?: number;
    rowHeight?: number;
    customTypes?: WdpVersionReadDTO | undefined;
    addIdxHeader?: boolean;
};

export const NEW_ENTITY_FIELD = '__newEntity';
const HEADER_ID = '__HEADER__';
const IDX_HEADER_NAME = '__IDX__';

const idxHeader: TableHeader = {
    name: IDX_HEADER_NAME,
    label: '',
    width: 30,
    readonly: true,
    type: 'number',
};

const transformHeaders = (headers: TableHeader[], headerHeight: number): Row<CellTypes> => ({
    rowId: HEADER_ID,
    cells: headers.map((h) => ({ type: 'header', text: h.label, style: tableHeaderStyle })),
    height: headerHeight,
});

const transformRowCell = (
    rowData: any,
    header: TableHeader,
    customTypes: WdpVersionReadDTO | undefined,
    keyField: string,
    setData: {
        (callbackOrData: ((prevData: any[]) => any[]) | unknown[]): void;
    }
): CellTypes => {
    const val = accessValue(rowData, header.name);
    const metadata = header.customType;
    const setDataRow = (newValue: unknown[]) =>
        setData((prevData: any[]) =>
            prevData.map((row) =>
                row[keyField] === rowData[keyField] ? { ...row, ...{ [header.name]: newValue } } : row
            )
        );
    if ((header.type && header.type === 'multiautocomplete') || header.type === 'autocomplete') {
        const values = !!customTypes
            ? metadata
                ? (customTypes[metadata.dataKey as keyof WdpVersionReadDTO] as any[])?.map((opt) => ({
                      [metadata.idKey]: opt[metadata.idKey],
                      [metadata.labelKey]: opt[metadata.labelKey],
                  }))
                : []
            : [];
        const dropdownCell = {
            values,
            selectedValue: val,
            idKey: metadata?.idKey ?? header.idKey ?? 'value',
            labelKey: metadata?.labelKey ?? header.idKey ?? 'label',
            nonEditable: typeof header.readonly === 'function' ? header.readonly(rowData) : header.readonly,
            setData: setDataRow,
        } satisfies Partial<BaseDropdownCell>;

        if (header.type === 'multiautocomplete') {
            return {
                ...dropdownCell,
                type: header.type,
                valueList: val,
            } satisfies MultiDropdownCell;
        } else {
            return {
                ...dropdownCell,
                type: header.type,
                value: val,
            } satisfies DropdownCell;
        }
    }
    if (header.type === 'person') {
        return {
            type: 'person',
            person: val,
            nonEditable: typeof header.readonly === 'function' ? header.readonly(rowData) : header.readonly,
            setData: setDataRow,
        };
    }
    return {
        type: header.type ?? typeof val === 'number' ? 'number' : typeof val === 'boolean' ? 'checkbox' : 'text',
        hasChildren: !!rowData.children?.length,
        text: val ?? '',
        value: val,
        checked: val,
        style: rowData[NEW_ENTITY_FIELD] ? tableCellStyle : undefined,
        nonEditable: typeof header.readonly === 'function' ? header.readonly(rowData) : header.readonly,
        person: val,
    };
};

const transformRows = (
    headers: TableHeader[],
    data: any[],
    keyField: string,
    customTypes: WdpVersionReadDTO | undefined,
    rowHeight: number,
    setData: {
        (callbackOrData: ((prevData: any[]) => any[]) | unknown[]): void;
    }
): Row<CellTypes>[] =>
    data.map((rowData) => ({
        rowId: rowData[keyField],
        height: rowHeight,
        cells: headers.map((header) => transformRowCell(rowData, header, customTypes, keyField, setData)),
    }));

const getColumns = (headers: TableHeader[]) => headers.map(({ name, width }) => ({ columnId: name, width }));

const getMenuEntries = (menuEntries: MenuEntry[], selectedRowIds: Id[]): MenuOption[] =>
    selectedRowIds.includes(HEADER_ID)
        ? []
        : menuEntries.map((menuEntry) => ({
              id: menuEntry.label,
              label: menuEntry.label,
              handler: menuEntry.onClick,
          }));

const getValueField = (cell: any, type: CellTypes['type']) => {
    const accessField =
        type === 'multiautocomplete'
            ? 'valueList'
            : type === 'autocomplete'
            ? 'value'
            : type === 'checkbox'
            ? 'checked'
            : type === 'number'
            ? 'value'
            : type === 'date'
            ? 'date'
            : type === 'time'
            ? 'time'
            : type === 'dropdown'
            ? 'selectedValue'
            : type === 'person'
            ? 'value'
            : 'text';
    return cell[accessField];
};

export const Spreadsheet = ({
    headers,
    data,
    setData,
    keyField,
    onFocus,
    menuEntries = [],
    headerHeight = 45,
    rowHeight = 40,
    customTypes = undefined,
    addIdxHeader,
}: SpreadsheetProps) => {
    const enhancedHeaders = addIdxHeader ? [idxHeader, ...headers] : headers;
    //TODO memoize rows and stuff
    const rows = React.useMemo(
        () => [
            transformHeaders(enhancedHeaders, headerHeight),
            ...transformRows(
                enhancedHeaders,
                data.map((row: any, index) => ({ ...row, [IDX_HEADER_NAME]: index + 1 })),
                keyField,
                customTypes,
                rowHeight,
                setData
            ),
        ],
        [setData, data]
    );

    return (
        <ReactGrid
            rows={rows}
            columns={getColumns(enhancedHeaders)}
            enableRowSelection
            enableRangeSelection
            onFocusLocationChanged={onFocus}
            minColumnWidth={1}
            stickyTopRows={1}
            stickyLeftColumns={1}
            onContextMenu={(selectedRowIds) => getMenuEntries(menuEntries, selectedRowIds)}
            canReorderRows={(targetRowId: Id) => targetRowId !== 'header'}
            onRowsReordered={() => 1}
            onCellsChanged={(args: CellChange<CellTypes>[]) => {
                const updObj: any = args.reduce(
                    (acc, { columnId, rowId, type, newCell }) => ({
                        ...acc,
                        [rowId]: {
                            [columnId]: getValueField(newCell, type),
                        },
                    }),
                    {}
                );
                setData(data.map((row: any) => ({ ...row, ...(updObj[row[keyField]] ?? {}) })));
            }}
            customCellTemplates={{
                multiautocomplete: new MultiDropdownCellTemplate(),
                autocomplete: new MultiDropdownCellTemplate(),
                person: new PersonAutocompleteTemplate(),
            }}
        />
    );
};
