import { FireExtinguisher } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { FirePermitGridReadDTO, FirePermitReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { formatDateTime } from '../date';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getFirePermitStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const FirePermitIcon = FireExtinguisher;

export const getFirePermitInformation = (
    firePermit: FirePermitReadDTO | FirePermitGridReadDTO,
    intl: IntlShape
): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.firePermit.view, params: { id: firePermit.id } }),
        name: intl.formatMessage({ id: 'firePermit.titleExisting' }, { firePermitId: firePermit.id }),
        title: firePermit.activity.title,
        status: getFirePermitStatus(firePermit, intl),
        details: [
            ...(firePermit.activity.facility
                ? [
                      {
                          labelKey: 'document.field.facility' as const,
                          value: firePermit.activity.facility
                              ? getLovLabel(translateValue(firePermit.activity.facility, intl.locale))
                              : '',
                          type: true,
                      },
                  ]
                : []),
            {
                labelKey: 'document.field.startDate',
                value: firePermit.startDate ? formatDateTime(firePermit.startDate, intl) : '',
            },
            {
                labelKey: 'document.field.endDate',
                value: firePermit.endDate ? formatDateTime(firePermit.endDate, intl) : '',
            },
            {
                labelKey: 'document.field.responsible',
                value: firePermit.responsible.searchLabel ?? '',
                italic: true,
            },
        ],
    };
};
