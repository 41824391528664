import { AddCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { ResponsiveButton } from '../../../../components/ResponsiveButton';
import { ResponsiveButtonSet } from '../../../../components/ResponsiveButtonSet';
import { MenuEntry, TableHeader } from '../WdpInterfaces';
import { WdpTabProps } from './WdpTab';

// TODO
const headers: TableHeader[] = [
    {
        label: 'Step',
        name: 'step',
    },
    {
        label: 'Description',
        name: 'description',
    },
    {
        label: 'Responsible',
        name: 'responsible',
    },
    {
        label: 'Work teams',
        name: 'workTeams',
    },
    {
        label: 'Workers',
        name: 'workers',
    },
    {
        label: 'Number of participants',
        name: 'numberOfParticipants',
    },
    {
        label: 'Working positions',
        name: 'workingPositions',
    },
    {
        label: 'Dose rate [μSv/h]',
        name: 'doseRate',
    },
    {
        label: 'Exposure time coefficient',
        name: 'exposureTimeCoefficient',
    },
    {
        label: 'Estimated exposure time [min]',
        name: 'estExposureTime',
    },
    {
        label: 'Estimated total step exposure time [person.h]',
        name: 'estTotExposureTime',
    },
    {
        label: 'Estimated dose [person.μSv]',
        name: 'estimatedDose',
    },
    {
        label: 'Estimated total step dose',
        name: 'estTotStepDose',
    },
    {
        label: 'Expected start date',
        name: 'startDate',
    },
    {
        label: 'Expected end date',
        name: 'endDate',
    },
];

export const WorkStepsTab: React.FC<WdpTabProps> = ({ wdp }) => {
    const workSteps = wdp.wdpWorkSteps || [];

    const menuEntries: MenuEntry[] = [
        { label: 'Add work step', Icon: AddCircle, onClick: () => 1 },
        { label: 'Add work step before', Icon: AddCircle, onClick: () => 1 },
        { label: 'Add work step after', Icon: AddCircle, onClick: () => 1 },
        { label: 'Add parent work step', Icon: AddCircle, onClick: () => 1 },
        { label: 'Add child work step', Icon: AddCircle, onClick: () => 1 },
        { label: 'Remove work step', Icon: AddCircle, onClick: () => 1 },
        { label: 'Move work step one level up', Icon: AddCircle, onClick: () => 1 },
        { label: 'Move work step one level down', Icon: AddCircle, onClick: () => 1 },
        { label: 'Move workstep up', Icon: AddCircle, onClick: () => 1 },
        { label: 'Move workstep down', Icon: AddCircle, onClick: () => 1 },
    ];

    return (
        <>
            <ResponsiveButtonSet
                buttons={menuEntries.map(({ label, onClick, Icon, tooltip }) => (
                    <ResponsiveButton
                        buttonVariant="contained"
                        onClick={onClick}
                        icon={<Icon />}
                        tooltipText={tooltip ?? label}
                    >
                        {label}
                    </ResponsiveButton>
                ))}
            />
            <Box>
                <div style={{ overflowX: 'scroll', paddingBottom: '10px', paddingTop: '10px' }}>
                    {/* <TableGrid
                        headers={headers}
                        data={teams}
                        setData={setTeams}
                        keyField={fieldsMetadata.teams.idKey}
                        onFocus={(...args) => setLoc(...args)}
                        menuEntries={menuEntries}
                    /> */}
                </div>
            </Box>
        </>
    );
};
