import { TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';

interface AccessGridHeadProps {
    headers: any[];
}
export const AccessGridHead: React.FC<AccessGridHeadProps> = ({ headers }) => {
    return (
        <TableHead>
            <TableRow>
                {headers.map((column: any) => {
                    return (
                        <TableCell key={`${column.Header}`} padding="normal" {...column.getHeaderProps()}>
                            {column.render('Header')}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};
