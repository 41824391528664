import { Person } from '@mui/icons-material';
import React from 'react';
import { PersonReadDTO } from '../../api/dto';
import { PersonTooltip } from '../../components/PersonTooltip';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchPeopleByName, fetchPeopleForFiltering } from '../../lib/api';
import { getPersonLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';
export const personTypeProperties = ({
    multiple,
    fetchEndpoint,
}: { multiple?: boolean; fetchEndpoint?: any } = {}): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchEndpoint ?? fetchPeopleByName,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getPersonLabel,
    multiple,
    autocompleteProps: {
        forcePopupIcon: false,
    },
    endAdornment: ({ field, disabled }) => {
        const { value: person } = field;

        return (
            <>
                {!multiple && person ? (
                    <PersonTooltip person={person}>
                        <Person color={!disabled ? 'action' : 'disabled'} sx={{ cursor: 'help' }} />
                    </PersonTooltip>
                ) : null}
            </>
        );
    },
});

interface PersonSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    readOnly?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    fetchEndpoint?: any;
}

export const personSchema = ({
    path,
    label,
    required,
    readOnly,
    disabled,
    multiple,
    fetchEndpoint,
}: PersonSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    readOnly,
    disabled,
    typeProperties: personTypeProperties({ multiple, fetchEndpoint }),
});

interface PersonFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const personFilterSchema = ({ id, label }: PersonFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: PersonReadDTO) => value.searchLabel,
    typeProperties: {
        ...personTypeProperties({ fetchEndpoint: fetchPeopleForFiltering }),
        autocompleteProps: {
            disableCloseOnSelect: false,
            forcePopupIcon: false,
        },
    },
});
