import { Is37ChangeRequestStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const Is37ChangeRequestStatusColorMapping: Record<Is37ChangeRequestStatusCode, StatusColor> = {
    [Is37ChangeRequestStatusCode.REQUESTED]: StatusColor.COLOR_PRIMARY,
    [Is37ChangeRequestStatusCode.ACCEPTED]: StatusColor.COLOR_SUCCESS,
    [Is37ChangeRequestStatusCode.REJECTED]: StatusColor.COLOR_WARNING,
    [Is37ChangeRequestStatusCode.CANCELLED]: StatusColor.COLOR_NORMAL,
    [Is37ChangeRequestStatusCode.NEW]: StatusColor.COLOR_PRIMARY,
};
