import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { DIMR_PATH } from './documents/dimrs';
import { FP_PATH } from './documents/firePermits';
import { IS37_PATH } from './documents/is37s';
import { LOCKOUT_PATH } from './documents/lockouts';
import { NDC_PATH } from './documents/noteDeCoupures';
import { VIC_PATH } from './documents/vics';
import { WDP_PATH } from './documents/wdps';
import { DocumentType, WorkflowDTO } from './dto';
import { GetManyUnpaged } from './types';

const WORKFLOWS_PATH = 'workflows';

const DOCUMENT_PATHS = {
    [DocumentType.NDC]: NDC_PATH,
    [DocumentType.IS37]: IS37_PATH,
    [DocumentType.FP]: FP_PATH,
    [DocumentType.DIMR]: DIMR_PATH,
    [DocumentType.WDP]: WDP_PATH,
    [DocumentType.LOK]: LOCKOUT_PATH,
    [DocumentType.VIC]: VIC_PATH,
} satisfies Partial<Record<DocumentType, string>>;

export const getDocumentWorkflow: GetManyUnpaged<
    { documentType: keyof typeof DOCUMENT_PATHS; id: number },
    WorkflowDTO
> = async ({ documentType, id, config }) => {
    return client
        .get(buildPath([DOCUMENT_PATHS[documentType], id, WORKFLOWS_PATH]), config)
        .then((response) => response.data);
};
