import { SpringFilterQueryBuilder as SFQB } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';

export const makeOrgUnitFilter = (searchParam: string) => {
    const escapedSearchParam = escapeSfqbString(searchParam.replaceAll(/(\s|[-_\/])+/g, '-').trim(), true);
    return SFQB.or(SFQB.like('code', `${escapedSearchParam}*`), SFQB.like('code', `*-${escapedSearchParam}*`));
};

export const makeOrgUnitIdFilter = (searchParam: string, idField: string = 'id') =>
    SFQB.equal(idField, escapeSfqbString(searchParam));
