import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { PersonReferenceDTO, TaskAddAssigneesDTO, TaskCommentUpdateDTO, TaskDTO, TaskTypeDTO } from './dto';
import { ActionOne, GetManyPaged, GetManyUnpaged } from './types';

export type TaskIdentifier = { taskId: string; comment?: TaskCommentUpdateDTO };

const TASKS_PATH = 'tasks';

export const getTasks: GetManyPaged<TaskDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([TASKS_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const addTaskAssignees: ActionOne<TaskIdentifier & { newAssignees: PersonReferenceDTO[] }, void> = async ({
    taskId,
    newAssignees,
    config,
}) => {
    const body: TaskAddAssigneesDTO = { newAssignees };
    return client
        .post(buildPath([TASKS_PATH, taskId, 'add']), body, config)
        .then((response: AxiosResponse) => response.data);
};

/*export const delegateSignature: ActionOne<SignatureIdentifier & { personId: number }, void> = async ({
    taskId,
    personId,
    config,
}) => {
    const body: DocumentProcessingPersonDTO = { personId };
    return client
        .post(buildPath([SIGNATURES_PATH, taskId, 'delegate']), body, config)
        .then((response: AxiosResponse) => response.data);
};*/

/*export const takeSignature: ActionOne<SignatureIdentifier, void> = async ({ taskId, config }) =>
    client
        .post(buildPath([SIGNATURES_PATH, taskId, 'take']), undefined, config)
        .then((response: AxiosResponse) => response.data);
*/

export const getTaskTypes: GetManyUnpaged<{}, TaskTypeDTO> = async ({ config } = {}) =>
    client.get(buildPath([TASKS_PATH, 'types']), config).then((response: AxiosResponse) => response.data);
