import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getActivity } from '../../../api/activities';
import { getDimrValidationRules, getDimrVersion, getDimrVersionLatest } from '../../../api/documents/dimrs';
import { DocumentType } from '../../../api/dto';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { DimrForm } from '../../../forms/documents/DimrForm';
import { getRoute, ROUTES } from '../../../routes/routes';
import { DIMRPageVariant } from './DIMRPageVariant';

interface DIMRPageProps {
    variant: DIMRPageVariant;
}

export const DIMRPage: React.FC<DIMRPageProps> = ({ variant }) => {
    const intl = useIntl();
    const { id: dimrId, version: dimrVersionNumber } = useParams();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        data: dimrData,
        isLoading: dimrDataLoading,
        isError: dimrDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.DIMR, dimrId, dimrVersionNumber],
        // The version is optional, if not set we assume it is the latest one
        () =>
            dimrVersionNumber !== undefined
                ? getDimrVersion({ id: String(dimrId), versionNumber: parseInt(dimrVersionNumber) })
                : getDimrVersionLatest({ id: String(dimrId) }),
        {
            enabled: dimrId !== undefined,
            onError: () => toast.error('There was an error fetching the DIMR'),
        }
    );

    useEffect(() => {
        if (dimrData && variant === DIMRPageVariant.READ) {
            navigate(
                getRoute({
                    path: ROUTES.dimr.view,
                    params: { id: dimrData.dimrMasterId, version: dimrData.versionNumber },
                }),
                {
                    replace: true,
                }
            );
        }
    }, [dimrData, navigate]);

    const activityId = variant === DIMRPageVariant.CREATE ? String(params.get('activity')) : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.DIMR, 'rules'], () => getDimrValidationRules({ id: dimrId }));

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (
        (dimrDataLoading && dimrId !== undefined) ||
        (isActivityDataLoading && activityId !== undefined) ||
        isRulesDataLoading
    ) {
        return (
            <>
                <LinearProgress />
                <ImpactHelmet documentType={DocumentType.DIMR} title={intl.formatMessage({ id: 'title.loading' })} />
            </>
        );
    } else if (dimrDataFailed || activityDataFailed || rulesDataFailed) {
        return (
            <>
                <Alert severity="error" sx={{ m: 2 }}>
                    There has been an error fetching document data
                </Alert>
                <ImpactHelmet documentType={DocumentType.DIMR} title={intl.formatMessage({ id: 'title.error' })} />
            </>
        );
    } else if ((variant === DIMRPageVariant.CREATE && activityData) || (variant === DIMRPageVariant.READ && dimrData)) {
        return (
            <DimrForm
                data={
                    variant === DIMRPageVariant.CREATE
                        ? { variant, activityData: activityData! }
                        : { variant, dimrData: dimrData! }
                }
                rules={rulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};
