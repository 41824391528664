import { Cancel, CheckCircle, Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirmation: () => void;
    confirmationButtonColor?: 'primary' | 'warning' | 'error';
    title?: string;
    children: ReactNode;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    onConfirmation,
    confirmationButtonColor = 'primary',
    title,
    children,
}) => {
    const handleConfirm = () => {
        onClose();
        onConfirmation();
    };
    return (
        <Dialog open={open} onClose={onClose}>
            {title ? (
                <DialogTitle>
                    {title}
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
            ) : null}
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    disabled={!open}
                    onClick={onClose}
                    startIcon={<Cancel />}
                    sx={{ color: grey[600] }}
                >
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                    variant="contained"
                    disabled={!open}
                    onClick={handleConfirm}
                    startIcon={<CheckCircle />}
                    color={confirmationButtonColor}
                >
                    <FormattedMessage id="common.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
