import {
    Close,
    CopyAll,
    ForwardToInbox,
    Grading,
    Login,
    MoreVert,
    People,
    Person,
    Replay,
    Save,
} from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
} from '@mui/material';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { PersonReadDTO, TaskDTO } from '../../api/dto';
import { FormGenerator } from '../../forms/FormGenerator';
import { personSchema } from '../../forms/schemas/person';
import { TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { useAdminRole } from '../../hooks/useAdminRole';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useImpersonation } from '../../hooks/useImpersonation';
import { translateTaskType } from '../../lib/language';
import { getDocumentName, getDocumentUrl } from '../../lib/records/documents/document';
import { PersonTooltip } from '../PersonTooltip';

interface TaskAssigneesDialogProps {
    open: boolean;
    onClose: () => void;
    mutateAddAssignees: ((data: { newAssignees: PersonReadDTO[] }) => void) | null;
    disabled: boolean;
    readOnly: boolean;
    task: TaskDTO;
}

const TaskAssigneesDialog: React.FC<TaskAssigneesDialogProps> = ({
    open,
    onClose,
    mutateAddAssignees,
    disabled,
    readOnly,
    task,
}) => {
    const intl = useIntl();
    const { isMobile } = useDeviceType();
    const copyToClipboard = useCopyToClipboard();
    const { canImpersonateUsers, isCurrentlyImpersonating, impersonate, isImpersonateLoading } = useImpersonation();
    const form = useForm<{ newAssignees: { person?: PersonReadDTO }[] }>({
        mode: 'onSubmit',
        defaultValues: { newAssignees: [] },
    });
    const formRootElement: TFormElement = useMemo(
        () => ({
            type: UiSchemaType.FIELD_ARRAY,
            path: 'newAssignees',
            inline: true,
            element: personSchema({
                label: intl.formatMessage({ id: 'document.task.more.newAssignee' }),
                path: 'person',
            }),
            appendLabel: intl.formatMessage({ id: 'document.task.more.addNewAssignee' }),
            removeLabel: intl.formatMessage({ id: 'document.task.more.removeNewAssignee' }),
        }),
        [intl]
    );
    const submit = form.handleSubmit((data) => {
        if (!mutateAddAssignees) {
            throw new Error();
        }
        mutateAddAssignees({ newAssignees: data.newAssignees.map((a) => a.person!) });
    });
    const watchNewAssignees = form.watch('newAssignees');
    const canSubmit = watchNewAssignees.length > 0 && watchNewAssignees.every((a) => !!a && !!a.person);

    const [selectedAssignees, setSelectedAssignees] = useState(new Set<number>());
    const toggleSelectedAssignee = useCallback(
        (person: Pick<PersonReadDTO, 'id'>) =>
            setSelectedAssignees(
                new Set(
                    selectedAssignees.has(person.id)
                        ? [...selectedAssignees.values()].filter((id) => id !== person.id)
                        : [...selectedAssignees.values(), person.id]
                )
            ),
        [selectedAssignees, setSelectedAssignees]
    );
    const setAllSelectedAssignee = useCallback(
        () => setSelectedAssignees(new Set(task.assignees.map(({ id }) => id))),
        [task, setSelectedAssignees]
    );
    const setNoSelectedAssignee = useCallback(
        () => setSelectedAssignees(new Set()),
        [selectedAssignees, setSelectedAssignees]
    );
    const selectedPersons = useMemo(
        () => task.assignees.filter(({ id }) => selectedAssignees.has(id)),
        [selectedAssignees, task]
    );
    const handleMailAddressesCopy = useCallback(
        () =>
            copyToClipboard(
                selectedPersons
                    .map(({ email }) => email)
                    .filter((email): email is string => !!email)
                    .map((email) => email.toLowerCase())
                    .join(', ')
            ),
        [selectedPersons, copyToClipboard]
    );
    const handleMailToOpen = useCallback(() => {
        const addresses = selectedPersons
            .map(({ email }) => email)
            .filter((email): email is string => !!email)
            .map((email) => email.toLowerCase())
            .join(',');
        const subject = `${getDocumentName(task, intl)} - ${translateTaskType(task.type, intl.locale).typeName}`;
        const body = `${window.location.origin}${getDocumentUrl(task)}`;
        const params = queryString.stringify({
            subject,
            body,
        });
        const mailto = `mailto:${encodeURIComponent(addresses)}?${params}`;
        window.open(mailto, '_blank');
    }, [selectedPersons, intl]);
    const handleImpersonate = useCallback(() => {
        if (selectedPersons.length === 1) {
            const person = selectedPersons[0];
            impersonate({ impersonatingUsername: person.username });
        }
    }, [selectedPersons, impersonate]);

    useEffect(() => {
        // Reset form when dialog is opened
        if (open) {
            form.reset();
            setNoSelectedAssignee();
        }
    }, [open]);

    return (
        <Dialog onClose={!disabled ? onClose : undefined} open={open} fullWidth maxWidth="sm">
            <DialogTitle>
                <FormattedMessage id="document.task.more.manageAssignees" />
                <IconButton
                    onClick={onClose}
                    disabled={disabled}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <List dense disablePadding>
                        {task.assignees.map((person) => {
                            return (
                                <ListItem key={person.id} disablePadding>
                                    <ListItemButton
                                        selected={selectedAssignees.has(person.id)}
                                        onClick={() => toggleSelectedAssignee(person)}
                                        disabled={isImpersonateLoading}
                                    >
                                        {!isMobile && (
                                            <ListItemIcon>
                                                <PersonTooltip person={person}>
                                                    <Person color="action" sx={{ cursor: 'help' }} />
                                                </PersonTooltip>
                                            </ListItemIcon>
                                        )}
                                        <ListItemText primary={person.searchLabel} />
                                        {!isMobile && (
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedAssignees.has(person.id)}
                                                    disableRipple
                                                    sx={{
                                                        py: 0,
                                                        display: selectedAssignees.size === 0 ? 'none' : undefined,
                                                    }}
                                                />
                                            </ListItemIcon>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    {selectedAssignees.size === 0 ? (
                        !!mutateAddAssignees &&
                        !readOnly && (
                            <FormProvider {...form}>
                                <FormGenerator rootElement={formRootElement} form={form} disabled={disabled} />
                                {watchNewAssignees.length > 0 && (
                                    <Button
                                        variant="contained"
                                        startIcon={<Save />}
                                        onClick={submit}
                                        disabled={disabled || !canSubmit}
                                    >
                                        <FormattedMessage id="document.action.save" />
                                    </Button>
                                )}
                            </FormProvider>
                        )
                    ) : (
                        <Stack direction="row" spacing={1} justifyContent="space-between">
                            <Stack direction="row">
                                <Tooltip title={intl.formatMessage({ id: 'document.task.openMailto' })}>
                                    <IconButton color="primary" onClick={handleMailToOpen}>
                                        <ForwardToInbox />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={intl.formatMessage({ id: 'document.task.copySelectedEmails' })}>
                                    <IconButton color="primary" onClick={handleMailAddressesCopy}>
                                        <CopyAll />
                                    </IconButton>
                                </Tooltip>
                                {(canImpersonateUsers || isCurrentlyImpersonating) && selectedAssignees.size === 1 && (
                                    <Tooltip title={intl.formatMessage({ id: 'menu.loginAs' })}>
                                        <span>
                                            <IconButton
                                                color="primary"
                                                onClick={handleImpersonate}
                                                disabled={isImpersonateLoading}
                                            >
                                                <Login />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                            </Stack>
                            <Stack direction="row" justifyContent="flex-end">
                                <Tooltip title={intl.formatMessage({ id: 'common.selectAll' })}>
                                    <span>
                                        <IconButton
                                            disabled={
                                                selectedAssignees.size === task.assignees.length || isImpersonateLoading
                                            }
                                            onClick={setAllSelectedAssignee}
                                        >
                                            <Grading />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title={intl.formatMessage({ id: 'common.reset' })}>
                                    <span>
                                        <IconButton
                                            onClick={() => setNoSelectedAssignee()}
                                            disabled={isImpersonateLoading}
                                        >
                                            <Replay />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

interface TaskManageButtonProps {
    task: TaskDTO;
    mutateAddAssignees: ((data: { newAssignees: PersonReadDTO[] }) => void) | null;
    disabled: boolean;
    moreActionsDisabled: boolean;
    readOnly: boolean;
}

export const TaskManageButton: React.FC<TaskManageButtonProps> = ({
    task,
    mutateAddAssignees,
    disabled,
    moreActionsDisabled,
    readOnly,
}) => {
    const intl = useIntl();
    const isAdmin = useAdminRole();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = !!menuAnchorEl;

    const [isAssigneesDialogOpen, setAssigneesDialogOpen] = useState(false);

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    const handleAssigneesDialogOpen = () => {
        setAssigneesDialogOpen(true);
        handleMenuClose();
    };
    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        // For normal users, open the dialog straight away, since it's the only option
        if (!isAdmin) {
            handleAssigneesDialogOpen();
        } else {
            setMenuAnchorEl(menuAnchorEl === null ? event.currentTarget : null);
        }
    };
    const handleTaskCamundaOpen = () => {
        if (task.workflowUrl) {
            window.open(task.workflowUrl);
        }
        handleMenuClose();
    };

    const hasCamundaLink = isAdmin && !!task.workflowUrl;

    return (
        <>
            <Tooltip title={intl.formatMessage({ id: 'document.task.more.moreActions' })}>
                <IconButton component="span" size="small" disabled={moreActionsDisabled} onClick={handleMenuOpen}>
                    <MoreVert />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={menuAnchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={handleAssigneesDialogOpen}>
                    <ListItemIcon>
                        <People />
                    </ListItemIcon>
                    <FormattedMessage id="document.task.more.manageAssignees" />
                </MenuItem>
                {hasCamundaLink && <Divider />}
                {hasCamundaLink && (
                    <MenuItem onClick={handleTaskCamundaOpen}>
                        <ListItemIcon>
                            <AccountTreeIcon />
                        </ListItemIcon>
                        <FormattedMessage id="document.workflow.openCamunda" />
                    </MenuItem>
                )}
            </Menu>
            <TaskAssigneesDialog
                open={isAssigneesDialogOpen}
                onClose={() => setAssigneesDialogOpen(false)}
                disabled={disabled}
                readOnly={readOnly}
                mutateAddAssignees={mutateAddAssignees}
                task={task}
            />
        </>
    );
};
