import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Box } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import {
    ActivityReadDTO,
    DimrVersionGridReadDTO,
    FirePermitReadDTO,
    IS37ReadDTO,
    LockoutReadDTO,
    RpAssessmentReadDTO,
    VicReadDTO,
    WdpVersionGridReadDTO,
} from '../../api/dto';
import { DefaultAccordion } from '../DefaultAccordion';

import { getWorkOrder } from '../../api/documents/is37s';
import { EntityData } from '../../lib/information/types/EntityData';
import { EntityType } from '../../lib/information/types/EntityType';
import { LinkedDocumentDataAsync, LinkedDocumentsContent } from './LinkedDocumentsContent';

type ParentDocument =
    | {
          type: EntityType.Is37;
          is37: Pick<IS37ReadDTO, 'activity'> & Partial<Pick<IS37ReadDTO, 'firePermit' | 'workOrderId'>>;
      }
    | {
          type: EntityType.FirePermit;
          firePermit: Pick<FirePermitReadDTO, 'activity'> & Partial<Pick<FirePermitReadDTO, 'is37s'>>;
      }
    | {
          type: EntityType.NoteDeCoupure;
          ndc: { activity: ActivityReadDTO };
      }
    | {
          type: EntityType.Dimr;
          dimr: { rpAssessments: Pick<RpAssessmentReadDTO, 'activity'>[] } & Pick<
              DimrVersionGridReadDTO,
              'workDosePlanningVersions'
          >;
      }
    | {
          type: EntityType.Wdp;
          wdp: Pick<WdpVersionGridReadDTO, 'dimr'>;
      }
    | {
          type: EntityType.Lockout;
          lockout: Pick<LockoutReadDTO, 'activity'>;
      }
    | {
          type: EntityType.Vic;
          vic: Pick<VicReadDTO, 'activities'>;
      };

type LinkedDocumentsPanelProps = ParentDocument & {
    collapsed?: boolean;
};

const getLinkedDocumentsFromParent = (data: LinkedDocumentsPanelProps): LinkedDocumentDataAsync[] => {
    switch (data.type) {
        case EntityType.Is37:
            return [
                { type: EntityType.Activity, activity: data.is37.activity },
                ...(data.is37.firePermit != null
                    ? [{ type: EntityType.FirePermit, firePermit: data.is37.firePermit } satisfies EntityData]
                    : []),
                ...(data.is37.workOrderId != null
                    ? [
                          {
                              async: true as const,
                              query: (() =>
                                  getWorkOrder({ id: data.is37.workOrderId! }).then((workOrder) => ({
                                      type: EntityType.WorkOrder,
                                      workOrder,
                                  }))) satisfies () => Promise<EntityData>,
                              queryKey: ['workOrder', data.is37.workOrderId],
                              errorLabelKey: 'document.links.workOrder.errorFetch' as const,
                          },
                      ]
                    : []),
            ];
        case EntityType.FirePermit:
            return [
                { type: EntityType.Activity, activity: data.firePermit.activity },
                ...(data.firePermit.is37s != null
                    ? data.firePermit.is37s.map((is37) => ({ type: EntityType.Is37, is37 } satisfies EntityData))
                    : []),
            ];
        case EntityType.NoteDeCoupure:
            return [{ type: EntityType.Activity, activity: data.ndc.activity }];
        case EntityType.Dimr:
            const wdps: EntityData[] = data.dimr.workDosePlanningVersions.map((wdp) => ({
                type: EntityType.Wdp,
                wdp,
            }));
            const activities: EntityData[] = data.dimr.rpAssessments.map((rpAssessment) => ({
                type: EntityType.Activity,
                activity: rpAssessment.activity,
            }));
            return wdps.concat(activities);
        case EntityType.Wdp:
            return [...(data.wdp.dimr ? [{ type: EntityType.Dimr, dimr: data.wdp.dimr } satisfies EntityData] : [])];
        case EntityType.Lockout:
            return [{ type: EntityType.Activity, activity: data.lockout.activity }];

        case EntityType.Vic:
            return data.vic.activities.map((activity) => {
                return { type: EntityType.Activity, activity };
            });
    }
};

export const LinkedDocumentsPanel: React.FC<LinkedDocumentsPanelProps> = ({ collapsed, ...data }) => {
    const intl = useIntl();
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <DefaultAccordion
                    title={intl.formatMessage({ id: 'document.links.panel' })}
                    error={false}
                    icon={<FilePresentIcon color="action" />}
                    disabled={false}
                    collapsed={collapsed}
                >
                    <LinkedDocumentsContent documents={getLinkedDocumentsFromParent(data)} />
                </DefaultAccordion>
            </Box>
        </>
    );
};
