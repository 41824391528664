import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import {
    getNdcInterventionTypes,
    getNdcNetworks,
    getNdcs,
    getNdcStatuses,
    getNdcTypes,
} from '../../../api/documents/noteDeCoupures';
import { DocumentType, NdcReadDTO, SavedFilterType } from '../../../api/dto';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterTypeEnum } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { locationFilterSchema } from '../../../forms/schemas/location';
import { orgUnitFilterSchema } from '../../../forms/schemas/orgUnit';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { NdcStatusColorMapping } from '../../../lib/colors/NdcStatusColorMapping';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getLovLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { getRoute, ROUTES } from '../../../routes/routes';

const RESPONSIBLE_GROUP_ID = 'responsibleGroup.code';
const REQUESTED_BY_ID = 'requestedBy.searchLabel';
const STATUS_ID = 'status.code';
const AFFECTED_NETWORK_ID = 'affectedNetworksAndEquipment.affectedNetwork.code';
const LOCATION_ID = 'locations.location.id';
const AFFECTED_NETWORK_LOCATION_ID = 'affectedNetworksAndEquipment.location.id';
const CUT_EXECUTOR_ID = 'cutExecutor.searchLabel';
const INTERVENTION_TYPE_ID = 'interventionType.code';
const SEVERITY_ID = 'severity.code';

const filterSchemas = ({ formatMessage, locale }: IntlShape): TFilterElement[] => [
    {
        id: 'ndcNumber',
        label: formatMessage({ id: 'ndc.field.ndcNumber' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: 'title',
        label: formatMessage({ id: 'document.field.title' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: 'startDate',
        label: formatMessage({ id: 'document.field.startDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'endDate',
        label: formatMessage({ id: 'document.field.endDate' }),
        type: FilterTypeEnum.DATE,
    },
    personFilterSchema({ id: REQUESTED_BY_ID, label: formatMessage({ id: 'ndc.field.requestedBy' }) }),
    orgUnitFilterSchema({
        id: RESPONSIBLE_GROUP_ID,
        label: formatMessage({ id: 'document.field.responsibleGroup' }),
    }),
    valueFilterSchema(
        {
            id: AFFECTED_NETWORK_ID,
            label: formatMessage({ id: 'ndc.field.affectedNetwork' }),
            fetchOptions: getNdcNetworks,
        },
        locale
    ),
    locationFilterSchema({
        id: LOCATION_ID,
        label: formatMessage({ id: 'ndc.field.locationOfIntervention' }),
    }),
    locationFilterSchema({
        id: AFFECTED_NETWORK_LOCATION_ID,
        label: formatMessage({ id: 'ndc.field.affectedLocation' }),
    }),
    valueFilterSchema(
        {
            id: STATUS_ID,
            label: formatMessage({ id: 'document.field.status' }),
            fetchOptions: getNdcStatuses,
        },
        locale
    ),
    valueFilterSchema(
        {
            id: INTERVENTION_TYPE_ID,
            label: formatMessage({ id: 'ndc.field.interventionType' }),
            fetchOptions: getNdcInterventionTypes,
        },
        locale
    ),
    valueFilterSchema(
        {
            id: SEVERITY_ID,
            label: formatMessage({ id: 'ndc.field.type' }),
            fetchOptions: getNdcTypes,
        },
        locale
    ),
    personFilterSchema({ id: CUT_EXECUTOR_ID, label: formatMessage({ id: 'ndc.field.cutExecutor' }) }),
];

const columnHelper = createColumnHelper<NdcReadDTO>();

const columns = ({ formatMessage, locale }: IntlShape) => [
    columnHelper.accessor((row) => row.ndcNumber ?? '', {
        id: 'ndcNumber',
        header: formatMessage({ id: 'ndc.field.ndc' }),
        size: 155,
        cell: ({ getValue, row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.noteDeCoupure.view,
                    params: { id: (original as any).id },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => row.title ?? '', {
        id: 'title',
        header: formatMessage({ id: 'document.field.title' }),
        size: 240,
    }),
    columnHelper.accessor((row) => row.requestedBy?.searchLabel ?? '', {
        id: 'requestedBy.searchLabel',
        header: formatMessage({ id: 'ndc.field.requestedBy' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.requestedBy && (
                <PersonTooltip person={original.requestedBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.cutExecutor?.searchLabel ?? '', {
        id: 'cutExecutor.searchLabel',
        header: formatMessage({ id: 'ndc.field.cutExecutor' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.cutExecutor && (
                <PersonTooltip person={original.cutExecutor}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.responsibleGroup?.code ?? '', {
        id: 'responsibleGroup.code',
        header: formatMessage({ id: 'document.field.responsibleGroup' }),
        size: 100,
    }),
    columnHelper.accessor((row) => (row.status ? getLovLabel(translateValue(row.status, locale)) : ''), {
        id: 'status.label',
        header: formatMessage({ id: 'document.field.status' }),
        size: 160,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
        cell: ({ row }) => (
            <StatusChip
                label={getLovLabel(translateValue(row.original.status, locale))}
                color={NdcStatusColorMapping[row.original.status.code]}
                size="small"
            />
        ),
    }),
    columnHelper.accessor(
        (row) => (row.interventionType ? getLovLabel(translateValue(row.interventionType, locale)) : ''),
        {
            id: 'interventionType.label',
            header: formatMessage({ id: 'ndc.field.interventionType' }),
            size: 140,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
        }
    ),
    columnHelper.accessor((row) => (row.severity ? getLovLabel(translateValue(row.severity, locale)) : ''), {
        id: 'severity.label',
        header: formatMessage({ id: 'ndc.field.type' }),
        size: 120,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
    }),
    columnHelper.accessor((row) => row.startDate ?? '', {
        id: 'startDate',
        header: formatMessage({ id: 'document.field.startDate' }),
        size: 110,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.endDate ?? '', {
        id: 'endDate',
        header: formatMessage({ id: 'document.field.endDate' }),
        size: 110,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.activity.id ?? '', {
        id: 'activityId',
        header: formatMessage({ id: 'activity.name' }),
        size: 80,
        cell: ({ getValue }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.activities.view,
                    params: { id: String(getValue()) },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
];

export const NdcSearchPage = () => {
    const intl = useIntl();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.ndcSearch' })}
            filterDialogTitle={intl.formatMessage({ id: 'ndc.name' })}
            columns={columns(intl)}
            pageKey="NdcSearchPage"
            documentType={DocumentType.NDC}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getNdcs}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.NoteDeCoupure, noteDeCoupure: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'startDate', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.NDC}
        />
    );
};
