import { CsamSensorReadDTO, LocationDTO } from '../../api/dto';
import { LocationWithNodeDTO } from '../../api/locations';

export const isLeafLocation = (location: LocationWithNodeDTO): boolean => location.node.childCount === 0;

export const areLocationsEqual = (l1: LocationDTO, l2: LocationDTO): boolean => l1.id === l2.id;
export const areSensorsEqual = (l1: CsamSensorReadDTO, l2: CsamSensorReadDTO): boolean => l1.sensorId === l2.sensorId;

/**
 * @deprecated
 */
export function areLocationsEqualLegacy(location1: any, location2: any): boolean {
    if (typeof location1 === 'string') return location1 === location2;
    return location1.label === location2.label;
}
