import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import { useDeviceType } from '../hooks/useDeviceType';

interface ResponsiveButtonProps {
    icon: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickExpand?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    buttonVariant?: 'contained' | 'outlined';
    buttonColor?: 'primary' | 'warning' | 'error';
    children: React.ReactNode;
    tooltipText?: string;
    large?: boolean;
    dropdown?: boolean;
    split?: boolean;
}
export const ResponsiveButton: React.FC<ResponsiveButtonProps> = ({
    icon,
    onClick,
    onClickExpand,
    disabled,
    children,
    buttonVariant,
    buttonColor = 'primary',
    tooltipText = '',
    large,
    dropdown,
    split,
}) => {
    const { isMobile } = useDeviceType();

    const buttonProps = {
        variant: buttonVariant,
        size: !large ? 'small' : undefined,
        disabled: disabled,
        sx: { width: '100%' },
    } as const;

    const renderButton = (withProps: boolean) => (
        <Button
            color={buttonColor}
            startIcon={icon}
            endIcon={dropdown && !split ? <ArrowDropDown /> : undefined}
            onClick={!dropdown || split ? onClick : onClickExpand}
            {...(withProps ? buttonProps : {})}
        >
            {children}
        </Button>
    );

    return (
        <Tooltip title={tooltipText}>
            <span style={{ flexGrow: 1 }}>
                {!isMobile || large ? (
                    !dropdown || !split ? (
                        renderButton(true)
                    ) : (
                        <ButtonGroup {...buttonProps}>
                            {renderButton(false)}
                            {dropdown && split && (
                                <Button
                                    onClick={onClickExpand}
                                    startIcon={<ArrowDropDown />}
                                    color={buttonColor}
                                    size={'small'}
                                    sx={{ '.MuiButton-startIcon': { mr: 0 } }}
                                />
                            )}
                        </ButtonGroup>
                    )
                ) : (
                    <IconButton
                        onClick={dropdown ? onClickExpand : onClick}
                        size="small"
                        disabled={disabled}
                        color={buttonVariant === 'contained' || buttonColor !== 'primary' ? buttonColor : undefined}
                    >
                        {icon}
                    </IconButton>
                )}
            </span>
        </Tooltip>
    );
};
