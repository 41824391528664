import { LockoutStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const LockoutStatusColorMapping: Record<LockoutStatusCode, StatusColor> = {
    [LockoutStatusCode.LOCKOUT_STATUS_NEW]: StatusColor.COLOR_PRIMARY,
    [LockoutStatusCode.LOCKOUT_STATUS_SAVED]: StatusColor.COLOR_PRIMARY,
    [LockoutStatusCode.LOCKOUT_STATUS_SUBMITTED]: StatusColor.COLOR_SUCCESS,
    [LockoutStatusCode.LOCKOUT_STATUS_WAITING_LOCKOUT_APPROVAL]: StatusColor.COLOR_WARNING,
    [LockoutStatusCode.LOCKOUT_STATUS_LOCKOUT_APPROVED]: StatusColor.COLOR_SUCCESS,
    [LockoutStatusCode.LOCKOUT_STATUS_WAITING_UNLOCK_APPROVAL]: StatusColor.COLOR_WARNING,
    [LockoutStatusCode.LOCKOUT_STATUS_FINISHED]: StatusColor.COLOR_NORMAL,
    [LockoutStatusCode.LOCKOUT_STATUS_REJECTED]: StatusColor.COLOR_ERROR,
    [LockoutStatusCode.LOCKOUT_STATUS_CANCELLED]: StatusColor.COLOR_ERROR,
};
