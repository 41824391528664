import { FormatListBulleted } from '@mui/icons-material';
import {
    Badge,
    Box,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useDeviceType } from '../../hooks/useDeviceType';
import { SearchFilter } from './filters';

interface QuickFiltersButtonsProps {
    onPredefinedFilterClick: (predefinedFilter: SearchFilter[]) => void;
    predefinedFilters?: {
        filters: SearchFilter[];
        active: boolean;
        labelKey: MessageDescriptor['id'];
    }[];
}

export const QuickFiltersButtons: React.FC<QuickFiltersButtonsProps> = ({
    onPredefinedFilterClick,
    predefinedFilters,
}) => {
    const { isMobile } = useDeviceType();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const closeMenu = () => setAnchorEl(null);
    const withMenuClose =
        <T,>(f: () => T): (() => T) =>
        () => {
            closeMenu();
            return f();
        };

    return (
        <ToggleButtonGroup color="primary" size={isMobile ? undefined : 'small'}>
            {isMobile
                ? !!predefinedFilters?.length && (
                      <ToggleButton
                          value="quickFilter"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          selected={!!predefinedFilters?.filter((f) => f.active).length}
                      >
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                              <Badge badgeContent={predefinedFilters?.filter((f) => f.active).length} color="primary">
                                  <FormatListBulleted />
                              </Badge>
                              {!isMobile && (
                                  <Box>
                                      <FormattedMessage id="search.quickFilters" />
                                  </Box>
                              )}
                          </Stack>
                      </ToggleButton>
                  )
                : predefinedFilters?.map((predefinedFilterData) => (
                      <ToggleButton
                          key={predefinedFilterData.labelKey}
                          value={`quickFilter-${predefinedFilterData.labelKey}`}
                          selected={predefinedFilterData.active}
                          onClick={() => onPredefinedFilterClick(predefinedFilterData.filters)}
                      >
                          <FormattedMessage id={predefinedFilterData.labelKey} />
                      </ToggleButton>
                  ))}
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <MenuList dense>
                    {predefinedFilters?.map((predefinedFilterData) => (
                        <MenuItem
                            key={predefinedFilterData.labelKey}
                            selected={predefinedFilterData.active}
                            onClick={withMenuClose(() => onPredefinedFilterClick(predefinedFilterData.filters))}
                        >
                            <ListItemText>
                                <FormattedMessage id={predefinedFilterData.labelKey} />
                            </ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </ToggleButtonGroup>
    );
};
