import { AxiosRequestConfig } from 'axios';

export enum HttpCode {
    BAD_REQUEST = 400,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
}

export type RequestMethod<A, R> = (
    ...args:
        | ({} extends A ? [] : never)
        | [
              {
                  config?: AxiosRequestConfig;
              } & A
          ]
) => Promise<R>;

export type GetOne<A, T = any> = RequestMethod<A, T>;

export type CreateOne<A, T = any> = RequestMethod<A, T>;

export type UpdateOne<A, T = any> = RequestMethod<A, T>;

export type DeleteOne<A> = RequestMethod<A, void>;

export type ActionOne<A, T = any> = RequestMethod<A, T>;

export type GetManyUnpaged<A, T> = RequestMethod<A, T[]>;

export type GetManyPaged<T = any> = RequestMethod<
    {
        filter?: string;
        page?: number;
        size?: number;
        sort?: string[];
    },
    Pageable<T>
>;

export type GetManyList<T = any> = RequestMethod<
    {
        searchTerm?: string;
        page?: number;
        size?: number;
        sort?: string[];
    },
    T[]
>;

export interface Pageable<T = any> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    /**
     * Number of elements in the current page; namely the length of {@link content}.
     */
    numberOfElements: number;
    /**
     * Size of the page; always greater or equal to {@link numberOfElements}.
     */
    size: number;
    /**
     * Total number of elements.
     */
    totalElements: number;
    /**
     * Total number of pages.
     */
    totalPages: number;
    pageable: {
        offset: number;
        pageNumber: number;
        pageSize: number;
        paged: true;
        unpaged: false;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
    };
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
}
