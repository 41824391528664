import { IntlShape } from 'react-intl';
import { DimrVersionGridReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchDimrVersionsById } from '../../lib/api';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const dimrTypeProperties = (intl: IntlShape): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchDimrVersionsById,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: ({ dimrMaster, versionNumber }: DimrVersionGridReadDTO) =>
        intl.formatMessage({ id: 'title.dimrView' }, { masterId: dimrMaster.id, versionNumber }),
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
    },
});

interface DimrSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const dimrSchema = ({ path, label, required }: DimrSchemaParameters, intl: IntlShape): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: dimrTypeProperties(intl),
});

interface DimrFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const dimrFilterSchema = ({ id, label }: DimrFilterSchemaParameters, intl: IntlShape): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: DimrVersionGridReadDTO) => value.id,
    typeProperties: dimrTypeProperties(intl),
});
