import { Calculate } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { DimrVersionGridReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { formatDateTime } from '../date';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getDimrStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const DimrIcon = Calculate;

export const getDimrInformation = (dimr: DimrVersionGridReadDTO, intl: IntlShape): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.dimr.view, params: { id: dimr.dimrMaster.id, version: dimr.versionNumber } }),
        name: intl.formatMessage(
            { id: 'title.dimrView' },
            { masterId: dimr.dimrMaster.id, versionNumber: dimr.versionNumber }
        ),
        title: dimr.title,
        status: getDimrStatus(dimr, intl),
        details: [
            {
                labelKey: 'document.field.activities',
                value: dimr.activitiesIds.map((id) => ({
                    label: String(id),
                    url: getRoute({ path: ROUTES.activities.view, params: { id } }),
                })),
            },
            {
                labelKey: 'document.field.alara',
                value: dimr.alaraLevel ? getLovLabel(translateValue(dimr.alaraLevel, intl.locale)) : null,
                type: true,
            },
            // TODO WDP
            { labelKey: 'dimr.field.owner', value: dimr.owner?.searchLabel ?? '', italic: true },
            { labelKey: 'dimr.field.rpo', value: dimr.rpo?.searchLabel ?? '', italic: true },
            { labelKey: 'dimr.field.rsoExperimentRso' as any, value: dimr.rso?.searchLabel ?? '', italic: true },
            {
                labelKey: 'document.field.createdDate' as any,
                value: dimr.createdDate ? formatDateTime(dimr.createdDate, intl) : '',
            },
        ],
    };
};
