import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';
import { getActivity } from '../../../api/activities';
import {
    getFirePermit,
    getFirePermitValidationRules,
    getFirePermitWorkerTypes,
} from '../../../api/documents/firePermits';
import { DocumentType, FirePermitWorkerTypeCode } from '../../../api/dto';
import { getLocation } from '../../../api/locations';
import { ImpactHelmet } from '../../../components/ImpactHelmet';
import { FirePermitForm } from '../../../forms/documents/FirePermitForm';
import { FirePermitPageVariant } from './FirePermitPageVariant';

interface FirePermitPageProps {
    variant: FirePermitPageVariant;
}

export const FirePermitPage: React.FC<FirePermitPageProps> = ({ variant }) => {
    const { id: firePermitId } = useParams();
    const [params] = useSearchParams();
    const intl = useIntl();

    const {
        data: firePermitData,
        isLoading: firePermitDataLoading,
        isError: firePermitDataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.FP, firePermitId], () => getFirePermit({ id: String(firePermitId) }), {
        enabled: firePermitId !== undefined,
    });

    const activityId =
        variant === FirePermitPageVariant.CREATE ? String(params.get('activity')) : firePermitData?.activity.id;
    const locationId =
        variant === FirePermitPageVariant.CREATE && params.get('location') !== null
            ? String(params.get('location'))
            : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: activityId !== undefined,
    });

    const {
        data: locationData,
        isLoading: isLocationDataLoading,
        isError: locationDataFailed,
    } = useQuery(['location', locationId], () => getLocation({ id: String(locationId) }), {
        enabled: variant === FirePermitPageVariant.CREATE && locationId !== undefined,
    });

    const {
        data: rulesData,
        isLoading: isRulesDataLoading,
        isError: rulesDataFailed,
    } = useQuery([DocumentType.FP, firePermitId, 'rules'], () =>
        getFirePermitValidationRules({ id: variant === FirePermitPageVariant.CLONE ? undefined : firePermitId })
    );

    const {
        data: workerTypesData,
        isLoading: isWorkerTypesDataLoading,
        isError: workerTypesDataFailed,
    } = useQuery(['firePermitWorkerTypes'], () => getFirePermitWorkerTypes());

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (
        (firePermitDataLoading && firePermitId !== undefined) ||
        (isActivityDataLoading && activityId !== undefined) ||
        (isLocationDataLoading && locationId !== undefined) ||
        isRulesDataLoading ||
        isWorkerTypesDataLoading
    ) {
        return <LinearProgress />;
    } else if (
        firePermitDataFailed ||
        activityDataFailed ||
        rulesDataFailed ||
        workerTypesDataFailed ||
        locationDataFailed
    ) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                <FormattedMessage id="firePermit.error.document" />
                <ImpactHelmet documentType={DocumentType.FP} title={intl.formatMessage({ id: 'title.error' })} />
            </Alert>
        );
    } else if (
        (variant === FirePermitPageVariant.CREATE && activityData && (locationId === undefined || locationData)) ||
        (variant === FirePermitPageVariant.CLONE && firePermitData) ||
        (variant === FirePermitPageVariant.READ && firePermitData)
    ) {
        return (
            <FirePermitForm
                data={
                    variant === FirePermitPageVariant.CREATE
                        ? { variant, activityData: activityData!, locationData: locationData }
                        : { variant, firePermitData: firePermitData!, activityData: activityData! }
                }
                defaultWorkerType={workerTypesData.find(
                    (workerType) => workerType.code === FirePermitWorkerTypeCode.INTERNAL
                )}
                rules={rulesData}
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};
