import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ActivityCommentDTO, ActivityDTO, ActivityReadDTO, ListOfValuesReadDTO } from './dto';
import { CreateOne, DeleteOne, GetManyPaged, GetManyUnpaged, GetOne, UpdateOne } from './types';

export const ACTIVITY_PATH = 'activities';
const COMMENTS = 'comments';

export const getActivity: GetOne<{ id: string }, ActivityReadDTO> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id]), config).then((response) => response.data);

export const getActivities: GetManyPaged<ActivityReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response) => response.data);

/**
 * @deprecated Backend endpoint doesn't exist
 */
export const updateActivity: UpdateOne<{ id: string; data: any }, ActivityDTO> = async ({ id, data, config }) =>
    client.put(buildPath([ACTIVITY_PATH, id]), data, config).then((response) => response.data);

/**
 * @deprecated Backend endpoint doesn't exist
 */
export const getActivityComments: GetManyUnpaged<{ id: string }, ActivityCommentDTO> = async ({ id, config }) =>
    client.get(buildPath([ACTIVITY_PATH, id, COMMENTS]), config).then((response) => response.data);

/**
 * @deprecated Backend endpoint doesn't exist
 */
export const createActivityComment: CreateOne<{ id: string; content: any }, ActivityCommentDTO> = async ({
    id,
    content,
    config,
}) =>
    client
        .post(buildPath([ACTIVITY_PATH, id, COMMENTS]), { content: content }, config)
        .then((response) => response.data);

/**
 * @deprecated Backend endpoint doesn't exist
 */
export const updateActivityComment: UpdateOne<
    {
        activity: string;
        commentId: string;
        content: string;
        config?: any;
    },
    ActivityCommentDTO
> = async ({ activity, commentId, content, config }) =>
    client
        .put(buildPath([ACTIVITY_PATH, activity, COMMENTS, commentId]), { content: content }, config)
        .then((response) => response.data);

/**
 * @deprecated Backend endpoint doesn't exist
 */
export const deleteActivityComment: DeleteOne<{ activity: string; commentId: string }> = async ({
    activity,
    commentId,
    config,
}) =>
    client.delete(buildPath([ACTIVITY_PATH, activity, COMMENTS, commentId]), config).then((response) => response.data);

export const getParticipantRoles: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'participant-roles']), config)
        .then((response: AxiosResponse) => response.data);
