import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Fragment, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getActivity } from '../../../api/activities';
import { getVic } from '../../../api/documents/vics';
import { DocumentType } from '../../../api/dto';
import { VicForm } from '../../../forms/documents/VicForm';
import { VicPageVariant } from './VicPageVariant';

interface VicPageProps {
    variant: VicPageVariant;
}

export const VicPage: React.FC<VicPageProps> = ({ variant }) => {
    const { id: vicId } = useParams();
    const [params] = useSearchParams();

    const {
        data: vicData,
        isLoading: vicDataLoading,
        isError: vicDataFailed,
        remove,
        refetch,
    } = useQuery([DocumentType.VIC, vicId], () => getVic({ id: String(vicId) }), {
        enabled: vicId !== undefined,
        onError: () => toast.error('There was an error fetching the VIC'),
    });

    const activityId = variant === VicPageVariant.CREATE ? String(params.get('activity')) : undefined;

    const {
        data: activityData,
        isLoading: isActivityDataLoading,
        isError: activityDataFailed,
    } = useQuery(['activity', activityId], () => getActivity({ id: String(activityId) }), {
        enabled: variant === VicPageVariant.CREATE && activityId !== undefined,
    });

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if ((vicDataLoading && vicId !== undefined) || (isActivityDataLoading && activityId !== undefined)) {
        return <LinearProgress />;
    } else if (vicDataFailed || activityDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                There has been an error fetching document data
            </Alert>
        );
    } else if ((variant === VicPageVariant.CREATE && activityData) || (variant === VicPageVariant.READ && vicData)) {
        return (
            <VicForm
                data={
                    variant === VicPageVariant.CREATE
                        ? { variant, activityData: activityData! }
                        : { variant, vicData: vicData! }
                }
                refreshData={refreshData}
            />
        );
    } else {
        return <Fragment />;
    }
};
