import { Box, Container, Divider, Pagination, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, ColumnSort, OnChangeFn, SortingState, VisibilityState } from '@tanstack/react-table';
import deepEqual from 'fast-deep-equal';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { DocumentType, SavedFilterType } from '../../api/dto';
import { GetManyPaged } from '../../api/types';
import { TFilterElement, TFormElement } from '../../forms/types';
import { UiSchemaType } from '../../forms/UiSchemaType';
import { useDeviceType } from '../../hooks/useDeviceType';
import { useGridTableLocalStorageState } from '../../hooks/useGridTableLocalStorageState';
import { useImpactTable } from '../../hooks/useImpactTable';
import { Nullable } from '../../lib/types';
import { ImpactHelmet } from '../ImpactHelmet';
import { ImpactTable } from '../tables/table/ImpactTable';
import { SearchFilter, SearchFilterTyped, toSpringFilterQuery } from './filters';
import { FiltersDialog } from './FiltersDialog';
import { SearchCardList } from './SearchCardList';
import { SearchPageHeader } from './SearchPageHeader';
import { SearchView } from './SearchView';
import { SimpleFiltersDialog } from './SimpleFiltersDialog';
import { sanitizeFiltersBySchema } from './utils';

interface SearchFieldValues {
    filters?: Nullable<SearchFilter>[];
}

export interface BaseData {
    id: number | string;
}

interface SearchPageProps<TData extends BaseData> {
    pageKey: string;
    documentType: DocumentType | null;
    title: string;
    columns: ColumnDef<TData, any>[];
    filterDialogTitle?: string;
    filterSchemas: TFilterElement[];
    initialFilters?: SearchFilter[];
    pagedApiCall: GetManyPaged<TData>;
    CardItem?: ({
        item,
        handleExpandToggle,
        isExpanded,
    }: {
        item: TData;
        handleExpandToggle: (item: TData) => void;
        isExpanded: (item: TData) => boolean;
    }) => ReactElement;
    initialSort?: ColumnSort;
    savedFilterType?: SavedFilterType;
    selectable?: boolean;
    embedded?: boolean;
    selectedRows?: TData[];
    onSelect?: (name: any[]) => void;
    getRowId?: (row: TData) => number;
    header?: React.ReactNode;
    predefinedFilters?: { filters: SearchFilter[]; labelKey: MessageDescriptor['id']; sorting?: SortingState }[];
    hiddenColumnsIds?: string[] | undefined;
}

export const SearchPage = <TData extends BaseData>({
    pageKey,
    documentType,
    title,
    columns,
    filterDialogTitle,
    filterSchemas,
    initialFilters,
    pagedApiCall,
    CardItem,
    initialSort,
    savedFilterType,
    selectable = false,
    embedded = false,
    selectedRows,
    onSelect,
    getRowId,
    header,
    predefinedFilters,
    hiddenColumnsIds,
}: SearchPageProps<TData>): ReactElement => {
    const intl = useIntl();

    const sanitizeFilters = useCallback(
        (filters: Nullable<SearchFilter>[] | undefined | null) => sanitizeFiltersBySchema(filters, filterSchemas),
        [filterSchemas]
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [localStorageState, setLocalStorageState] = useGridTableLocalStorageState(pageKey);
    const { isMobile } = useDeviceType();
    // Mobile devices are shown the list view by default
    const [view, setView] = useState<SearchView>(() =>
        location.state?.view
            ? location.state?.view
            : isMobile && CardItem !== undefined
            ? SearchView.LIST
            : SearchView.TABLE
    );
    const [filtering, setFiltering] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [page, setPage] = React.useState(() => location.state?.page || 1);
    const [totalPages, setTotalPages] = React.useState(0);
    const [filters, setFilters] = useState<SearchFilterTyped[]>(() =>
        sanitizeFilters(location.state?.filters ? location.state.filters : initialFilters)
    );
    const [sorting, setSorting] = useState<SortingState>(
        location.state?.sorting ? location.state.sorting : initialSort ? [initialSort] : []
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        Object.fromEntries(
            (localStorageState.hiddenColumns && localStorageState.hiddenColumns.length > 0
                ? localStorageState.hiddenColumns
                : hiddenColumnsIds ?? []
            ).map((hiddenColumnId) => [hiddenColumnId, false])
        )
    );
    const [filtersVersion, setFiltersVersion] = useState(0);

    const filterForm = useForm<SearchFieldValues>({
        mode: 'onChange',
        defaultValues: { filters: location.state?.filters ? location.state.filters : initialFilters },
    });
    const filterQuery = useMemo(() => toSpringFilterQuery(filters), [filters]);
    const activeFilters = useMemo(() => filters.length, [filters]);
    const { data, isLoading, isError } = useQuery([pageKey, page, filterQuery, sorting], ({ signal }) =>
        pagedApiCall({
            page: page - 1,
            filter: filterQuery,
            sort: sorting.map((el) => `${el.id},${el.desc ? 'desc' : 'asc'}`.replaceAll('_', '.')),
            config: { signal },
        })
    );
    const watchFormFilters = useWatch({ control: filterForm.control, name: 'filters' });

    useEffect(() => {
        if (!embedded) {
            navigate(location.pathname, {
                replace: true,
                state: {
                    ...location.state,
                    filters: watchFormFilters,
                    page: page,
                    sorting: sorting,
                    view: view,
                },
            });
        }
    }, [watchFormFilters, page, sorting, view, navigate, embedded]);

    const listContainerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const filterFormSchema: TFormElement = useMemo(
        () => ({
            type: UiSchemaType.LAYOUT_VERTICAL,
            elements: [
                {
                    type: UiSchemaType.FIELD_ARRAY,
                    path: 'filters',
                    appendLabel: intl.formatMessage({ id: 'search.filters.add' }),
                    initialValue: {
                        field: null,
                        operator: null,
                        value: null,
                    },
                    element: {
                        type: UiSchemaType.FILTER_GROUP,
                        elements: filterSchemas,
                    },
                },
            ],
        }),
        [intl, filterSchemas]
    );

    useEffect(() => {
        if (data) {
            setTotalPages(data.totalPages || 0);
        }
    }, [setTotalPages, data?.totalPages]);

    const content = useMemo(() => data?.content || ([] as TData[]), [data?.content]);

    const scrollToTop = useCallback(() => {
        const listNode = listContainerRef.current;
        listNode && listNode.scrollIntoView();
    }, [listContainerRef]);

    const handleViewChange = useCallback(
        (_: any, nextView: SearchView | null) => {
            if (nextView != null) {
                setView(nextView as SearchView);
            }
        },
        [setView]
    );

    const handleExpandToggle = useCallback(
        (item: TData) => {
            const { id } = item;
            if (expandAll) {
                setExpandAll(false);
                const restExpanded = content.reduce(
                    (acc: { [key: string]: boolean }, el: { id: string | number }) => ({ ...acc, [el.id]: true }),
                    expanded
                );
                setExpanded({ ...restExpanded, [id]: false });
            } else {
                setExpanded({ ...expanded, [id]: !expanded[id] });
            }
        },
        [expandAll, setExpandAll, content, expanded, setExpanded]
    );

    const handleExpandAllToggle = useCallback(() => {
        setExpanded({});
        setExpandAll(!expandAll);
    }, [setExpanded, setExpandAll, expandAll]);

    const handleClose = useCallback(() => setFiltering(false), [setFiltering]);

    const handleSearch = useMemo(
        () =>
            filterForm.handleSubmit((searchData) => {
                const filteredSearchData = sanitizeFilters(searchData?.filters);
                setFilters(filteredSearchData);
                setPage(1);
                setExpanded({});
                filterForm.reset(searchData);
                scrollToTop();
            }),
        [filterForm.handleSubmit, filterSchemas, setFilters, setPage, setExpanded, handleClose, scrollToTop]
    );

    const handleChangePage = useCallback(
        (_: React.ChangeEvent<unknown>, newPage: number) => {
            setPage(newPage);
            scrollToTop();
        },
        [setPage, scrollToTop]
    );

    const handleSavedSearchStore = useCallback(
        (savedSearchId: number | undefined) => {
            setLocalStorageState({
                ...localStorageState,
                savedFilterId: savedSearchId,
            });
        },
        [setLocalStorageState, localStorageState]
    );

    const handleSortingChange: OnChangeFn<SortingState> = useCallback(
        (arg) => {
            setSorting(arg);
            setPage(1);
            setExpanded({});
            scrollToTop();
        },
        [setSorting, setPage, setExpanded, scrollToTop]
    );

    const isExpanded = useCallback((item: TData) => expandAll || expanded[item.id], [expandAll, expanded]);

    const table = useImpactTable({
        data: content,
        columns,
        state: { sorting, columnVisibility },
        onColumnVisibilityChange: setColumnVisibility,
        onSortingChange: handleSortingChange,
    });

    useEffect(() => {
        setLocalStorageState({
            ...localStorageState,
            hiddenColumns: Object.keys(columnVisibility).filter((columnId) => !columnVisibility[columnId]),
        });
    }, [columnVisibility]);

    const sortableFields = useMemo(
        () =>
            columns
                .filter((column) => column.enableSorting !== false)
                .map((column) => {
                    const label: string = typeof column.header === 'string' ? column.header : '';
                    return { id: column.id ?? '', label: label };
                }),
        [columns]
    );

    const handleFiltersClick = useCallback(() => setFiltering(!filtering), [setFiltering, filtering]);

    const renderCardItem = useCallback(
        (item: TData) => {
            return CardItem ? CardItem({ item, handleExpandToggle, isExpanded }) : <></>;
        },
        [CardItem, handleExpandToggle, isExpanded]
    );

    const handleResetFilters = useCallback(() => {
        handleSavedSearchStore(undefined);
        filterForm.reset({ filters: [] });
        handleSearch().then(() => setFiltersVersion(filtersVersion + 1));
    }, [filterForm.reset]);

    const handlePredefinedFilterClick = (predefinedFilter: SearchFilter[]) => {
        handleResetFilters();
        if (!deepEqual(sanitizeFilters(predefinedFilter), filters)) {
            filterForm.setValue('filters', predefinedFilter);
            const filtersClicked = predefinedFilters?.find((f) => f.filters === predefinedFilter);
            setSorting(filtersClicked?.sorting ? filtersClicked.sorting : []);
        }
        handleSearch()
            .then(() => setFiltersVersion(filtersVersion + 1))
            .then(handleClose);
    };

    return (
        <Box
            sx={(theme) => ({
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderBottom: `1px solid ${theme.palette.grey.A200}`,
            })}
        >
            <ImpactHelmet title={title} {...(documentType !== null ? { documentType } : {})} />
            <Box mx={2} my={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                {header}
                <SearchPageHeader
                    onFiltersClick={handleFiltersClick}
                    onPredefinedFilterClick={handlePredefinedFilterClick}
                    onViewChange={handleViewChange}
                    onExpandAllToggle={handleExpandAllToggle}
                    predefinedFilters={predefinedFilters?.map((predefinedFiltersData) => ({
                        ...predefinedFiltersData,
                        active: deepEqual(sanitizeFilters(predefinedFiltersData.filters), filters),
                    }))}
                    activeFiltersCount={activeFilters}
                    hiddenColumnsIds={hiddenColumnsIds}
                    view={view}
                    isExpandAll={expandAll}
                    table={table}
                    sortableFields={sortableFields}
                    sorting={sorting}
                    onSortingChange={handleSortingChange}
                    hasViewChoice={CardItem !== undefined}
                    selectedCount={selectedRows?.length}
                    onClearSelection={onSelect !== undefined ? () => onSelect([]) : undefined}
                />
            </Box>
            <Box
                ref={bodyRef}
                sx={{
                    display: 'flex',
                    overflow: 'auto',
                    flex: 1,
                    flexDirection: 'column',
                }}
            >
                {CardItem !== undefined && view === SearchView.LIST ? (
                    <>
                        <Divider />
                        <Box
                            sx={(theme) => ({
                                py: 2,
                                flex: 1,
                                overflow: 'auto',
                                backgroundColor: theme.palette.grey['100'],
                            })}
                        >
                            <Container
                                maxWidth="md"
                                ref={listContainerRef}
                                sx={{
                                    scrollMargin: (theme) => theme.spacing(2),
                                }}
                            >
                                <Stack spacing={2}>
                                    <SearchCardList
                                        isLoading={isLoading}
                                        isError={isError}
                                        parentRef={bodyRef}
                                        items={data?.content}
                                        itemRender={renderCardItem}
                                    />
                                </Stack>
                            </Container>
                        </Box>
                    </>
                ) : view === SearchView.TABLE ? (
                    <ImpactTable
                        isLoading={isLoading}
                        isError={isError}
                        table={table}
                        selectable={selectable}
                        selectedRows={selectedRows}
                        onSelect={onSelect}
                        getRowId={getRowId}
                    />
                ) : null}
            </Box>

            <Divider />

            <Box p={1} justifyContent="center" display="flex">
                <Pagination
                    page={page}
                    onChange={handleChangePage}
                    siblingCount={1}
                    boundaryCount={1}
                    count={totalPages}
                    shape="rounded"
                />
            </Box>
            {savedFilterType ? (
                <FiltersDialog
                    title={filterDialogTitle}
                    open={filtering}
                    form={filterForm}
                    formSchema={filterFormSchema}
                    formGeneratorKey={filtersVersion}
                    savedFilterType={savedFilterType}
                    savedSearchId={localStorageState.savedFilterId}
                    sorting={sorting}
                    setSorting={setSorting}
                    onSaveSearchStore={handleSavedSearchStore}
                    onClose={handleClose}
                    onCancel={handleClose}
                    onUpdateFilters={handleSearch}
                    onResetFilters={handleResetFilters}
                />
            ) : (
                <SimpleFiltersDialog
                    title={filterDialogTitle}
                    open={filtering}
                    form={filterForm}
                    formGeneratorKey={filtersVersion}
                    formSchema={filterFormSchema}
                    onClose={handleClose}
                    onCancel={handleClose}
                    onUpdateFilters={handleSearch}
                    onResetFilters={handleResetFilters}
                />
            )}
        </Box>
    );
};
