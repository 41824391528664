// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosResponse } from 'axios';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import { ListOfValuesReadDTO, LockoutGridReadDTO, LockoutReadDTO } from '../dto';
import { GetManyPaged, GetManyUnpaged, GetOne } from '../types';

export const LOCKOUT_PATH = 'lockout';

export const getLockout: GetOne<{ id: string }, LockoutReadDTO> = async ({ id, config }) =>
    client.get(buildPath([LOCKOUT_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getLockouts: GetManyPaged<LockoutGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([LOCKOUT_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getLockoutStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([LOCKOUT_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getLockoutTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([LOCKOUT_PATH, 'types']), config).then((response: AxiosResponse) => response.data);

export const getLockoutWorkingTimes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([LOCKOUT_PATH, 'working-times']), config).then((response: AxiosResponse) => response.data);
