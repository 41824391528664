import { AccessTime, Comment, ContentPaste, ContentPasteGo, People, Send, Settings } from '@mui/icons-material';
import { Alert, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { getParticipantRoles } from '../../../api/activities';
import {
    getVicApplicability,
    getVicDistributionTypes,
    getVicParticipantCompanyTypes,
    getVicSafetyDocs,
} from '../../../api/documents/vics';
import { VicRiskAssessmentReadDTO } from '../../../api/dto';
import { translateValue } from '../../../lib/language';
import { FormElementCommon, TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { personSchema } from '../person';
import { supplierSchema } from '../supplier';
import { valueSchema } from '../value';

const RiskAssessmentTypeName: React.FC<FormElementCommon> = (props) => {
    const intl = useIntl();
    const { watch } = useFormContext();
    const path: string | undefined = (props as any).path;
    const value: VicRiskAssessmentReadDTO | undefined = watch(path ?? '_'); // Dummy property that never gets resolved to satisfy the hook
    const display =
        path !== undefined && value !== undefined ? translateValue(value.vicRiskType, intl.locale) : undefined;
    return display !== undefined ? (
        <Box>
            <strong>{display.label}</strong>
            {' - '}
            <em>{display.description}</em>
        </Box>
    ) : null;
};

const riskAssessmentSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            lg: 5,
            element: {
                type: UiSchemaType.CUSTOM,
                Component: RiskAssessmentTypeName,
            },
        },
        {
            xs: 12,
            sm: 4,
            lg: 2,
            element: {
                type: UiSchemaType.CHECKBOX,
                path: 'answer',
                label: 'Applicable',
            },
        },
        {
            xs: 12,
            sm: 8,
            lg: 5,
            element: {
                type: UiSchemaType.INPUT,
                path: 'mitigation',
                label: 'Mitigation',
            },
        },
    ],
});

const cernParticipantSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            sm: 6,
            element: valueSchema(
                {
                    path: 'participantRole',
                    label: 'Role',
                    fetchOptions: getParticipantRoles,
                },
                intl.locale
            ),
        },
        {
            xs: 12,
            sm: 6,
            element: personSchema({
                path: 'participant',
                label: 'Person',
            }),
        },
    ],
});

const distributionSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            sm: 6,
            element: personSchema({
                path: 'person',
                label: 'Name',
            }),
        },
        {
            xs: 12,
            sm: 6,
            element: valueSchema(
                {
                    path: 'distributionType',
                    label: 'Type',
                    fetchOptions: getVicDistributionTypes,
                },
                intl.locale
            ),
        },
    ],
});

const companyParticipantSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            md: 4,
            element: supplierSchema({
                path: 'company',
                label: 'Company',
            }),
        },
        {
            xs: 12,
            sm: 6,
            md: 4,
            element: personSchema({
                path: 'participant',
                label: 'Person',
            }),
        },
        {
            xs: 12,
            sm: 6,
            md: 4,
            element: valueSchema(
                {
                    path: 'participantCompanyType',
                    label: 'Type',
                    fetchOptions: getVicParticipantCompanyTypes,
                },
                intl.locale
            ),
        },
    ],
});

export const vicFormSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        // TODO two warnings accordions
        {
            type: UiSchemaType.PANEL,
            label: 'Schedule',
            typeProperties: {
                icon: <AccessTime color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATE,
                            path: 'scheduledStartDate',
                            label: 'Start date',
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATE,
                            path: 'scheduledEndDate',
                            label: 'End date',
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'Participants',
            typeProperties: {
                icon: <People color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.FIELD_ARRAY,
                        path: 'vicCernParticipants',
                        element: cernParticipantSchema(intl),
                        appendLabel: 'Add a CERN participant',
                        removeLabel: 'Remove participant',
                        initialValue: {},
                    },
                    {
                        type: UiSchemaType.FIELD_ARRAY,
                        path: 'vicCompanyParticipants',
                        element: companyParticipantSchema(intl),
                        appendLabel: 'Add a company participant',
                        removeLabel: 'Remove participant',
                        initialValue: {},
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'How',
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    // TODO documents
                    // TODO tooltip over the "works to be performed" as on OIM?
                    {
                        type: UiSchemaType.RICH_TEXT,
                        path: 'worksToBePerformed',
                        label: 'Works to be performed',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkAccessConditions',
                        label: 'The access conditions, the circulation, the de-charging, the storage and the parking.',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkRescueOrganization',
                        label: 'The rescue organization: Red phone or other alert means, emergency number, extinguishers and emergency stops.',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkWorkingZone',
                        label: 'The working zones.',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkPowerSupply',
                        label: 'The supply of power and other services needed (air, water..).',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkEquipment',
                        label: 'The obligation to use only equipments that are in conformity to european norms (marquage CE).',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        path: 'checkToilets',
                        label: 'The toilets available.',
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'Points revised during the VIC',
            typeProperties: {
                icon: <ContentPaste color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.CUSTOM,
                            Component: () => (
                                <Alert severity="info">
                                    <Stack direction="column">
                                        <Box>
                                            Do you have a risk assessment or a task procedure that identified the risk
                                            (*) and define mitigation measures?
                                        </Box>
                                        <Typography variant="caption" fontWeight="light">
                                            (*) Risk = risks related to: the activity, the area where the activity is
                                            carried out, co-activities known in advance.
                                        </Typography>
                                    </Stack>
                                </Alert>
                            ),
                        },
                    },
                    // TODO assessment/procedure
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.CUSTOM,
                            Component: () => (
                                <Alert severity="info">
                                    <Stack direction="column">
                                        <Box>
                                            Have measures been identified, evaluated, planned in order to
                                            control/mitigate the following hazards/risks that could have an impact in
                                            terms of coactivity?
                                        </Box>
                                        <Typography variant="caption" fontWeight="light">
                                            The questions below are optional if you answered yes to the previous
                                            question and fill in the EDMS number.
                                        </Typography>
                                    </Stack>
                                </Alert>
                            ),
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.FIELD_ARRAY,
                            path: 'vicRiskAssessments',
                            element: riskAssessmentSchema(intl),
                            appendLabel: '',
                            removeLabel: '',
                            initialValue: {},
                            // Cannot add or remove items, but can edit the items themselves
                            shallowReadOnly: true,
                            inline: true,
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'Conclusion',
            typeProperties: {
                icon: <ContentPasteGo color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.CUSTOM,
                            Component: () => (
                                <Alert severity="info">
                                    Hazards identified as not present or not requiring mitigation measures.
                                </Alert>
                            ),
                        },
                    },
                    {
                        xs: 12,
                        element: valueSchema(
                            {
                                path: 'vicApplicability',
                                label: 'Applicability of this VIC',
                                fetchOptions: getVicApplicability,
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        element: valueSchema(
                            {
                                path: 'vicSafetyDocs',
                                label: 'Method statement',
                                fetchOptions: getVicSafetyDocs,
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.RICH_TEXT,
                            path: 'remarks',
                            label: 'Instructions / Remarks',
                        },
                    },
                    // TODO identified hazards
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.LAYOUT_VERTICAL,
                            elements: [
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalFirepermit',
                                    label: 'Fire Permit',
                                },
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalIs37',
                                    label: 'IS37',
                                },
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalDimr',
                                    label: 'DIMR',
                                },
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalWdp',
                                    label: 'WDP',
                                },
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalCryogenicLockout',
                                    label: 'Cryogenic Lockout',
                                },
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalElectricalLockout',
                                    label: 'Electrical Lockout',
                                },
                                {
                                    type: UiSchemaType.CHECKBOX,
                                    path: 'additionalAuthorization',
                                    label: 'Authorization from the equipment owner',
                                },
                            ],
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'serviceNeeds',
                            label: 'Services needed',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'emergencyInfo',
                            label: 'Emergency information',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'workProcedure',
                            label: 'Comments/Suggestions in the procedure',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            path: 'otherPoints',
                            label: 'Other points',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.distribution' }),
            typeProperties: {
                icon: <Send color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.FIELD_ARRAY,
                        path: 'vicDistributions',
                        element: distributionSchema(intl),
                        appendLabel: 'Add a person',
                        removeLabel: 'Remove person',
                        initialValue: {},
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.comments' }),
            typeProperties: {
                icon: <Comment color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [],
            },
        },
        // TODO bottom warning accordion
    ],
});
