import { ActivityStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const ActivityStatusColorMapping: Record<ActivityStatusCode, StatusColor> = {
    [ActivityStatusCode.NEW]: StatusColor.COLOR_PRIMARY,
    [ActivityStatusCode.DRAFT]: StatusColor.COLOR_PRIMARY,
    [ActivityStatusCode.SAVED]: StatusColor.COLOR_PRIMARY,
    [ActivityStatusCode.WAITING_SYSTEM_APPROVAL]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.WAITING_MEETINGS_APPROVAL]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.WAITING_TO_BE_SCHEDULED]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.SCHEDULED]: StatusColor.COLOR_SUCCESS,
    [ActivityStatusCode.WAITING_SAFETY_APPROVAL]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.APPROVED]: StatusColor.COLOR_SUCCESS,
    [ActivityStatusCode.IN_PROGRESS]: StatusColor.COLOR_SUCCESS,
    [ActivityStatusCode.LATE]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.INTERRUPTED]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.CLOSED]: StatusColor.COLOR_NORMAL,
    [ActivityStatusCode.REJECTED]: StatusColor.COLOR_ERROR,
    [ActivityStatusCode.CANCELLED]: StatusColor.COLOR_ERROR,
    [ActivityStatusCode.FINISHED]: StatusColor.COLOR_NORMAL,
    [ActivityStatusCode.WAITING_APPROVAL]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.WAITING_VISIT_SAFETY_APPROVAL]: StatusColor.COLOR_WARNING,
    [ActivityStatusCode.WAITING_SURFACE_APPROVAL]: StatusColor.COLOR_WARNING,
};
