import { AttachFile, Close, FileUpload } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosProgressEvent } from 'axios';
import { MuiFileInput } from 'mui-file-input';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { createAttachment } from '../../api/attachments';
import { AttachmentDTO } from '../../api/dto';

interface AttachmentUploadWidgetProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (newAttachment: AttachmentDTO) => void;
}
export const AttachmentUploadWidget: React.FC<AttachmentUploadWidgetProps> = ({ open, onClose, onSubmit }) => {
    const intl = useIntl();
    const [file, setFile] = useState<File | null>(null);
    const [description, setDescription] = useState('');
    const [progress, setProgress] = useState<number | null>(null);

    useEffect(() => {
        // Cleanup
        if (open) {
            setFile(null);
            setDescription('');
            setProgress(null);
        }
    }, [open]);

    const { mutate: uploadAttachment, isLoading } = useMutation(createAttachment, {
        onSuccess: (response) => {
            onSubmit(response);
            onClose();
        },
        onError: () => {
            setProgress(null);
            toast.error(intl.formatMessage({ id: 'document.attachment.error' }));
        },
    });

    const handleClose = () => {
        if (!isLoading) {
            onClose();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length === 1) {
            setFile(event.target.files[0]);
        }
    };
    const uploadFile = () => {
        if (file === null) {
            // This method should be called only after the file has been set
            throw new Error();
        }
        uploadAttachment({
            file,
            description,
            config: {
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (progressEvent.total !== undefined) {
                        const value = Math.round(100 * (progressEvent.loaded / progressEvent.total));
                        setProgress(value);
                    }
                },
            },
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>
                <Typography>
                    <FormattedMessage id="document.attachment.file.title" />
                </Typography>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    disabled={isLoading}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2} sx={{ mt: 0.5 }}>
                    <MuiFileInput
                        value={file}
                        onChange={(newFile) => setFile(newFile)}
                        placeholder={intl.formatMessage({ id: 'document.attachment.selectFile' })}
                        clearIconButtonProps={{
                            children: <Close fontSize="small" />,
                        }}
                        InputProps={{
                            startAdornment: <AttachFile />,
                        }}
                    />
                    <TextField
                        label={intl.formatMessage({ id: 'document.attachment.file.field.description' })}
                        variant="outlined"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        fullWidth
                        disabled={isLoading}
                    />
                </Stack>
                {progress !== null ? (
                    <>
                        <LinearProgress variant="determinate" value={progress} sx={{ marginTop: 2 }} />
                        <Typography variant="body2" color="text.secondary" align="right">
                            {progress}%
                        </Typography>
                    </>
                ) : null}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={<FileUpload />}
                    onClick={uploadFile}
                    disabled={file === null || isLoading}
                >
                    <FormattedMessage id="document.attachment.action.confirmFile" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
