import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageContextState, usePageContext } from './application/PageContextProvider';

type ImpactHelmetProps = {
    title?: string;
} & PageContextState;

const IMPACT_SLUG = 'IMPACT';
const SLUG_SEPARATOR = ' - ';

export const ImpactHelmet: React.FC<ImpactHelmetProps> = ({ title, ...rest }) => {
    const { register, unregister } = usePageContext();

    useEffect(() => {
        const id = register(rest);
        return () => unregister(id);
    }, [register, unregister]);

    return (
        <Helmet defer={false}>
            <title>{title !== undefined ? [title, SLUG_SEPARATOR, IMPACT_SLUG].join('') : IMPACT_SLUG}</title>
        </Helmet>
    );
};
