import { Dashboard } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { TaskDTO } from '../../api/dto';
import { formatDateTime } from '../date';
import { translateTaskType } from '../language';
import { getDocumentName, getDocumentTypeName, getDocumentUrl } from '../records/documents/document';
import { getTaskStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const TaskIcon = Dashboard;

export const getTaskInformation = (task: TaskDTO, intl: IntlShape): EntityInformation => {
    return {
        url: getDocumentUrl(task),
        name: getDocumentName(task, intl),
        title: getDocumentTypeName(task.documentType, intl),
        status: getTaskStatus(task, intl),
        details: [
            {
                labelKey: 'document.field.type',
                value: translateTaskType(task.type, intl.locale).typeName,
                type: true,
            },
            {
                labelKey: 'task.field.createdDate',
                value: formatDateTime(task.createdDate, intl),
            },
        ],
    };
};
