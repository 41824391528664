import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import * as React from 'react';

interface ButtonSetProps {
    buttons: Array<React.ReactNode>;
}
export const ButtonSet: React.FC<ButtonSetProps> = ({ buttons }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const theme = useTheme();

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                onClick={openMenu}
                sx={{
                    color: theme.palette.primary.main,
                }}
            >
                <MoreVert />
            </IconButton>
            <Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={closeMenu}>
                {buttons.map((button, idx) => (
                    <MenuItem key={idx}>{button}</MenuItem>
                ))}
            </Menu>
        </>
    );
};
