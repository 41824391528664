import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { updateActivity } from '../../api/activities';
import { ActivityDTO } from '../../api/dto';
import { FormGenerator } from '../FormGenerator';
import { TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const ACTIVITY_FORM_LABELS = {
    GENERAL: 'General',
    WHAT: 'What',
    WHERE: 'Where',
    WHEN: 'When',
    WHO: 'Who',
    HOW: 'How',
    SAFETY: 'Safety',
    RP_ASSESSMENT: 'RP Assessment',
    TESTS: 'Tests',
    COMMENTS: 'Comments',
    WORK_ORDERS: 'Work Orders',
    INCONSISTENCIES: 'Inconsistencies',
    INFO_LINES: 'Workflow Info Lines',
};

const participantValidation = yup.object({
    role: yup.string().required(),
    person: yup.string().required(),
    egroup: yup.string(),
    unknown: yup.string(),
});

const locationValidation = yup.object({
    location: yup.string().required(),
    accessPoints: yup.string(),
});

const impactedFacilityValidation = yup.object({
    description: yup.string().required(),
});

const activitySchema = yup.object({
    title: yup.string().required(),
    responsible: yup.object({
        id: yup.string(),
    }),
    facility: yup.object({
        description: yup.string(),
    }),
    type: yup.object({
        description: yup.string(),
    }),
    priority: yup.object({
        description: yup.string(),
    }),
    description: yup.string(),
    system: yup.object({
        code: yup.string(),
    }),
    where: yup.object({
        comment: yup.string(),
        locations: yup.array().of(locationValidation),
        impactedFacilities: yup.array().of(impactedFacilityValidation),
    }),
    when: yup.object({
        proposedStartDate: yup.date().nullable(),
        interventionPeriod: yup.object({
            name: yup.string(),
        }),
        duration: yup.number().nullable(),
        workingTime: yup.object({
            code: yup.string(),
            description: yup.string(),
        }),
        from: yup.date().nullable(),
        to: yup.date().nullable(),
    }),
    who: yup.object({
        maxNumberParticipants: yup.number(),
        contactPhone: yup.string(),
        shortTermRadiationWorkers: yup.boolean(),
        comment: yup.string(),
        participants: yup.array().of(participantValidation),
    }),
    how: yup.object({
        modusOperandi: yup.string(),
        materialResource: yup.string(),
        constraints: yup.string(),
        waste: yup.string(),
    }),
});

const uiSchemaParticipant: TFormElement = {
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.INPUT,
            label: 'Role',
            path: 'role',
        },
        {
            type: UiSchemaType.LAYOUT_HORIZONTAL,
            elements: [
                {
                    type: UiSchemaType.INPUT,
                    label: 'Person',
                    path: 'person',
                },
                {
                    type: UiSchemaType.INPUT,
                    label: 'E-group',
                    path: 'egroup',
                },
            ],
        },
        {
            type: UiSchemaType.INPUT,
            label: 'Unknown',
            path: 'unknown',
        },
    ],
};

const uiSchemaLocation: TFormElement = {
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        /*{
            type: UiSchemaType.AUTOCOMPLETE_WITH_LOOKUP_TREE,
            label: 'Location',
            path: 'location',
            typeProperties: {
                getTree: getLocationsTreeMock,
                getOptions: getLocationsOptionsMock,
                makeLabel: getLocationLabelLegacy,
                areEqual: areLocationsEqualLegacy,
            },
        },*/
        {
            type: UiSchemaType.INPUT,
            label: 'Access Points',
            path: 'accessPoints',
        },
    ],
};

const uiSchemaImpactedFacility: TFormElement = {
    type: UiSchemaType.LAYOUT_HORIZONTAL,
    elements: [
        {
            type: UiSchemaType.INPUT,
            label: 'Impacted Facility',
            path: 'description',
        },
    ],
};

const uiSchemaActivity: TFormElement = {
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.GENERAL,
            element: {
                type: UiSchemaType.LAYOUT_HORIZONTAL,
                elements: [
                    {
                        type: UiSchemaType.LAYOUT_VERTICAL,
                        elements: [
                            {
                                type: UiSchemaType.INPUT,
                                label: 'Title',
                                path: 'title',
                            },
                            {
                                type: UiSchemaType.INPUT,
                                label: 'Responsible',
                                path: 'responsible.id',
                            },
                        ],
                    },
                    {
                        type: UiSchemaType.LAYOUT_VERTICAL,
                        elements: [
                            {
                                type: UiSchemaType.INPUT,
                                label: 'Facility',
                                path: 'facility.description',
                            },
                            {
                                type: UiSchemaType.INPUT,
                                label: 'Type',
                                path: 'type.description',
                            },
                            {
                                type: UiSchemaType.INPUT,
                                label: 'Priority',
                                path: 'priority.description',
                            },
                        ],
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.WHAT,
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Description',
                        path: 'description',
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'System',
                        path: 'system.code',
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.WHERE,
            path: 'where',
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.REGION,
                        elements: [
                            {
                                type: UiSchemaType.FIELD_ARRAY,
                                path: 'locations',
                                element: uiSchemaLocation,
                                appendLabel: 'Add Location',
                                removeLabel: 'Remove Location',
                            },
                        ],
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Comments',
                        path: 'comment',
                    },
                    {
                        type: UiSchemaType.REGION,
                        elements: [
                            {
                                type: UiSchemaType.FIELD_ARRAY,
                                path: 'impactedFacilities',
                                element: uiSchemaImpactedFacility,
                                appendLabel: 'Add Impacted Facility',
                                removeLabel: 'Remove Impacted Facility',
                            },
                        ],
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.WHEN,
            path: 'when',
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.DATE,
                        label: 'Proposed Start Date',
                        path: 'proposedStartDate',
                    },
                    {
                        type: UiSchemaType.AUTOCOMPLETE,
                        label: 'Intervention Period',
                        path: 'interventionPeriod.name',
                        typeProperties: {
                            options: [],
                            getOptionLabel: (option) => option,
                        },
                    },
                    {
                        type: UiSchemaType.LAYOUT_HORIZONTAL,
                        elements: [
                            {
                                type: UiSchemaType.INPUT,
                                label: 'Duration',
                                path: 'duration',
                            },
                            {
                                type: UiSchemaType.AUTOCOMPLETE,
                                label: '',
                                path: 'unit',
                                typeProperties: {
                                    options: [],
                                    getOptionLabel: (option) => option,
                                },
                            },
                        ],
                    },
                    {
                        type: UiSchemaType.LAYOUT_HORIZONTAL,
                        elements: [
                            {
                                type: UiSchemaType.DATE,
                                label: 'From Earliest Start',
                                path: 'from',
                            },
                            {
                                type: UiSchemaType.DATE,
                                label: 'To Latest End Date',
                                path: 'to',
                            },
                        ],
                    },
                    {
                        type: UiSchemaType.AUTOCOMPLETE,
                        label: 'Working Time',
                        path: 'workingTime.code',
                        typeProperties: {
                            options: [],
                            getOptionLabel: (option) => option,
                        },
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Working time comments',
                        path: 'workingTime.description',
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.WHO,
            path: 'who',
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.REGION,
                        elements: [
                            {
                                type: UiSchemaType.LAYOUT_VERTICAL,
                                elements: [
                                    {
                                        type: UiSchemaType.FIELD_ARRAY,
                                        path: 'participants',
                                        element: uiSchemaParticipant,
                                        appendLabel: 'Add Participant',
                                        removeLabel: 'Remove Participant',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Max. number of participants',
                        path: 'maxNumberParticipants',
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Contact phone',
                        path: 'contactPhone',
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: 'Presence of Short-term radiation worker(s)',
                        path: 'shortTermRadiationWorkers',
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Comments',
                        path: 'comment',
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.HOW,
            path: 'how',
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Modus Operandi',
                        path: 'modusOperandi',
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Material',
                        path: 'materialResource',
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Constraints',
                        path: 'constraints',
                    },
                    {
                        type: UiSchemaType.INPUT,
                        label: 'Waste',
                        path: 'waste',
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.SAFETY,
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.RP_ASSESSMENT,
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.TESTS,
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.WORK_ORDERS,
        },
        {
            type: UiSchemaType.PANEL,
            label: ACTIVITY_FORM_LABELS.INCONSISTENCIES,
        },
    ],
};

type ActifityFormProps = {
    activityData: ActivityDTO;
};

const transformActivityData = (activityData: ActivityDTO) => {
    return {
        title: activityData.title || '',
        responsible: {
            id: activityData.responsible?.id || '',
        },
        facility: {
            description: activityData.facility?.description || '',
        },
        type: {
            description: activityData.type?.description || '',
        },
        priority: {
            description: activityData.priority?.description || '',
        },
        description: activityData.description || '',
        // system: {
        //     code: activityData.system?.code || '',
        // },
        where: {
            comment: activityData.where?.comment || '',
            locations: activityData.where?.locations || [],
            impactedFacilities: activityData.where?.impactedFacilities || [],
        },
        when: {
            proposedStartDate: activityData.when?.proposedStartDate || null,
            interventionPeriod: {
                name: activityData.when?.interventionPeriod?.name || '',
            },
            duration: activityData.when?.duration || null,
            workingTime: {
                code: activityData.when?.workingTime?.code || '',
                description: activityData.when?.workingTime?.description || '',
            },
            from: activityData.when?.from || null,
            to: activityData.when?.to || null,
        },
        who: {
            maxNumberParticipants: activityData.who?.maxNumberOfParticipants || '',
            contactPhone: activityData.who?.contactPhone || '',
            shortTermRadiationWorkers: activityData.who?.shortTermRadiationWorkers || false,
            comment: activityData.who?.comment || '',
            participants: activityData.who?.participants || [],
        },
        how: {
            modusOperandi: activityData.how?.modusOperandi || '',
            materialResource: activityData.how?.materialResource || '',
            constraints: activityData.how?.constraints || '',
            waste: activityData.how?.waste || '',
        },
    };
};

export const ActivityForm: React.FC<ActifityFormProps> = ({ activityData }) => {
    const defaultValues = transformActivityData(activityData);
    const activityId = useParams()?.id || '';
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(activitySchema),
        defaultValues,
    });
    const { handleSubmit } = form;

    const onSubmit = (data: any) => {
        updateActivity({
            id: activityId,
            data: data,
        });
    };
    return (
        <Box m={2}>
            <FormGenerator rootElement={uiSchemaActivity} form={form} />
            <Box m={2} display="flex" justifyContent="center">
                <Button type="submit" variant="contained" onClick={handleSubmit(onSubmit)}>
                    Submit
                </Button>
            </Box>
        </Box>
    );
};
