import { DimrStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const DIMRStatusColorMapping: Record<DimrStatusCode, StatusColor> = {
    [DimrStatusCode.DIMR_STATUS_DRAFT]: StatusColor.COLOR_PRIMARY,
    [DimrStatusCode.DIMR_STATUS_RP_VALIDATION]: StatusColor.COLOR_WARNING,
    [DimrStatusCode.DIMR_STATUS_VALIDATING]: StatusColor.COLOR_WARNING,
    [DimrStatusCode.DIMR_STATUS_WAITING_APPROVAL]: StatusColor.COLOR_WARNING,
    [DimrStatusCode.DIMR_STATUS_APPROVED]: StatusColor.COLOR_SUCCESS,
    [DimrStatusCode.DIMR_STATUS_IN_PROGRESS]: StatusColor.COLOR_SUCCESS,
    [DimrStatusCode.DIMR_STATUS_LATE]: StatusColor.COLOR_SUCCESS,
    [DimrStatusCode.DIMR_STATUS_WAITING_FEEDBACK]: StatusColor.COLOR_WARNING,
    [DimrStatusCode.DIMR_STATUS_WAITING_WDP_CLOSURE]: StatusColor.COLOR_WARNING,
    [DimrStatusCode.DIMR_STATUS_WAITING_DIMR_CLOSURE]: StatusColor.COLOR_WARNING,
    [DimrStatusCode.DIMR_STATUS_CLOSED]: StatusColor.COLOR_NORMAL,
    [DimrStatusCode.DIMR_STATUS_CANCELLED]: StatusColor.COLOR_ERROR,
    [DimrStatusCode.DIMR_STATUS_REJECTED]: StatusColor.COLOR_ERROR,
    [DimrStatusCode.DIMR_STATUS_OBSOLETE]: StatusColor.COLOR_ERROR,
};
