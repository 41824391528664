import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';

interface UseUnsavedChangesPrompt {
    when: boolean;
    message?: string;
}

export const useUnsavedChangesPrompt = ({ when, message: customMessage }: UseUnsavedChangesPrompt) => {
    const intl = useIntl();
    const message = customMessage || intl.formatMessage({ id: 'document.unsavedChangesWarning' });
    usePrompt({ when, message });
    return useCallback(
        (f: () => void) => {
            if (when) {
                if (window.confirm(message)) {
                    // Pressed "OK"
                    f();
                }
                // Otherwise pressed "Cancel"
            } else {
                f();
            }
        },
        [when]
    );
};
