import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DocumentType, LockoutReadDTO } from '../../api/dto';
import { useConfirmationDialog } from '../../components/ConfirmationDialogContext';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { EntityType } from '../../lib/information/types/EntityType';
import { mapIs37ToUpdateDTO } from '../../lib/records/documents/is37';
import { LockoutContentHeader } from '../../pages/documents/lockout/LockoutContentHeader';
import { LockoutPageData } from '../../pages/documents/lockout/LockoutPageData';
import { LockoutPageVariant } from '../../pages/documents/lockout/LockoutPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FormGenerator } from '../FormGenerator';
import { lockoutFormSchema } from '../schemas/documents/lockout';

interface LockoutFormProps {
    data: LockoutPageData;
    refreshData: () => void;
}

export const LockoutForm: React.FC<LockoutFormProps> = ({ data: pageData, refreshData }) => {
    const intl = useIntl();
    const { variant } = pageData;
    const navigate = useNavigate();
    const defaultValues = pageData.variant === LockoutPageVariant.READ ? pageData.lockoutData : {};
    const form = useForm<LockoutReadDTO>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultValues,
        },
    });
    const { handleSubmit, clearErrors, formState } = form;

    const confirmation = useConfirmationDialog();

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.LOK);
    const { mutate: saveLockout, isLoading: isSaveLoading } = useMutation(
        ({ data: formData, submit = false }: { data: any; submit?: boolean }) => {
            if (variant === LockoutPageVariant.READ) {
                const updateOrSubmit = async (any: any) => null as any; // TODO
                return updateOrSubmit({
                    id: pageData.lockoutData.id,
                    data: mapIs37ToUpdateDTO(formData, false), // TODO
                });
            } else {
                if (submit) {
                    throw new Error(); // Must be created first
                }
                return (async (any: any) => null as any)({ data: mapIs37ToUpdateDTO(formData, true) }); // TODO
            }
        },
        {
            onSuccess: (result) => {
                if (variant === LockoutPageVariant.READ) {
                    refreshData();
                } else {
                    navigate(
                        getRoute({
                            path: ROUTES.lockout.view,
                            params: { id: String(result.id) }, // TODO
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );
    const canBeSaved = true;
    const { isDirty: formIsDirty } = formState;
    const isLoading = isSaveLoading;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: variant === LockoutPageVariant.READ && canBeSaved && (formIsDirty || isLoading),
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const uiSchemaLockout = useMemo(() => lockoutFormSchema(intl), [intl]);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <LockoutContentHeader
                        data={pageData}
                        editable={canBeSaved}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === LockoutPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null} /* TODO signature here */
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {(pageData.variant !== LockoutPageVariant.READ || true) && ( // TODO savable
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.SAVE}
                                        onClick={() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveLockout({ data }))();
                                        }}
                                        disabled={false}
                                        disablingReason="The button is disabled because the form does not contain any change"
                                    />
                                )}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* TODO */}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        {variant === LockoutPageVariant.READ && (
                            <LinkedDocumentsPanel type={EntityType.Lockout} lockout={pageData.lockoutData} />
                        )}
                        <ValidationErrorsPanel schema={uiSchemaLockout} />
                        <FormGenerator rootElement={uiSchemaLockout} form={form} disabled={isLoading} />
                    </>
                }
            />
        </FormProvider>
    );
};
