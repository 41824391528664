import queryString, { StringifyOptions } from 'query-string';

export const ROUTES = {
    homepage: '/',
    news: '/news',
    activities: {
        search: '/activities',
        view: '/activities/:id',
        favourites: '/activities/favourites',
        clusters: '/activities/clusters',
        create: '/activities/new',
    },
    dimr: {
        search: '/dimr',
        view: '/dimr/:id/:version',
        viewLatest: '/dimr/:id',
        create: '/dimr/create',
    },
    wdp: {
        create: '/wdp/create',
        search: '/wdp',
        view: '/wdp/:id/:version',
        viewLatest: '/wdp/:id',
    },
    vic: {
        search: '/vic',
        view: '/vic/:id',
        create: '/vic/create',
    },
    lockout: {
        search: '/lockout',
        view: '/lockout/:id',
        create: '/lockout/create',
        clone: '/lockout/:id/clone',
    },
    firePermit: {
        search: '/firepermit',
        view: '/firepermit/:id',
        create: '/firepermit/create',
        clone: '/firepermit/:id/clone',
    },
    is37: {
        search: '/is37',
        view: '/is37/:id',
        create: '/is37/create',
        clone: '/is37/:id/clone',
    },
    noteDeCoupure: {
        search: '/notedecoupure',
        view: '/notedecoupure/:id',
        create: '/notedecoupure/create',
        clone: '/notedecoupure/:id/clone',
    },
    tasks: '/tasks',
    radiationDoses: '/radiationdoses',
    accessControl: '/accesscontrol',
    locations: '/locations',
    notFound: '/not-found',
} as const;

type DescendantsOf<Obj, T> = Obj extends T ? Obj : DescendantsOf<Obj[keyof Obj], T>;
export type RouteType = DescendantsOf<typeof ROUTES, string>;

export const replaceParams = ({ path, params }: { path: string; params: { [key: string]: string | number } }) =>
    Object.keys(params).reduce((acc, key) => {
        const paramRegex = new RegExp(`:${key}`, 'g');
        return acc.replace(paramRegex, String(params[key]));
    }, path);

const withQueryParams = (
    {
        path,
        queryParams,
    }: {
        path: string;
        queryParams: { [key: string]: string | number };
    },
    options?: StringifyOptions
) => queryString.stringifyUrl({ url: path, query: queryParams }, options);

export const getRoute = (
    {
        path,
        params = {},
        queryParams = {},
    }: {
        path: string;
        params?: { [key: string]: string | number };
        queryParams?: { [key: string]: string | number };
    },
    options?: StringifyOptions
) => withQueryParams({ path: replaceParams({ path, params }), queryParams }, options);
