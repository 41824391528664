import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
} from '@mui/material';
import React from 'react';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { WdpTabProps } from './tabs/WdpTab';
import { createEnum } from './tabs/WdpUtils';
import { MenuEntry, WdpTabsProps } from './WdpInterfaces';
import { WdpTabs } from './WdpTabsConfig';

interface WdpTabV {
    Component: React.FC<WdpTabsProps>;
    label: string;
    props?: WdpTabProps;
}

type WdpTabKey = keyof typeof WdpTabs;

const WdpTabKeys = createEnum<WdpTabKey>(Object.keys(WdpTabs) as WdpTabKey[]);

const WdpTabsRegion: React.FC<WdpTabsProps> = ({ wdp, setWdp }) => {
    const [selectedTab, setSelectedTab] = React.useState<WdpTabKey>(WdpTabKeys.TEAMS);
    const { isMediumScreen } = useDeviceType();

    const tab: WdpTabV = WdpTabs[selectedTab] as WdpTabV;
    const CurrentTab: React.FC<WdpTabsProps> = tab.Component;
    const currentTabProps = tab.props;

    const switchTab = (event: React.SyntheticEvent, newValue: WdpTabKey) => setSelectedTab(newValue);
    const switchTabSelect = (event: SelectChangeEvent<WdpTabKey>) => setSelectedTab(event.target.value as WdpTabKey);

    return (
        <Paper sx={{ marginTop: 2, padding: 0 }}>
            {isMediumScreen ? (
                <FormControl fullWidth>
                    <InputLabel id="tab-selection-select">Select tab</InputLabel>
                    <Select
                        labelId="tab-selection-select"
                        value={selectedTab}
                        label="Select tab"
                        size="small"
                        onChange={switchTabSelect}
                    >
                        {Object.entries(WdpTabs).map(([key, { label }]) => (
                            <MenuItem key={key} value={key}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <Tabs value={selectedTab} onChange={switchTab}>
                    {Object.entries(WdpTabs).map(([key, { label }]) => (
                        <Tab key={key} label={label} value={key} />
                    ))}
                </Tabs>
            )}
            <Divider />
            <Box sx={{ padding: 1, width: '100%', overflowX: 'scroll', boxSizing: 'border-box' }}>
                {wdp && <CurrentTab wdp={wdp} setWdp={setWdp} customTypes={wdp} {...currentTabProps} />}
            </Box>
        </Paper>
    );
};

export { WdpTabsRegion, type MenuEntry, type WdpTabsProps };
