import { Assignment, Functions, Settings } from '@mui/icons-material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IntlShape } from 'react-intl';
import {
    getDimrAlaraLevels,
    getDimrAreaClassification,
    getDimrRequirementPeriods,
    getEquipmentJobCodes,
    getGeneralJobCodes,
} from '../../../api/documents/dimrs';
import { RpAssessmentReadDTO } from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { LinkedDocumentsContent } from '../../../components/documents/LinkedDocumentsContent';
import { EntityType } from '../../../lib/information/types/EntityType';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { locationSchema } from '../location';
import { personSchema } from '../person';
import { prefilledLovSchema } from '../prefilledLovSchema';
import { numberWithUnitSchemaFor } from '../unit';
import { valueSchema } from '../value';

interface RpAssessmentLinkedActivityProps {
    path: string;
}

const RpAssessmentLinkedActivity: React.FC<RpAssessmentLinkedActivityProps> = ({ path }) => {
    const { watch } = useFormContext();
    const rpAssessment: RpAssessmentReadDTO | undefined = watch(path);
    return rpAssessment ? (
        <LinkedDocumentsContent documents={[{ type: EntityType.Activity, activity: rpAssessment.activity }]} />
    ) : null;
};

export const dimrFormSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.title' }),
                            path: 'title',
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.description' }),
                            path: 'description',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.mainFacility' }),
                                path: 'mainFacility',
                                fetchOptions: getFacilities,
                            },
                            intl.locale
                        ),
                    },
                    // TODO location with the highest classification area
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'dimr.field.owner' }),
                            path: 'owner',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'dimr.field.rsoExperimentRso' }),
                            path: 'rso',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'dimr.field.rpo' }),
                            path: 'rpo',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'dimr.field.rssoRpe' }),
                            path: 'rssoRpe',
                        }),
                    },
                    {
                        // TODO may need to be a dropdown, depends on the requirements
                        xs: 12,
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: intl.formatMessage({ id: 'dimr.field.pcrNotification' }),
                            path: 'pcrNotification',
                        },
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'dimr.field.riskAnalysisAssessment' }),
                            path: 'riskAnalysisAssessment',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATE,
                            label: intl.formatMessage({ id: 'dimr.field.workStartDate' }),
                            path: 'workStartDate',
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATE,
                            label: intl.formatMessage({ id: 'dimr.field.workEndDate' }),
                            path: 'workEndDate',
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'dimr.panel.calculated' }),
            typeProperties: {
                icon: <Functions color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.maxEstAirborneCont' }),
                                path: 'maxEstAirborneCont',
                                unit: intl.formatMessage({ id: 'dimr.unit.contaminationAirborne' }),
                                recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstAirborneCont' }),
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.maxEstSurfaceCont' }),
                                path: 'maxEstSurfaceCont',
                                unit: intl.formatMessage({ id: 'dimr.unit.contaminationSurface' }),
                                recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstSurfaceCont' }),
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.maxEstDoseRate' }),
                                path: 'maxEstDoseRate',
                                unit: intl.formatMessage({ id: 'dimr.unit.microSievertPerHour' }),
                                recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstDoseRate' }),
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.maxAvgEstDoseRate' }),
                                path: 'maxAvgEstDoseRate',
                                unit: intl.formatMessage({ id: 'dimr.unit.microSievertPerHour' }),
                                recipe: intl.formatMessage({ id: 'dimr.recipe.maxAvgEstDoseRate' }),
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.estCollectiveDose' }),
                                path: 'estCollectiveDose',
                                unit: intl.formatMessage({ id: 'dimr.unit.personMicroSievertPerHour' }),
                                recipe: intl.formatMessage({ id: 'dimr.recipe.estCollectiveDose' }),
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: intl.formatMessage({ id: 'dimr.field.maxEstIndividualDose' }),
                                path: 'maxEstIndividualDose',
                                unit: intl.formatMessage({ id: 'dimr.unit.microSievert' }),
                                recipe: intl.formatMessage({ id: 'dimr.recipe.maxEstIndividualDose' }),
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        element: prefilledLovSchema({
                            label: intl.formatMessage({ id: 'document.field.alaraLevel' }),
                            path: 'alaraLevel',
                            fetchOptions: getDimrAlaraLevels,
                        }),
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: intl.formatMessage({ id: 'dimr.field.forceAlara' }),
                            path: 'alaraLevelForced',
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'dimr.panel.rpAssessments' }),
            typeProperties: {
                icon: <Assignment color="action" />,
                disableGutters: true,
            },
            element: {
                type: UiSchemaType.FIELD_ARRAY_TABS,
                path: 'rpAssessments',
                tabLabel: (rpAssessment: RpAssessmentReadDTO) => rpAssessment.activity.title,
                element: {
                    type: UiSchemaType.LAYOUT_GRID,
                    elements: [
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CUSTOM,
                                Component: RpAssessmentLinkedActivity as any,
                            },
                        },
                        {
                            xs: 12,
                            element: personSchema({
                                label: intl.formatMessage({ id: 'dimr.field.rssoRpe' }),
                                path: 'rssoRpe',
                            }),
                        },
                        {
                            xs: 12,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.rpPresence' }),
                                    path: 'rpPresence',
                                    fetchOptions: getDimrRequirementPeriods,
                                },
                                intl.locale
                            ),
                        },

                        {
                            xs: 12,
                            element: locationSchema({
                                label: intl.formatMessage({ id: 'document.field.locations' }),
                                path: 'locations',
                                multiple: true,
                            }),
                        },
                        {
                            xs: 12,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.generalJobCode' }),
                                    path: 'generalJobCode',
                                    fetchOptions: getGeneralJobCodes,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 12,
                            element: valueSchema(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.equipmentJobCode' }),
                                    path: 'equipmentJobCode',
                                    fetchOptions: getEquipmentJobCodes,
                                },
                                intl.locale
                            ),
                        },
                        {
                            xs: 12,
                            element: personSchema({
                                label: intl.formatMessage({ id: 'dimr.field.rpa.pcrs' }),
                                path: 'rpAssessmentPcrs',
                                multiple: true,
                            }),
                        },
                        // TODO optimization attachments
                        // TODO other attachments
                        // TODO radiation dose report link
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'dimr.field.rpa.opDosimetryMandatory' }),
                                path: 'operDosimetryMandatory',
                            },
                        },
                        {
                            xs: 12,
                            element: prefilledLovSchema({
                                label: intl.formatMessage({ id: 'dimr.field.rpa.highestAreaClassification' }),
                                path: 'highestAreaClassification',
                                fetchOptions: getDimrAreaClassification,
                            }),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.avgEstDoseRate' }),
                                    path: 'avgEstDoseRate',
                                    unit: intl.formatMessage({ id: 'dimr.unit.microSievertPerHour' }),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.totalCollectiveWorkTime' }),
                                    path: 'totalCollectiveWorkTime',
                                    unit: intl.formatMessage({ id: 'dimr.unit.personHour' }),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxIndividualWorkTime' }),
                                    path: 'maxIndividualWorkTime',
                                    unit: intl.formatMessage({ id: 'dimr.unit.hour' }),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.estCollectiveDose' }),
                                    path: 'estCollectiveDose',
                                    unit: intl.formatMessage({ id: 'dimr.unit.personMicroSievert' }),
                                    recipe: intl.formatMessage({ id: 'dimr.recipe.rpa.estCollectiveDose' }),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstIndividualDose' }),
                                    path: 'maxEstIndividualDose',
                                    unit: intl.formatMessage({ id: 'dimr.unit.microSievert' }),
                                    recipe: intl.formatMessage({ id: 'dimr.recipe.rpa.maxEstIndividualDose' }),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.individualDoseAlarm' }),
                                    path: 'individualDoseAlarm',
                                    unit: intl.formatMessage({ id: 'dimr.unit.microSievert' }),
                                    recipe: intl.formatMessage(
                                        { id: 'dimr.recipe.rpa.individualDoseAlarm' },
                                        {
                                            coeffMaxEstIndividualDose: '1.3',
                                            maxWeightedMaxEstIndividualDose: 50,
                                            individualDose: 50,
                                        }
                                    ),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstDoseRate' }),
                                    path: 'maxEstDoseRate',
                                    unit: intl.formatMessage({ id: 'dimr.unit.microSievertPerHour' }),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.doseRateAlarmThreshold' }),
                                    path: 'doseRateAlarmThreshold',
                                    unit: intl.formatMessage({ id: 'dimr.unit.microSievertPerHour' }),
                                    recipe: intl.formatMessage(
                                        { id: 'dimr.recipe.rpa.doseRateAlarmThreshold' },
                                        { minMaxEstDoseRate: 2000, doseRate1: 100, doseRate2: 2000 }
                                    ),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'dimr.field.rpa.contaminatingWorks' }),
                                path: 'contaminatingWorks',
                            },
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstAirborneCont' }),
                                    path: 'maxEstAirborneCont',
                                    unit: intl.formatMessage({ id: 'dimr.unit.contaminationAirborne' }),
                                    recipe: intl.formatMessage(
                                        { id: 'dimr.recipe.rpa.maxEstAirborneCont' },
                                        { contaminationAirborne: '0.05' }
                                    ),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            sm: 6,
                            element: numberWithUnitSchemaFor(
                                {
                                    label: intl.formatMessage({ id: 'dimr.field.rpa.maxEstSurfaceCont' }),
                                    path: 'maxEstSurfaceCont',
                                    unit: intl.formatMessage({ id: 'dimr.unit.contaminationSurface' }),
                                    recipe: intl.formatMessage(
                                        { id: 'dimr.recipe.rpa.maxEstSurfaceCont' },
                                        { contaminationSurface: 1 }
                                    ),
                                },
                                intl
                            ),
                        },
                        {
                            xs: 12,
                            element: {
                                type: UiSchemaType.CHECKBOX,
                                label: intl.formatMessage({ id: 'dimr.field.rpa.forceValues' }),
                                path: 'valuesForcedManual',
                            },
                        },
                    ],
                },
            },
        },
    ],
});
