import {
    alpha,
    Backdrop,
    Box,
    Checkbox,
    CircularProgress,
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { ReactElement, useRef } from 'react';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { EmptyState, MissingStateReasonEnum } from '../../searching/EmptyState';

const CellComponent = styled(TableCell)(({ theme }) => ({
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: 'none',
    padding: theme.spacing(1),
    wordBreak: 'break-word',
    fontSize: 'smaller',
}));

const HeaderCell = styled(CellComponent)(({ theme }) => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    background: theme.palette.grey[100],
}));

const StyledTable = styled(MUITable)(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    width: `calc(100% - 1px)`,
})) as typeof MUITable;

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: alpha(theme.palette.grey[200], 0.5),
    color: theme.palette.primary.light,
    zIndex: theme.zIndex.drawer - 1,
})) as typeof Backdrop;

const SortLabel = styled(TableSortLabel)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
    },
    '&:active': {
        backgroundColor: theme.palette.grey[100],
    },
}));

interface ImpactTableProps<TData> {
    isLoading: boolean;
    isError: boolean;
    table: Table<TData>;
    selectable: boolean;
    selectedRows?: TData[];
    onSelect?: (name: TData[]) => void;
    getRowId?: (row: TData) => number;
}

export const ImpactTable = <TData,>({
    isLoading,
    isError,
    table,
    selectable,
    selectedRows = [],
    onSelect,
    getRowId = (x: any) => 0,
}: ImpactTableProps<TData>): ReactElement => {
    const parentRef = useRef<HTMLDivElement>(null);
    const virtualizer = useVirtualizer({
        count: table.getRowModel().rows?.length ?? 0,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 55,
    });
    const virtualRows = virtualizer.getVirtualItems();
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom =
        virtualRows.length > 0 ? virtualizer.getTotalSize() - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
    const { isMobile } = useDeviceType();
    const isSelected = (id: number) => selectedRows.map((x) => getRowId(x)).indexOf(id) !== -1;

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const element = table.getRowModel().rows[id].original;
        const selectedIndex = selectedRows.map((x) => getRowId(x)).indexOf(getRowId(element));
        let newSelected: TData[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, element);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        if (onSelect) {
            onSelect(newSelected);
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
            <StyledBackdrop open={!!isLoading}>
                <CircularProgress color="inherit" />
            </StyledBackdrop>
            {isError ? (
                <EmptyState
                    sx={{ position: 'absolute', top: 100 }}
                    reason={MissingStateReasonEnum.ERROR}
                    message="There has been an error fetching results"
                />
            ) : virtualRows?.length === 0 && !isLoading ? (
                <EmptyState sx={{ position: 'absolute', top: 100 }} />
            ) : null}
            <TableContainer
                ref={parentRef}
                sx={{
                    height: '100%',
                    overflow: 'auto',
                    width: '100%',
                    position: 'relative',
                    boxSizing: 'content-box',
                }}
            >
                <StyledTable stickyHeader>
                    <TableHead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {selectable && !isMobile && (
                                    <HeaderCell padding="checkbox" sx={{ minWidth: 32, maxWidth: 32 }} />
                                )}
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <HeaderCell
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={
                                                header.column.getCanSort()
                                                    ? header.column.getToggleSortingHandler()
                                                    : undefined
                                            }
                                            sx={{
                                                width: header.getSize(),
                                                minWidth: header.getSize(),
                                                cursor: header.column.getCanSort() ? 'pointer' : undefined,
                                            }}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {header.column.getCanSort() ? (
                                                        <SortLabel
                                                            active={header.column.getIsSorted() !== false}
                                                            direction={header.column.getIsSorted() || undefined}
                                                            onClick={header.column.getToggleSortingHandler()}
                                                        />
                                                    ) : null}
                                                </div>
                                            )}
                                        </HeaderCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHead>

                    <TableBody>
                        {paddingTop > 0 && (
                            <TableRow>
                                <CellComponent style={{ height: `${paddingTop}px` }} />
                            </TableRow>
                        )}
                        {virtualRows.map((virtualItem) => {
                            const { rows } = table.getRowModel();
                            const row = rows[virtualItem.index] as Row<TData>;
                            const isItemSelected = isSelected(getRowId(row.original));
                            return (
                                <TableRow
                                    key={row.id}
                                    ref={virtualizer.measureElement}
                                    data-index={row.id}
                                    onClick={(event) => handleClick(event, Number(row.id))}
                                    hover
                                    sx={{ ...(selectable ? { cursor: 'pointer' } : {}) }}
                                    selected={isItemSelected}
                                >
                                    {selectable && !isMobile && (
                                        <CellComponent
                                            padding="checkbox"
                                            sx={{
                                                textAlign: 'center',
                                                minWidth: 32,
                                                maxWidth: 32,
                                            }}
                                        >
                                            <Checkbox checked={isItemSelected} />
                                        </CellComponent>
                                    )}
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <CellComponent key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </CellComponent>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                        {paddingBottom > 0 && (
                            <TableRow>
                                <CellComponent style={{ height: `${paddingBottom}px` }} />
                            </TableRow>
                        )}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </Box>
    );
};
