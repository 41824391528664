import { AddCircle } from '@mui/icons-material';
import { WdpTab } from './tabs/WdpTab';
import { WorkStepsTab } from './tabs/WorkStepsTab';
import { fieldsMetadata, TableHeader } from './WdpInterfaces';

export const participantsHeaders: TableHeader[] = [
    { name: 'unknown', label: 'Unknown', width: 80 },
    {
        name: 'name',
        label: 'Unknown participant',
        readonly: (row: { unknown: boolean }) => !row.unknown,
        width: 200,
    },
    {
        name: 'person',
        label: 'Activity participant',
        readonly: (row: { unknown: boolean }) => row.unknown,
        width: 400,
        type: 'person',
        idKey: 'id',
    },
    {
        name: 'wdpWorkingTeamIdxs',
        label: 'Team(s)',
        readonly: false,
        width: 500,
        customType: fieldsMetadata.teams,
        type: 'multiautocomplete',
    },
    { name: 'opDoseLastMonth', label: 'Op. Dose last 31 days [µSv]', readonly: true },
    { name: 'opDoseLastYear', label: 'Op. Dose last 12 months [µSv]', readonly: true },
    { name: 'dosimetryStatus', label: 'Dosimetry status', readonly: true },
    { name: 'doseTaken', label: 'Dose taken [µSv]', readonly: true },
    { name: 'estimatedDose', label: 'Estimated dose [µSv]', readonly: true },
    { name: 'estDoseLastYear', label: 'Estimation next 12 months [µSv]', readonly: true },
    {
        name: 'totalWorkTime',
        label: 'Total individual working time [person.h]',
        readonly: true,
    },
];

export const WdpTabs = {
    TEAMS: {
        Component: WdpTab,
        label: 'Teams',
        props: {
            menuEntries: [],
            headers: [
                { name: 'name', label: 'Name', readonly: false, width: 220 },
                { name: 'description', label: 'Description', width: 800 },
            ],
            newRowSupplier: () => ({}), // No extra fields needed for new row
            metadata: fieldsMetadata.teams,
            spreadsheetProps: {},
        },
    },
    PARTICIPANTS: {
        Component: WdpTab,
        label: 'Participants',
        props: {
            menuEntries: [
                {
                    label: 'Import participants',
                    tooltip: 'Import participants from activity',
                    Icon: AddCircle,
                    onClick: () => 1, // TODO
                },
                {
                    label: 'Import e-group members',
                    tooltip: 'Import e-group members from activity',
                    Icon: AddCircle,
                    onClick: () => 1, // TODO,
                },
            ],
            headers: participantsHeaders,
            newRowSupplier: () => ({ unknown: false }),
            metadata: fieldsMetadata.participants,
            spreadsheetProps: {},
        },
    },
    WORKING_POSITIONS: {
        Component: WdpTab,
        label: 'Working positions',
        props: {
            menuEntries: [],
            headers: [
                { name: 'name', label: 'ID', readonly: false },
                { name: 'description', label: 'Description', width: 800 },
                { name: 'doseRate', label: 'Dose Rate' },
            ],
            newRowSupplier: () => ({}), // No extra fields needed for new row
            metadata: fieldsMetadata.positions,
            spreadsheetProps: {},
        },
    },
    WORK_STEPS: {
        Component: WorkStepsTab,
        label: 'Work Steps + Dose Estimation',
    },
    RP_ASSESSMENTS: {
        Component: () => null,
        label: 'RP Assessments',
    },
    FOLLOWUP: {
        Component: () => null,
        label: 'Follow-Up',
    },
};
