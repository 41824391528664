import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { getActivities, getActivityStatuses, getInterventionPeriodTypes } from '../../api/activities-nim';
import { ActivityGridReadDTO, SavedFilterType } from '../../api/dto';
import { getFacilities } from '../../api/values';
import { ImpactLink } from '../../components/ImpactLink';
import { PersonTooltip } from '../../components/PersonTooltip';
import { DocumentResultCard } from '../../components/searching/DocumentResultCard';
import { FilterTypeEnum } from '../../components/searching/filters';
import { SearchPage } from '../../components/searching/SearchPage';
import { StatusChip } from '../../components/StatusChip';
import { DATATYPES } from '../../components/tables/table/utils';
import { accessPointFilterSchema } from '../../forms/schemas/accessPoints';
import { locationFilterSchema } from '../../forms/schemas/location';
import { personFilterSchema } from '../../forms/schemas/person';
import { valueFilterSchema } from '../../forms/schemas/value';
import { TFilterElement } from '../../forms/types';
import { EntityType } from '../../lib/information/types/EntityType';
import { getLovLabel } from '../../lib/label';
import { translateValue } from '../../lib/language';
import { formatVersionedId } from '../../lib/records/documents/versioned';
import { getActivityStatus } from '../../lib/status';
import { getRoute, ROUTES } from '../../routes/routes';

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    personFilterSchema({ id: 'createdBy.searchLabel', label: intl.formatMessage({ id: 'document.field.creator' }) }),
    personFilterSchema({
        id: 'whoTechResp.searchLabel',
        label: intl.formatMessage({ id: 'document.field.responsible' }),
    }),
    {
        id: 'whoTechResp.orgUnit.code',
        label: intl.formatMessage({ id: 'document.field.responsibleGroup' }),
        type: FilterTypeEnum.STRING,
    },
    personFilterSchema({
        id: 'activityParticipants.person.searchLabel',
        label: intl.formatMessage({ id: 'activity.field.participant' }),
    }),
    locationFilterSchema({
        id: 'activityLocations.location.id',
        label: intl.formatMessage({ id: 'document.field.location' }),
    }),
    valueFilterSchema(
        {
            id: 'status.code',
            label: intl.formatMessage({ id: 'document.field.status' }),
            fetchOptions: getActivityStatuses,
        },
        intl.locale
    ),
    valueFilterSchema(
        {
            id: 'whenIntervPer.periodType.code',
            label: intl.formatMessage({ id: 'activity.field.interventionPeriod' }),
            fetchOptions: getInterventionPeriodTypes,
        },
        intl.locale
    ),
    valueFilterSchema(
        {
            id: 'whatFacility.code',
            label: intl.formatMessage({ id: 'activity.field.facility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
    accessPointFilterSchema({
        id: 'activityLocations.location.accessPoints.name',
        label: intl.formatMessage({ id: 'document.location.info.accessPoints' }),
    }),
    {
        id: 'whatTitle',
        label: intl.formatMessage({ id: 'document.field.title' }),
        type: FilterTypeEnum.STRING,
    },
    {
        id: 'whatDescription',
        label: intl.formatMessage({ id: 'document.field.description' }),
        type: FilterTypeEnum.STRING,
    },
    // TODO system tree, approval status, approval type, activity type and meeting type when we have the data
    {
        id: 'id',
        label: 'ID',
        type: FilterTypeEnum.NUMBER,
    },
];

const columnHelper = createColumnHelper<ActivityGridReadDTO>();

const getColumns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.id ?? '', {
        id: 'id',
        header: intl.formatMessage({ id: 'activity.name' }),
        size: 80,
        cell: ({ getValue, row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.activities.view,
                    params: { id: (original as any).id },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => getLovLabel(translateValue(row.status, intl.locale)) ?? '', {
        id: 'status.label',
        header: intl.formatMessage({ id: 'document.field.status' }),
        size: 120,
        cell: ({ row }) => <StatusChip {...getActivityStatus(row.original, intl)} size="small" />,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
    }),
    columnHelper.accessor((row) => row.whoTechResp?.orgUnit?.code ?? '', {
        id: 'responsible.orgUnit',
        header: intl.formatMessage({ id: 'document.field.responsibleGroup' }),
        size: 80,
    }),
    columnHelper.accessor(
        (row) => (row.whatFacility?.description ? getLovLabel(translateValue(row.whatFacility, intl.locale)) : ''),
        {
            id: 'facility.description',
            header: intl.formatMessage({ id: 'activity.field.facility' }),
            size: 120,
            meta: {
                dataType: DATATYPES.ENUMERATION,
            },
        }
    ),
    columnHelper.accessor((row) => row.whatSystem?.name ?? '', {
        id: 'system.name',
        header: intl.formatMessage({ id: 'activity.field.system' }),
        size: 100,
    }),
    columnHelper.accessor((row) => row.whatTitle ?? '', {
        id: 'whatTitle',
        header: intl.formatMessage({ id: 'document.field.title' }),
        size: 260,
    }),
    columnHelper.accessor((row) => row.whoMaxNbParticipants ?? '', {
        id: 'maxNumberOfParticipants',
        header: intl.formatMessage({ id: 'activity.field.maxNumberOfParticipants' }),
        size: 50,
    }),
    columnHelper.accessor((row) => row.whatDescription ?? '', {
        id: 'description',
        header: intl.formatMessage({ id: 'document.field.description' }),
        size: 300,
    }),
    columnHelper.accessor((row) => row.whenPropDate ?? '', {
        id: 'whenPropDate',
        header: intl.formatMessage({ id: 'activity.field.whenPropDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenDuration ?? '', {
        id: 'whenDuration',
        header: intl.formatMessage({ id: 'activity.field.whenDuration' }),
        size: 100,
        cell: ({ row: { original }, getValue }) =>
            !!original.whenDuration &&
            !!original.whenDurationType && (
                <Typography variant="inherit">
                    {`${getValue()} ${
                        intl.locale === 'fr' ? original.whenDurationType.labelFr : original.whenDurationType.labelEn
                    }`}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenWorkingTimeAvail ?? '', {
        id: 'whenWorkingTimeAvail',
        header: intl.formatMessage({ id: 'activity.field.whenWorkingTimeAvail' }),
        size: 100,
        cell: ({ row: { original }, getValue }) =>
            !!original.whenWorkingTimeAvail && (
                <Typography variant="inherit">
                    {intl.locale === 'fr' ? getValue().labelFr : getValue().labelEn}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenScheduledStart ?? '', {
        id: 'whenScheduledStart',
        header: intl.formatMessage({ id: 'activity.field.whenScheduledStart' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenScheduledEnd ?? '', {
        id: 'whenScheduledEnd',
        header: intl.formatMessage({ id: 'activity.field.whenScheduledEnd' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenAccessStart ?? '', {
        id: 'whenAccessStart',
        header: intl.formatMessage({ id: 'activity.field.whenAccessStart' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenAccessEnd ?? '', {
        id: 'whenAccessEnd',
        header: intl.formatMessage({ id: 'activity.field.whenAccessEnd' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whenWorkingTimeGtd, {
        id: 'whenWorkingTimeGtd',
        header: intl.formatMessage({ id: 'activity.field.whenWorkingTimeGtd' }),
        size: 100,
        cell: ({ row: { original }, getValue }) =>
            !!original.whenWorkingTimeGtd && (
                <Typography variant="inherit">
                    {intl.locale === 'fr' ? getValue()?.labelFr : getValue()?.labelEn}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.is37s, {
        id: 'is37s',
        header: intl.formatMessage({ id: 'is37.name' }),
        size: 250,
        enableSorting: false,
        cell: ({ row: { original }, getValue }) =>
            !!original.is37s && (
                <Typography variant="inherit">
                    {getValue().map((is37, index) => (
                        <React.Fragment key={is37.id}>
                            {index > 0 && ', '}
                            <ImpactLink
                                to={getRoute({
                                    path: ROUTES.is37.view,
                                    params: { id: is37.id },
                                })}
                            >
                                {is37.id}
                            </ImpactLink>
                        </React.Fragment>
                    ))}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.firePermits, {
        id: 'firePermits',
        header: intl.formatMessage({ id: 'firePermit.name' }),
        size: 250,
        enableSorting: false,
        cell: ({ row: { original }, getValue }) =>
            !!original.firePermits && (
                <Typography variant="inherit">
                    {getValue().map((firePermit, index) => (
                        <React.Fragment key={firePermit.id}>
                            {index > 0 && ', '}
                            <ImpactLink
                                to={getRoute({
                                    path: ROUTES.firePermit.view,
                                    params: { id: firePermit.id },
                                })}
                            >
                                {firePermit.id}
                            </ImpactLink>
                        </React.Fragment>
                    ))}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.rpAssessments, {
        id: 'dimrs',
        header: intl.formatMessage({ id: 'dimr.name' }),
        size: 250,
        enableSorting: false,
        cell: ({ row: { original }, getValue }) =>
            !!original.rpAssessments && (
                <Typography variant="inherit">
                    {getValue().map(
                        (rpAssessment, index) =>
                            !!rpAssessment.dimrVersion && (
                                <React.Fragment key={rpAssessment.dimrVersion.dimrMaster.id}>
                                    {index > 0 && ', '}
                                    <ImpactLink
                                        to={getRoute({
                                            path: ROUTES.dimr.view,
                                            params: {
                                                id: rpAssessment.dimrVersion.dimrMaster.id,
                                                version: rpAssessment.dimrVersion.versionNumber,
                                            },
                                        })}
                                    >
                                        {formatVersionedId(
                                            rpAssessment.dimrVersion.dimrMaster.id,
                                            rpAssessment.dimrVersion.versionNumber
                                        )}
                                    </ImpactLink>
                                </React.Fragment>
                            )
                    )}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.activityLocations, {
        id: 'locations',
        header: intl.formatMessage({ id: 'document.field.locations' }),
        size: 250,
        enableSorting: false,
        cell: ({ row: { original }, getValue }) =>
            !!original.activityLocations && (
                <Typography variant="inherit">
                    {getValue()
                        .map((location) => location.location?.impactName)
                        .join(', ')}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.lastModifiedBy?.searchLabel ?? '', {
        id: 'lastModifiedBy',
        header: intl.formatMessage({ id: 'activity.field.lastModifiedBy' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.lastModifiedBy && (
                <PersonTooltip person={original.lastModifiedBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.lastModifiedDate ?? '', {
        id: 'lastModifiedDate',
        header: intl.formatMessage({ id: 'activity.field.lastModifiedDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.createdBy?.searchLabel ?? '', {
        id: 'createdBy',
        header: intl.formatMessage({ id: 'document.field.creator' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.createdBy && (
                <PersonTooltip person={original.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whoTechResp?.searchLabel, {
        id: 'responsible.searchLabel',
        header: intl.formatMessage({ id: 'document.field.responsible' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.whoTechResp && (
                <PersonTooltip person={original.whoTechResp}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.whoTechResp?.phone1, {
        id: 'phone',
        header: `${intl.formatMessage({ id: 'document.person.phone' })} / ${intl.formatMessage({
            id: 'document.person.mobile',
        })}`,
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.whoTechResp?.phone1 && !!original.whoTechResp?.phone3Display ? (
                <Typography variant="inherit">
                    {getValue()} / {original.whoTechResp.phone3Display}
                </Typography>
            ) : (
                <Typography variant="inherit">{getValue()}</Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.activityCluster?.title, {
        id: 'clusterTitle',
        header: intl.formatMessage({ id: 'activity.field.clusterTitle' }),
        size: 80,
        cell: ({ row: { original }, getValue }) =>
            !!original.activityCluster?.title && <Typography variant="inherit">{getValue()}</Typography>,
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.activityCluster?.status, {
        id: 'clusterStatus',
        header: intl.formatMessage({ id: 'activity.field.clusterStatus' }),
        size: 80,
        cell: ({ row: { original }, getValue }) =>
            !!original.activityCluster?.status && (
                <Typography variant="inherit">
                    {intl.locale === 'fr' ? getValue()?.labelFr : getValue()?.labelEn}
                </Typography>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.activityCluster?.responsible?.searchLabel, {
        id: 'clusterResponsible',
        header: intl.formatMessage({ id: 'activity.field.clusterResponsible' }),
        size: 80,
        cell: ({ row: { original }, getValue }) =>
            !!original.activityCluster?.responsible && (
                <PersonTooltip person={original.activityCluster?.responsible}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
        meta: {
            initiallyHidden: true,
        },
    }),
    columnHelper.accessor((row) => row.tagActivities, {
        id: 'tags',
        header: intl.formatMessage({ id: 'activity.field.tags' }),
        size: 80,
        cell: ({ row: { original }, getValue }) =>
            !!original.tagActivities && (
                <Typography variant="inherit">
                    {getValue()
                        .map((tag) => tag.tag.name)
                        .join(', ')}
                </Typography>
            ),
    }),
    //TODO approval type, approval status, approved date, guides and comments after importing them from OIM
];

export const ActivitySearchPage = () => {
    const intl = useIntl();
    const columns = getColumns(intl);

    return (
        <SearchPage
            title="Activity search"
            filterDialogTitle="Activity"
            columns={columns}
            pageKey="ActivitySearchPage"
            documentType={null} // TODO
            filterSchemas={filterSchemas(intl)}
            hiddenColumnsIds={columns
                .filter((column) => column.meta?.initiallyHidden)
                .map((column) => column.id as string)}
            pagedApiCall={getActivities}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.ActivityNim, activity: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'id', desc: true }}
            selectable={false}
            savedFilterType={SavedFilterType.ACTIVITY}
        />
    );
};
