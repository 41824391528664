import { add } from 'date-fns';
import {
    DISTRIBUTION_TYPES,
    NdcDistributionTargetFormValue,
    NdcDistributionTargetType,
} from '../../../api/documents/noteDeCoupures';
import {
    ActivityReadDTO,
    ListOfValuesReadDTO,
    NdcDistributionTargetReadDTO,
    NdcDistributionTargetUpdateDTO,
    NdcLocationUpdateDTO,
    NdcReadDTO,
    NdcUpdateDTO,
    PersonRichReadDTO,
} from '../../../api/dto';
import { getPreferredLanguageValue } from '../../language';

export const makeDefaultValuesFromNdc = (document: NdcReadDTO) => {
    const makeDistributionTargets = (
        targets: NdcDistributionTargetReadDTO[] | null
    ): NdcDistributionTargetFormValue[] => {
        if (targets === null) return [];
        return targets.map((target) => ({
            typeCode: String(target?.type?.code),
            value: target.egroup || target.email || target.person,
        }));
    };

    if (!document) return undefined;
    return {
        interventionType: document.interventionType,
        language: document.language,
        cutExecutor: document.cutExecutor,
        responsibleGroup: document.responsibleGroup,
        requestedBy: document.requestedBy,
        approvedBy: document.approvedBy,

        title: document.title,
        subtitle: document.subtitle ?? '',
        workToBePerformed: document.workToBePerformed ?? '',
        note: document.note ?? '',
        severity: document.severity,

        startDate: document.startDate,
        endDate: document.endDate,

        locations: document.locations ?? [],

        affectedNetworksAndEquipment: document.affectedNetworksAndEquipment ?? [],

        fireService: true, // Read-only
        controlRoom: true, // Read-only
        facilityCoordinatorsAffected: document.facilityCoordinatorsAffected,
        bldgOccupantsAffected: document.bldgOccupantsAffected,
        bldgLevelsOccupantsAffected: document.bldgLevelsOccupantsAffected,
        bldgTsosAndDtsosAffected: document.bldgTsosAndDtsosAffected,

        distributionTargets: makeDistributionTargets(document.distributionTargets),

        version: document.version,
    };
};

export const makeDefaultValuesFromLinkedActivity = (
    linkedActivity: ActivityReadDTO,
    loggedUser: PersonRichReadDTO | undefined,
    availableLanguages: ListOfValuesReadDTO[]
) => {
    const startDate: Date | null = new Date(linkedActivity.proposedStartDate || '') || null;
    let endDate: Date | null = null;
    if (startDate) {
        const durationKeys: { [key: string]: 'days' | 'hours' | 'minutes' } = {
            DT_DAY: 'days',
            DT_HOUR: 'hours',
            DT_MINUTE: 'minutes',
        };
        endDate = add(startDate, {
            [durationKeys[linkedActivity.durationType?.code || '']]: linkedActivity.duration || 0,
        });
    }
    const locations = (linkedActivity.locations || []).map(
        (location): NdcLocationUpdateDTO => ({
            location: location.location,
            designation: '',
            equipment: [],
        })
    );
    const language = loggedUser ? getPreferredLanguageValue(loggedUser, availableLanguages) : undefined;

    return {
        title: linkedActivity.title,
        workToBePerformed: linkedActivity.description,
        note: linkedActivity.modusOperandi,
        startDate: startDate,
        endDate: endDate,
        locations: locations,
        cutExecutor: loggedUser,
        requestedBy: loggedUser,
        responsibleGroup: loggedUser?.group,
        language,

        fireService: true, // Read-only
        controlRoom: true, // Read-only
        facilityCoordinatorsAffected: true,

        affectedNetworksAndEquipment: [],
        distributionTargets: [],
        activity: { id: linkedActivity.id },
    };
};
export const makeDefaultValuesFromClonedNdc = (originalNdc: NdcReadDTO, linkedActivity: ActivityReadDTO) => {
    return {
        ...makeDefaultValuesFromNdc(originalNdc),
        activity: { id: linkedActivity.id },
        requestedBy: linkedActivity.createdBy,
        approvedBy: undefined,
    };
};

export const makeDistributionTargetsFormValues = (
    values: NdcDistributionTargetType[],
    typeCode: string
): NdcDistributionTargetFormValue[] => {
    return values.map((value) => ({
        typeCode: typeCode,
        value: value,
    }));
};

const mapDistributionTargets = (targets: NdcDistributionTargetFormValue[]): NdcDistributionTargetUpdateDTO[] => {
    return targets.map((target) => {
        const targetDTO: NdcDistributionTargetUpdateDTO = {
            type: { code: target.typeCode },
            egroup: null,
            email: null,
            person: null,
        };
        switch (target.typeCode) {
            case DISTRIBUTION_TYPES.PERSON:
                targetDTO.person = target.value;
                break;
            case DISTRIBUTION_TYPES.EGROUP:
                targetDTO.egroup = target.value;
                break;
            case DISTRIBUTION_TYPES.EMAIL:
                targetDTO.email = target.value;
                break;
        }

        return targetDTO;
    });
};

export const mapNdcToUpdateDTO = (ndc: any, isCreation: boolean = false): NdcUpdateDTO & NdcReadDTO => {
    const { activityId, version, distributionTargets, ...rest } = ndc;

    return {
        ...rest,
        activityId: isCreation ? activityId : null,
        version: isCreation ? null : version,
        distributionTargets: mapDistributionTargets(distributionTargets),
    };
};
