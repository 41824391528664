import {
    AccessTime,
    AddCircleOutline,
    Comment,
    Notifications,
    NotificationsActive,
    NotificationsOff,
    Settings,
    Warehouse,
} from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { getIs37AlarmDisablementTypes, getIs37WorkTypes } from '../../../api/documents/is37s';
import { IS37ChangeRequestReadDTO } from '../../../api/dto';
import { LinkedDocumentsContent } from '../../../components/documents/LinkedDocumentsContent';
import { fetchFrsOfficersByName } from '../../../lib/api';
import { EntityType } from '../../../lib/information/types/EntityType';
import { IS37LocationActivityFacilityWarning } from '../../fields/specific/IS37LocationActivityFacilityWarning';
import { LayoutGridItem, TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { firePermitSchema } from '../firePermit';
import { is37AlarmTypeSchema } from '../is37AlarmType';
import { locationSchema } from '../location';
import { personSchema } from '../person';
import { sensorsSchema } from '../sensors';
import { supplierSchema } from '../supplier';
import { valueSchema } from '../value';

const interventionSchemaFor = ({ path }: { path: string }, intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_RESPONSIVE,
    elements: [
        {
            element: personSchema({
                path: `${path}.doneBy`,
                label: intl.formatMessage({ id: 'is37.field.doneBy' }),
            }),
        },
        {
            element: supplierSchema({
                path: `${path}.company`,
                label: intl.formatMessage({ id: 'document.field.company' }),
                readOnly: true,
            }),
        },
        {
            element: {
                type: UiSchemaType.DATETIME,
                path: `${path}.interventionDate`,
                label: intl.formatMessage({ id: 'is37.field.interventionDate' }),
                typeProperties: {
                    maxDate: new Date(),
                },
            },
        },
        {
            element: personSchema({
                path: `${path}.operator`,
                label: intl.formatMessage({ id: 'is37.field.savedBy' }),
                readOnly: true,
            }),
        },
        {
            element: {
                type: UiSchemaType.DATETIME,
                path: `${path}.inputDate`,
                label: intl.formatMessage({ id: 'is37.field.inputDate' }),
                readOnly: true,
            },
        },
    ],
});

export const uiSchemaIs37For = (
    hasDisablement: boolean,
    hasRecommissioning: boolean,
    intl: IntlShape,
    changeRequests: IS37ChangeRequestReadDTO[],
    openCRDialog: React.Dispatch<any>,
    canCreateChangeRequest: boolean,
    locationActivityFacilityWarning: boolean
): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'document.field.responsible' }),
                            path: 'responsible',
                        }),
                    },
                    {
                        xs: 12,
                        element: firePermitSchema({
                            label: intl.formatMessage({ id: 'firePermit.name' }),
                            path: 'firePermit',
                        }),
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'is37.panel.periodScheduled' }),
            typeProperties: {
                icon: <AccessTime color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    {
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'is37.field.disablementType' }),
                                path: 'disablementType',
                                fetchOptions: getIs37AlarmDisablementTypes,
                            },
                            intl.locale
                        ),
                    },
                    {
                        element: personSchema({
                            label: intl.formatMessage({ id: 'is37.field.frsOfficer' }),
                            path: 'frsOfficer',
                            fetchEndpoint: fetchFrsOfficersByName,
                        }),
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.DATETIME,
                                        label: intl.formatMessage({ id: 'is37.field.startDate' }),
                                        path: 'startDate',
                                        typeProperties: {
                                            minDate: new Date(),
                                        },
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.DATETIME,
                                        label: intl.formatMessage({ id: 'is37.field.endDate' }),
                                        path: 'endDate',
                                        typeProperties: {
                                            minDate: new Date(),
                                        },
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.TIME,
                                        label: intl.formatMessage({ id: 'is37.field.recommissioningStart' }),
                                        path: 'recommissioningStart',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.TIME,
                                        label: intl.formatMessage({ id: 'is37.field.recommissioningEnd' }),
                                        path: 'recommissioningEnd',
                                    },
                                },
                            ],
                        },
                    },
                    ...((canCreateChangeRequest
                        ? [
                              {
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: () => (
                                          <Box
                                              sx={{
                                                  border: 1,
                                                  borderColor: grey[400],
                                                  borderRadius: 1,
                                                  p: 2,
                                                  borderStyle: 'dashed',
                                                  padding: 0,
                                              }}
                                          >
                                              <Button
                                                  onClick={openCRDialog}
                                                  startIcon={<AddCircleOutline />}
                                                  sx={{
                                                      width: '100%',
                                                      borderRadius: 1,
                                                  }}
                                              >
                                                  <FormattedMessage id="is37.changeRequest.create" />
                                              </Button>
                                          </Box>
                                      ),
                                  },
                              },
                          ]
                        : []) satisfies LayoutGridItem[]),
                    ...((changeRequests.length > 0
                        ? [
                              {
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: () => (
                                          <LinkedDocumentsContent
                                              documents={changeRequests
                                                  .sort(
                                                      (a, b) =>
                                                          new Date(b.createdDate).getTime() -
                                                          new Date(a.createdDate).getTime()
                                                  )
                                                  .map((is37ChangeRequest) => ({
                                                      type: EntityType.Is37ChangeRequest,
                                                      is37ChangeRequest,
                                                  }))}
                                          />
                                      ),
                                  },
                              },
                          ]
                        : []) satisfies LayoutGridItem[]),
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'is37.panel.location' }),
            typeProperties: {
                icon: <Warehouse color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        sm: 6,
                        element: locationSchema({
                            label: intl.formatMessage({ id: 'is37.field.locations' }),
                            path: 'locations',
                            multiple: true,
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'is37.field.locationDetails' }),
                            path: 'locationDetails',
                        },
                    },
                    ...(!locationActivityFacilityWarning
                        ? [
                              {
                                  xs: 12,
                                  element: {
                                      type: UiSchemaType.CUSTOM,
                                      Component: IS37LocationActivityFacilityWarning,
                                  },
                              } satisfies LayoutGridItem,
                          ]
                        : []),
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'is37.panel.justification' }),
            typeProperties: {
                icon: <Comment color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    {
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'is37.field.workTypes' }),
                                path: 'workTypes',
                                fetchOptions: getIs37WorkTypes,
                                multiple: true,
                            },
                            intl.locale
                        ),
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'is37.field.workTypeJustification' }),
                            path: 'workTypeJustification',
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'is37.panel.alarmType' }),
            typeProperties: {
                icon: <Notifications color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: is37AlarmTypeSchema(
                            {
                                path: 'alarmType',
                                label: intl.formatMessage({ id: 'is37.field.alarmType' }),
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        element: sensorsSchema({
                            label: intl.formatMessage({ id: 'is37.field.sensors' }),
                            path: 'sensors',
                            required: false,
                        }),
                    },
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'is37.field.compensatoryMeasures' }),
                            path: 'compensatoryMeasures',
                        },
                    },
                ],
            },
        },
        ...(hasDisablement || hasRecommissioning
            ? [
                  {
                      type: UiSchemaType.LAYOUT_GRID,
                      elements: [
                          ...(hasDisablement
                              ? [
                                    {
                                        xs: 12,
                                        md: hasRecommissioning ? 6 : undefined,
                                        element: {
                                            type: UiSchemaType.PANEL,
                                            label: intl.formatMessage({ id: 'is37.field.disabledBy' }),
                                            typeProperties: {
                                                icon: <NotificationsOff color="action" />,
                                            },
                                            element: interventionSchemaFor({ path: 'disablement' }, intl),
                                        },
                                    } satisfies LayoutGridItem,
                                ]
                              : []),
                          ...(hasRecommissioning
                              ? [
                                    {
                                        xs: 12,
                                        md: hasDisablement ? 6 : undefined,
                                        element: {
                                            type: UiSchemaType.PANEL,
                                            label: intl.formatMessage({ id: 'is37.field.recommissionedBy' }),
                                            typeProperties: {
                                                icon: <NotificationsActive color="action" />,
                                            },
                                            element: interventionSchemaFor({ path: 'recommissioning' }, intl),
                                        },
                                    } satisfies LayoutGridItem,
                                ]
                              : []),
                      ],
                  } satisfies TFormElement,
              ]
            : []),
    ],
});
