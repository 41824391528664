import { CellStyle } from '@silevis/reactgrid';

const aggregateEntries = <T>(list: [string, T][]) =>
    list.reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {});

export const accessValue = (obj: object, field: string) =>
    field.split('.').reduce((acc, key) => acc?.[key], obj as any);

export const filterObjectFieldsFromList = <T>(object: { [key: string]: T }, fieldList: string[]) =>
    object && aggregateEntries(Object.entries(object).filter(([entry]) => fieldList.includes(entry)));

export const updateListIdxs = <T, U>(listIdx: T[], idKey: keyof T, listToChange: U[], listId: keyof U) => {
    const refDTOmap: Record<string, number> = listIdx.reduce(
        (acc, el, idx: number) => Object.assign(acc, { [el[idKey] as string]: idx }),
        {}
    );
    return listToChange.map((el) => ({
        ...el,
        [listId]: (el[listId] as T[] | undefined)
            ?.map((elem) => refDTOmap[elem[idKey] as string])
            .filter((elem: number) => elem)
            .map((elem: number) => ({ [idKey]: elem })),
    }));
};

export const createEnum = <T extends string>(keys: T[]): { [K in T]: K } =>
    keys.reduce((acc, el) => ({ ...acc, [el]: el }), {} as { [K in T]: K });

export const tableHeaderStyle = {
    background: '#515151',
    color: 'lightgrey',
    overflow: 'auto',
    whiteSpace: 'break-spaces',
};

export const tableCellStyle: CellStyle = {
    background: '#ffd7b5',
};
