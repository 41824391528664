import { Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { getIs37s, getIs37Statuses } from '../../../api/documents/is37s';
import { CsamSensorReadDTO, DocumentType, IS37ReadDTO, SavedFilterType } from '../../../api/dto';
import { getFacilities } from '../../../api/values';
import { ImpactLink } from '../../../components/ImpactLink';
import { PersonTooltip } from '../../../components/PersonTooltip';
import { DocumentResultCard } from '../../../components/searching/DocumentResultCard';
import { FilterOperatorEnum, FilterTypeEnum, SearchFilter } from '../../../components/searching/filters';
import { SearchPage } from '../../../components/searching/SearchPage';
import { StatusChip } from '../../../components/StatusChip';
import { DATATYPES } from '../../../components/tables/table/utils';
import { FetchUseCase } from '../../../forms/fields/AsyncAutocompleteElement';
import { is37AlarmTypeFilterSchema } from '../../../forms/schemas/is37AlarmType';
import { locationFilterSchema } from '../../../forms/schemas/location';
import { orgUnitFilterSchema } from '../../../forms/schemas/orgUnit';
import { personFilterSchema } from '../../../forms/schemas/person';
import { valueFilterSchema } from '../../../forms/schemas/value';
import { TFilterElement } from '../../../forms/types';
import { fetchSensorsByName } from '../../../lib/api';
import { EntityType } from '../../../lib/information/types/EntityType';
import { getCsamSensorLabel } from '../../../lib/label';
import { translateValue } from '../../../lib/language';
import { getIs37Status } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';

const STATUS_ID = 'status.code';
const ALARM_TYPE_ID = 'alarmTypeDetails.csamType';
const CSAM_SENSOR_ID = 'sensors.sensor.id';
const RESPONSIBLE_ID = 'responsible.searchLabel';
const CREATOR_ID = 'createdBy.searchLabel';
const ALARM_TYPE_RESP_GROUP_CODE = 'alarmTypeDetails.responsibleGroup.code';
const LOCATION_ID = 'locations.location.id';
const ACTIVITY_FACILITY_ID = 'activity.facility.code';

const filterSchemas = (intl: IntlShape): TFilterElement[] => [
    {
        id: 'id',
        label: intl.formatMessage({ id: 'is37.field.id' }),
        type: FilterTypeEnum.STRING,
    },
    valueFilterSchema(
        { id: STATUS_ID, label: intl.formatMessage({ id: 'document.field.status' }), fetchOptions: getIs37Statuses },
        intl.locale
    ),
    is37AlarmTypeFilterSchema(
        { id: ALARM_TYPE_ID, label: intl.formatMessage({ id: 'is37.field.alarmType' }) },
        intl.locale
    ),
    orgUnitFilterSchema({
        id: ALARM_TYPE_RESP_GROUP_CODE,
        label: intl.formatMessage({ id: 'is37.field.alarmTypeResponsibleGroup' }),
    }),
    locationFilterSchema({ id: LOCATION_ID, label: intl.formatMessage({ id: 'document.field.location' }) }),
    personFilterSchema({ id: RESPONSIBLE_ID, label: intl.formatMessage({ id: 'document.field.responsible' }) }),
    personFilterSchema({ id: CREATOR_ID, label: intl.formatMessage({ id: 'document.field.creator' }) }),
    {
        id: CSAM_SENSOR_ID,
        label: intl.formatMessage({ id: 'is37.field.sensor' }),
        type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
        valueGetter: (value: CsamSensorReadDTO) => value.id,
        typeProperties: {
            fetchOptions: fetchSensorsByName,
            fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
            getOptionLabel: getCsamSensorLabel,
            autocompleteProps: {
                openOnFocus: true,
                disableCloseOnSelect: false,
            },
        },
    },
    {
        id: 'startDate',
        label: intl.formatMessage({ id: 'is37.field.startDate' }),
        type: FilterTypeEnum.DATE,
    },
    {
        id: 'endDate',
        label: intl.formatMessage({ id: 'is37.field.endDate' }),
        type: FilterTypeEnum.DATE,
    },
    valueFilterSchema(
        {
            id: ACTIVITY_FACILITY_ID,
            label: intl.formatMessage({ id: 'activity.field.facility' }),
            fetchOptions: getFacilities,
        },
        intl.locale
    ),
];

const columnHelper = createColumnHelper<IS37ReadDTO>();

const columns = (intl: IntlShape) => [
    columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: intl.formatMessage({ id: 'is37.field.id' }),
        size: 80,
        cell: ({ getValue, row: { original } }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.is37.view,
                    params: { id: original.id },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => row.activity.id ?? '', {
        id: 'activity.id',
        header: intl.formatMessage({ id: 'activity.name' }),
        size: 80,
        cell: ({ getValue }) => (
            <ImpactLink
                to={getRoute({
                    path: ROUTES.activities.view,
                    params: { id: String(getValue()) },
                })}
            >
                {getValue()}
            </ImpactLink>
        ),
    }),
    columnHelper.accessor((row) => row.responsible.searchLabel, {
        id: 'responsible.searchLabel',
        header: intl.formatMessage({ id: 'document.field.responsible' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.responsible && (
                <PersonTooltip person={original.responsible}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => translateValue(row.status, intl.locale).label, {
        id: 'status.label',
        header: intl.formatMessage({ id: 'document.field.status' }),
        size: 140,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
        cell: ({ row }) => <StatusChip {...getIs37Status(row.original, intl)} size="small" />,
    }),
    columnHelper.accessor((row) => row.locations, {
        id: 'locations',
        header: intl.formatMessage({ id: 'document.field.locations' }),
        size: 200,
        enableSorting: false,
        cell: ({ row: { original }, getValue }) =>
            !!original.locations && (
                <Typography variant="inherit">
                    {getValue()
                        .map((location) => location.impactName)
                        .join(', ')}
                </Typography>
            ),
    }),
    columnHelper.accessor(
        (row) => (row.activity.facility ? translateValue(row.activity.facility, intl.locale).label : ''),
        {
            id: 'activity.facility.label',
            header: intl.formatMessage({ id: 'document.field.facility' }),
            size: 120,
        }
    ),
    columnHelper.accessor((row) => row.createdBy.searchLabel ?? '', {
        id: 'createdBy.searchLabel',
        header: intl.formatMessage({ id: 'document.field.creator' }),
        size: 200,
        cell: ({ row: { original }, getValue }) =>
            !!original.responsible && (
                <PersonTooltip person={original.createdBy}>
                    <Typography variant="inherit">{getValue()}</Typography>
                </PersonTooltip>
            ),
    }),
    columnHelper.accessor((row) => row.startDate ?? '', {
        id: 'startDate',
        header: intl.formatMessage({ id: 'is37.field.startDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => row.endDate ?? '', {
        id: 'endDate',
        header: intl.formatMessage({ id: 'is37.field.endDate' }),
        size: 100,
        meta: {
            dataType: DATATYPES.DATE,
        },
    }),
    columnHelper.accessor((row) => (row.alarmType?.type ? translateValue(row.alarmType.type, intl.locale).label : ''), {
        id: 'alarmType.label',
        header: intl.formatMessage({ id: 'is37.field.alarmType' }),
        size: 120,
        meta: {
            dataType: DATATYPES.ENUMERATION,
        },
    }),
    columnHelper.accessor((row) => row.sensors, {
        id: 'sensors',
        header: intl.formatMessage({ id: 'is37.field.sensors' }),
        size: 120,
        enableSorting: false,
        cell: ({ row: { original }, getValue }) =>
            !!original.sensors && (
                <Typography variant="inherit">
                    <FormattedMessage id="is37.nSensors" values={{ count: getValue()?.length ?? 0 }} />
                </Typography>
            ),
    }),
];

const todayDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
};

const getUpcomingDisablingFilters = (): SearchFilter[] => [
    {
        field: 'startDate',
        operator: FilterOperatorEnum.ON_OR_AFTER,
        value: todayDate(),
    },
];

const getUpcomingRecommissioningFilters = (): SearchFilter[] => [
    {
        field: 'endDate',
        operator: FilterOperatorEnum.ON_OR_AFTER,
        value: todayDate(),
    },
];

export const IS37SearchPage = () => {
    const intl = useIntl();
    return (
        <SearchPage
            title={intl.formatMessage({ id: 'title.is37Search' })}
            filterDialogTitle={intl.formatMessage({ id: 'is37.name' })}
            columns={columns(intl)}
            pageKey="IS37SearchPage"
            documentType={DocumentType.IS37}
            filterSchemas={filterSchemas(intl)}
            pagedApiCall={getIs37s}
            selectable={false}
            CardItem={({ item, handleExpandToggle, isExpanded }) => (
                <DocumentResultCard
                    key={item.id}
                    document={{ type: EntityType.Is37, is37: item }}
                    isExpanded={isExpanded(item)}
                    onExpandToggle={() => handleExpandToggle(item)}
                />
            )}
            initialSort={{ id: 'startDate', desc: true }}
            predefinedFilters={[
                {
                    filters: getUpcomingDisablingFilters(),
                    labelKey: 'search.filters.is37.upcomingDisabling',
                    sorting: [{ id: 'startDate', desc: false }],
                },
                {
                    filters: getUpcomingRecommissioningFilters(),
                    labelKey: 'search.filters.is37.upcomingRecommissioning',
                    sorting: [{ id: 'endDate', desc: false }],
                },
            ]}
            savedFilterType={SavedFilterType.IS37}
        />
    );
};
