import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import {
    CsamSensorReadDTO,
    IS37AlarmTypeReadDTO,
    IS37ChangeRequestContextReadDTO,
    IS37ChangeRequestCreateDTO,
    IS37ChangeRequestReadDTO,
    IS37ContextReadDTO,
    IS37ReadDTO,
    IS37UpdateDTO,
    ListOfValuesReadDTO,
    OrgUnitReadDTO,
    TaskCommentUpdateDTO,
    TaskPayloadCommentUpdateDTO,
    WorkOrderReadDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const IS37_PATH = 'is37';

export const getIs37: GetOne<{ id: string }, IS37ReadDTO> = async ({ id, config }) =>
    client.get(buildPath([IS37_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getIs37s: GetManyPaged<IS37ReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([IS37_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const createIs37: CreateOne<{ data: IS37UpdateDTO }, IS37ReadDTO> = async ({ data, config }) =>
    client.post(buildPath([IS37_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const updateIs37: UpdateOne<{ id: number; taskId: string; data: IS37UpdateDTO }, IS37ReadDTO> = async ({
    id,
    taskId,
    data,
    config,
}) =>
    client
        .put(buildPath([IS37_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const submitIs37: UpdateOne<{ id: number; data: IS37UpdateDTO }, IS37ReadDTO> = async ({ id, data, config }) =>
    client.put(buildPath([IS37_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const getIs37AlarmTypes: GetManyUnpaged<{}, IS37AlarmTypeReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([IS37_PATH, 'alarm-types']), config).then((response: AxiosResponse) => response.data);

export const getIs37AlarmDisablementTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([IS37_PATH, 'alarm-disablement-types']), config)
        .then((response: AxiosResponse) => response.data);

export const getIs37WorkTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([IS37_PATH, 'work-types']), config).then((response: AxiosResponse) => response.data);

export const getIs37Statuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([IS37_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getIs37Sensors: GetManyPaged<CsamSensorReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([IS37_PATH, 'sensors']), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getIs37AlarmTypeRespGroups: GetManyUnpaged<{}, OrgUnitReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([IS37_PATH, 'responsible-groups']), config).then((response: AxiosResponse) => response.data);

export const getWorkOrder: GetOne<{ id: number }, WorkOrderReadDTO> = async ({ id, config }) =>
    client.get(buildPath([IS37_PATH, 'work-order', id]), config).then((response: AxiosResponse) => response.data);
export const validateSubmitIs37: UpdateOne<{ id: number; data: IS37UpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([IS37_PATH, id, 'validate']), data, config);
export const createChangeRequest: CreateOne<
    { data: IS37ChangeRequestCreateDTO; is37id: string },
    IS37ChangeRequestReadDTO
> = async ({ data, is37id, config }) =>
    client
        .post(buildPath([IS37_PATH, is37id, 'change-request']), data, config)
        .then((response: AxiosResponse) => response.data);
export const setSensorsTask: UpdateOne<{ id: number; data: IS37UpdateDTO }, IS37ReadDTO> = async ({
    id,
    data,
    config,
}) =>
    client
        .put(buildPath([IS37_PATH, id, 'set-sensors']), data, config)
        .then((response: AxiosResponse) => response.data);
export const noSensorsTask: UpdateOne<{ id: number; data: IS37UpdateDTO }, IS37ReadDTO> = async ({
    id,
    data,
    config,
}) =>
    client.put(buildPath([IS37_PATH, id, 'no-sensors']), data, config).then((response: AxiosResponse) => response.data);
export const changeAlarmTypeTask: UpdateOne<
    { id: number; data: TaskPayloadCommentUpdateDTO<IS37UpdateDTO> },
    IS37ReadDTO
> = async ({ id, data, config }) =>
    client
        .put(buildPath([IS37_PATH, id, 'change-alarm-type']), data, config)
        .then((response: AxiosResponse) => response.data);
export const disableTask: UpdateOne<{ data: IS37UpdateDTO; is37id: string }, any> = async ({ data, is37id, config }) =>
    client
        .post(buildPath([IS37_PATH, is37id, 'disable']), data, config)
        .then((response: AxiosResponse) => response.data);
export const recommissionTask: UpdateOne<{ data: IS37UpdateDTO; is37id: string }, any> = async ({
    data,
    is37id,
    config,
}) =>
    client
        .post(buildPath([IS37_PATH, is37id, 'recommission']), data, config)
        .then((response: AxiosResponse) => response.data);
export const scheduleRecommissioning: UpdateOne<{ data: IS37UpdateDTO; is37id: string }, any> = async ({
    data,
    is37id,
    config,
}) =>
    client
        .post(buildPath([IS37_PATH, is37id, 'schedule']), data, config)
        .then((response: AxiosResponse) => response.data);

export const getIs37ValidationRules: GetOne<{ id: string | undefined }, PreciseRulesDTO<IS37ContextReadDTO>> = async ({
    id,
    config,
}) =>
    client
        .get(buildPath([IS37_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);
export const getChangeRequestValidationRules: GetOne<
    { id: string | undefined },
    PreciseRulesDTO<IS37ChangeRequestContextReadDTO>
> = async ({ id, config }) =>
    client
        .get(buildPath([IS37_PATH, 'change-request', 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);
export const cancelIs37: RequestMethod<{ id: number; comment: TaskCommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client.post(buildPath([IS37_PATH, id, 'cancel']), comment, config).then((response: AxiosResponse) => response.data);

export const cancelIs37ChangeRequest: RequestMethod<{ id: number; comment: TaskCommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client
        .post(buildPath([IS37_PATH, id, 'cancel-change-request']), comment, config)
        .then((response: AxiosResponse) => response.data);
export const getIs37Pdf: GetOne<{ id: number; langCode: string }, Blob> = async ({ id, langCode, config }) =>
    client
        .get(buildPath([IS37_PATH, id, 'pdf']), { ...config, params: { langCode }, responseType: 'blob' })
        .then((response: AxiosResponse) => response.data);
