import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ActivityGridReadDTO, ListOfValuesReadDTO } from './dto';
import { GetManyPaged, GetManyUnpaged } from './types';

export const ACTIVITY_PATH = 'activities_nim';

export const getActivities: GetManyPaged<ActivityGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response) => response.data);

export const getActivityStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getActivityTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'activity-types']), config).then((response: AxiosResponse) => response.data);

export const getInterventionPeriodTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'intervention-periods']), config)
        .then((response: AxiosResponse) => response.data);

export const getActivityApprovalStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([ACTIVITY_PATH, 'approval-statuses']), config)
        .then((response: AxiosResponse) => response.data);

export const getActivityApprovalTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'approval-types']), config).then((response: AxiosResponse) => response.data);

export const getActivityMeetingTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([ACTIVITY_PATH, 'meeting-types']), config).then((response: AxiosResponse) => response.data);
