import {
    AddCircle,
    AttachFile,
    Cancel,
    CancelPresentation,
    CheckCircle,
    DoDisturb,
    DriveFileRenameOutline,
    RemoveCircle,
    Send,
    Sensors,
    SensorsOff,
    StopCircle,
} from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { Leak } from 'mdi-material-ui';
import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { NavigateFunction } from 'react-router-dom';
import { cancelDimr, createNewDimrVersion, submitDimr, validateSubmitDimr } from '../../api/documents/dimrs';
import {
    cancelFirePermit,
    closeFirePermit,
    submitFirePermit,
    validateSubmitFirePermit,
} from '../../api/documents/firePermits';
import {
    cancelIs37,
    cancelIs37ChangeRequest,
    changeAlarmTypeTask,
    disableTask,
    noSensorsTask,
    recommissionTask,
    scheduleRecommissioning,
    setSensorsTask,
    submitIs37,
    validateSubmitIs37,
} from '../../api/documents/is37s';
import { cancelNdc, finishNdc, submitNdc, validateSubmitNdc } from '../../api/documents/noteDeCoupures';
import { DocumentType, TaskAction, TaskCommentUpdateDTO, TaskDTO, TaskType } from '../../api/dto';
import { approveSignature, rejectSignature } from '../../api/signatures';
import { isIs37LateSubmission, mapIs37ToUpdateDTO } from '../../lib/records/documents/is37';
import { mapNdcToUpdateDTO } from '../../lib/records/documents/noteDeCoupure';
import { getRoute, ROUTES } from '../../routes/routes';

/**
 * The importance of a task action. This is used to adapt the look of the task.
 */
export enum ActionKind {
    /**
     * This action must be done (or an equivalent one).
     */
    MUST_DO,
    /**
     * The action can be done (but usually isn't) and modifies the flow of the document with soft consequences.
     */
    CAN_DO_SOFT,
    /**
     * The action can be done (but usually isn't) and drastically modifies the flow of the document with major consequences.
     */
    CAN_DO_HARD,
}

/**
 * All actions are configured by default to be mandatory.
 */
export const DEFAULT_ACTION_KIND = ActionKind.MUST_DO;

const getCancelAction = (
    cancelCallback: (args: { id: number; comment: TaskCommentUpdateDTO }) => Promise<any>
): TaskActionConfigurationRecord => ({
    [TaskAction.CANCEL]: {
        mutation: ({ task, comment }) => cancelCallback({ id: task.documentId, comment: { comment } }),
        icon: <DoDisturb />,
        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.cancel' }),
        confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.cancel.confirm' }),
        comment: true,
        kind: ActionKind.CAN_DO_HARD,
    },
});

const ndcSubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="ndc.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const is37SubmitConfirmation: (data: any) => React.ReactNode = ({ startDate }) => (
    <>
        {isIs37LateSubmission({ startDate }) ? (
            <Alert severity="warning" sx={{ mb: 1 }}>
                <FormattedMessage id="is37.warning.lateSubmission" />
            </Alert>
        ) : (
            <></>
        )}
        <Box>
            <FormattedMessage id="is37.warning.creation" />
        </Box>
        <Box>
            <FormattedMessage id="is37.warning.consequence" />
        </Box>
        <Box>
            <FormattedMessage id="is37.warning.recommendation" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const firePermitSubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="firePermit.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const dimrSubmitConfirmation: (data: any) => React.ReactNode = () => (
    <>
        <Box>
            <FormattedMessage id="dimr.confirm.submit" />
        </Box>
        <Box>
            <FormattedMessage id="common.questionConfirm" />
        </Box>
    </>
);

const getSubmitAction = (
    submitDocument: (args: { id: number; data: any }) => Promise<any>,
    validateDocument: (args: { id: number; data: any }) => Promise<any>,
    getConfirmationContent: (args: any) => React.ReactNode
): TaskActionConfigurationRecord => ({
    [TaskAction.SUBMITTED]: {
        mutation: ({ id, data }) => submitDocument({ id, data: data as any }),
        validation: ({ id, data }) => validateDocument({ id, data: data as any }),
        icon: <Send />,
        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.submit' }),
        confirmationContent: ({ data }) => getConfirmationContent(data),
    },
});

export const TASK_CONFIGURATION: Record<TaskType, TaskConfiguration> = {
    [TaskType.DIMR_CHANGE_DATES]: {} as any, // TODO
    [TaskType.DIMR_FEEDBACK]: {} as any, // TODO
    [TaskType.DIMR_NEW_VERSION]: {
        icon: <AddCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.dimrNewVersion.instruction' }),
        actions: {
            [DocumentType.DIMR]: {
                [TaskAction.NEW_VERSION]: [
                    {
                        mutation: async ({ task, navigate }) => {
                            const result = await createNewDimrVersion({ dimrVersionId: task.documentId });
                            navigate(
                                getRoute({
                                    path: ROUTES.dimr.view,
                                    params: { id: result.dimrMasterId, version: result.versionNumber },
                                })
                            );
                            return result;
                        },
                        icon: <AddCircle />,
                        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.newVersion' }),
                        confirmationContent: ({ intl }) =>
                            intl.formatMessage({ id: 'document.task.dimrNewVersion.confirm' }),
                        kind: ActionKind.CAN_DO_SOFT,
                    },
                ],
            },
        },
    },
    [TaskType.ADD_IS37_SENSORS]: {
        icon: <Sensors />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.setSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: [
                    {
                        mutation: ({ task, data }) =>
                            setSensorsTask({
                                id: task.documentId,
                                data: mapIs37ToUpdateDTO(data, false),
                            }),
                        icon: <CheckCircle />,
                        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                        confirmationContent: ({ intl }) =>
                            intl.formatMessage({ id: 'document.task.setSensors.confirm' }),
                    },
                ],
                [TaskAction.NO_SENSORS]: [
                    {
                        mutation: ({ task, data }) =>
                            noSensorsTask({
                                id: task.documentId,
                                data: mapIs37ToUpdateDTO(data, false),
                            }),
                        icon: <RemoveCircle />,
                        secondary: true,
                        buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.noSensors' }),
                        confirmationContent: ({ intl }) =>
                            intl.formatMessage({ id: 'document.task.noSensors.confirm' }),
                        kind: ActionKind.CAN_DO_HARD,
                    },
                ],
            },
        },
    },
    [TaskType.CHANGE_ALARM_TYPE]: {
        icon: <Sensors />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.reassign.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data, comment }) =>
                        changeAlarmTypeTask({
                            id: task.documentId,
                            data: { data: mapIs37ToUpdateDTO(data, false), comment },
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.reassign.confirm' }),
                    comment: true,
                    kind: ActionKind.CAN_DO_SOFT,
                },
            },
        },
    },
    [TaskType.SCHEDULE_RECOMMISSIONING]: {
        instruction: (intl) => intl.formatMessage({ id: 'document.task.scheduleRecommissioning.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) =>
                        scheduleRecommissioning({
                            data: mapIs37ToUpdateDTO(data, false),
                            is37id: String(task.documentId),
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.scheduleRecommissioning.confirm' }),
                },
            },
        },
    },
    [TaskType.RECOMMISSION_SENSORS]: {
        icon: <Leak />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.recommissionSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) =>
                        recommissionTask({
                            data: mapIs37ToUpdateDTO(data, false),
                            is37id: String(task.documentId),
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.recommissionSensors.confirm' }),
                },
            },
        },
    },
    [TaskType.DISABLE_SENSORS]: {
        icon: <SensorsOff />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.disableSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) =>
                        disableTask({
                            data: mapIs37ToUpdateDTO(data, false),
                            is37id: String(task.documentId),
                        }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) =>
                        intl.formatMessage({ id: 'document.task.disableSensors.confirm' }),
                },
            },
        },
    },
    [TaskType.CANCEL]: {
        icon: <DoDisturb />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.cancel.instruction' }),
        actions: {
            [DocumentType.IS37]: getCancelAction(cancelIs37),
            [DocumentType.FP]: getCancelAction(cancelFirePermit),
            [DocumentType.NDC]: getCancelAction(cancelNdc),
            [DocumentType.DIMR]: getCancelAction(cancelDimr),
        },
    },
    [TaskType.CREATE_IS37_CHANGE_REQUEST]: {
        instruction: (intl) => intl.formatMessage({ id: 'document.task.createChangeRequest.instruction' }),
        actions: {
            [DocumentType.IS37]: {},
        },
    },
    [TaskType.FINISH]: {
        icon: <StopCircle />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.finish.instruction' }),
        all: true,
        actions: {
            [TaskAction.FINISH]: {
                mutation: ({ data, comment }) => finishNdc({ id: (data as any).id, comment: { comment } }),
                icon: <StopCircle />,
                buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.finish' }),
                confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.finish.confirm' }),
                comment: true,
                kind: ActionKind.CAN_DO_SOFT,
            },
        },
    },
    [TaskType.CANCEL_CHANGE_REQUEST]: {
        icon: <DoDisturb />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.cancelChangeRequest.instruction' }),
        actions: {
            [DocumentType.IS37]: {
                [TaskAction.CANCEL]: {
                    mutation: ({ data, comment }) =>
                        cancelIs37ChangeRequest({ id: (data as any).id, comment: { comment } }),
                    icon: <Cancel />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.cancel' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'is37.cancelChangeRequest.confirm' }),
                    comment: true,
                    kind: ActionKind.CAN_DO_SOFT,
                },
            },
        },
    },
    [TaskType.SIGNATURE]: {
        icon: <DriveFileRenameOutline />,
        instruction: (intl) => intl.formatMessage({ id: 'document.signature.instruction' }),
        all: true,
        actions: {
            [TaskAction.APPROVE]: {
                mutation: ({ task }) => approveSignature({ taskId: task.id }),
                icon: <CheckCircle />,
                buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.approve' }),
                confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.signature.confirm.approve' }),
                noSave: true,
            },
            [TaskAction.REJECT]: {
                mutation: ({ task, comment }) => rejectSignature({ taskId: task.id, comment: { comment } }),
                icon: <Cancel />,
                secondary: true,
                buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.reject' }),
                confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.signature.confirm.reject' }),
                comment: true,
                kind: ActionKind.CAN_DO_HARD,
                noSave: true,
            },
        },
    },
    [TaskType.SUBMIT]: {
        icon: <Send />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.submit.instruction' }),
        actions: {
            [DocumentType.NDC]: getSubmitAction(
                ({ id, data }) => submitNdc({ id, data: mapNdcToUpdateDTO(data) }),
                ({ id, data }) => validateSubmitNdc({ id, data: mapNdcToUpdateDTO(data) }),
                ndcSubmitConfirmation
            ),
            [DocumentType.IS37]: getSubmitAction(submitIs37, validateSubmitIs37, is37SubmitConfirmation),
            [DocumentType.FP]: getSubmitAction(
                submitFirePermit,
                validateSubmitFirePermit,
                firePermitSubmitConfirmation
            ),
            [DocumentType.DIMR]: getSubmitAction(submitDimr, validateSubmitDimr, dimrSubmitConfirmation),
        },
    },
    [TaskType.EDIT]: {
        instruction: (intl) => intl.formatMessage({ id: 'document.task.edit.instruction' }),
        actions: {},
    },
    [TaskType.EDIT_SENSORS]: {
        instruction: (intl) => intl.formatMessage({ id: 'document.task.editSensors.instruction' }),
        actions: {
            [DocumentType.IS37]: {},
        },
    },
    [TaskType.ATTACH_IS37]: {
        icon: <AttachFile />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.attachIs37.instruction' }),
        actions: {
            [DocumentType.FP]: {},
        },
    },
    [TaskType.FP_CLOSEOUT]: {
        icon: <CancelPresentation />,
        instruction: (intl) => intl.formatMessage({ id: 'document.task.fpCloseout.instruction' }),
        actions: {
            [DocumentType.FP]: {
                [TaskAction.COMPLETE]: {
                    mutation: ({ task, data }) => closeFirePermit({ id: task.documentId, data: data as any }),
                    icon: <CheckCircle />,
                    buttonContent: ({ intl }) => intl.formatMessage({ id: 'document.action.perform' }),
                    confirmationContent: ({ intl }) => intl.formatMessage({ id: 'document.task.fpCloseout.confirm' }),
                },
            },
        },
    },
};

interface TaskConfigurationAllDocuments {
    all: true;
    actions: TaskActionConfigurationRecord;
}

interface TaskConfigurationPerDocument {
    all?: false | undefined;
    actions: Partial<Record<DocumentType, TaskActionConfigurationRecord>>;
}

type TaskConfiguration = (TaskConfigurationAllDocuments | TaskConfigurationPerDocument) & {
    icon?: React.ReactNode;
    instruction: (intl: IntlShape) => string;
};

// We allow multiple buttons for the same completion code
// First such use case is IMPACT-1607, but this will probably turn into an antipattern
type TaskActionConfigurationRecord = Partial<Record<TaskAction, TaskActionConfiguration | TaskActionConfiguration[]>>;

type TaskActionCallback = (args: {
    id: number;
    task: TaskDTO;
    data: unknown;
    comment: string | null;
    navigate: NavigateFunction;
}) => Promise<unknown>;

export interface TaskActionConfiguration {
    mutation: TaskActionCallback;
    validation?: TaskActionCallback;
    secondary?: boolean;
    icon: React.ReactNode;
    buttonContent: (args: { intl: IntlShape }) => string;
    confirmationContent?: (args: { data: any; intl: IntlShape }) => React.ReactNode;
    comment?: boolean;
    /**
     * The importance of the action, see {@link ActionKind}. This is used to change the severity of the task card.
     */
    kind?: ActionKind;
    /**
     * If the form can be saved *and* {@link mutation} endpoint does not accept a payload, then this field should be set to <code>true</code>.
     */
    noSave?: boolean;
}
