import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { ListOfValuesReadDTO } from './dto';
import { GetManyUnpaged } from './types';

const RESOURCE_PATH = 'values';

export const getLanguages: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'languages']), config).then((response: AxiosResponse) => response.data);
export const getFacilities: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([RESOURCE_PATH, 'facilities']), config).then((response: AxiosResponse) => response.data);
