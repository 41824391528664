import { SpringFilterQueryBuilder as SFQB } from 'spring-filter-query-builder';
import { DocumentType, PersonRichReadDTO } from '../../api/dto';
import { escapeSfqbString } from '../sfqb';

export const makeDocumentTaskFilter = ({
    documentId,
    documentTypes,
    assignee,
}: {
    documentId: number;
    documentTypes: DocumentType[];
    assignee?: PersonRichReadDTO;
}): string => {
    return SFQB.and(
        ...[
            SFQB.equal('documentId', escapeSfqbString(String(documentId), false)),
            SFQB.or(
                ...documentTypes.map((documentType) =>
                    SFQB.equal('documentType', escapeSfqbString(String(documentType), false))
                )
            ),
            ...(assignee
                ? [SFQB.equal('assignees.person.searchLabel', escapeSfqbString(assignee.searchLabel, false))]
                : []),
        ]
    ).toString();
};
