import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DocumentType, VicReadDTO } from '../../api/dto';
import { useConfirmationDialog } from '../../components/ConfirmationDialogContext';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { EntityType } from '../../lib/information/types/EntityType';
import { mapIs37ToUpdateDTO } from '../../lib/records/documents/is37';
import { VicContentHeader } from '../../pages/documents/vic/VicContentHeader';
import { VicPageData } from '../../pages/documents/vic/VicPageData';
import { VicPageVariant } from '../../pages/documents/vic/VicPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FormGenerator } from '../FormGenerator';
import { vicFormSchema } from '../schemas/documents/vic';

interface VicFormProps {
    data: VicPageData;
    refreshData: () => void;
}

export const VicForm: React.FC<VicFormProps> = ({ data: pageData, refreshData }) => {
    const intl = useIntl();
    const { variant } = pageData;
    const navigate = useNavigate();
    const defaultValues = pageData.variant === VicPageVariant.READ ? pageData.vicData : {};
    const form = useForm<VicReadDTO>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultValues,
        },
    });
    const { handleSubmit, clearErrors, formState } = form;

    const confirmation = useConfirmationDialog();

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.VIC);
    const { mutate: saveVic, isLoading: isSaveLoading } = useMutation(
        ({ data: formData, submit = false }: { data: any; submit?: boolean }) => {
            if (variant === VicPageVariant.READ) {
                const updateOrSubmit = async (any: any) => null as any; // TODO
                return updateOrSubmit({
                    id: pageData.vicData.id,
                    data: mapIs37ToUpdateDTO(formData, false), // TODO
                });
            } else {
                if (submit) {
                    throw new Error(); // Must be created first
                }
                return (async (any: any) => null as any)({ data: mapIs37ToUpdateDTO(formData, true) }); // TODO
            }
        },
        {
            onSuccess: (result) => {
                if (variant === VicPageVariant.READ) {
                    refreshData();
                } else {
                    navigate(
                        getRoute({
                            path: ROUTES.vic.view,
                            params: { id: String(result.id) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );
    const canBeSaved = true;
    const { isDirty: formIsDirty } = formState;
    const isLoading = isSaveLoading;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: variant === VicPageVariant.READ && canBeSaved && (formIsDirty || isLoading),
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const uiSchemaVic = useMemo(() => vicFormSchema(intl), [intl]);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <VicContentHeader
                        data={pageData}
                        editable={canBeSaved}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === VicPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null} /* TODO signature here */
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {(pageData.variant !== VicPageVariant.READ || true) && ( // TODO savable
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.SAVE}
                                        onClick={() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveVic({ data }))();
                                        }}
                                        disabled={false}
                                        disablingReason="The button is disabled because the form does not contain any change"
                                    />
                                )}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* TODO */}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.Vic}
                            vic={
                                variant === VicPageVariant.READ
                                    ? pageData.vicData
                                    : { activities: [pageData.activityData] }
                            }
                        />
                        <ValidationErrorsPanel schema={uiSchemaVic} />
                        <FormGenerator rootElement={uiSchemaVic} form={form} disabled={isLoading} />
                    </>
                }
            />
        </FormProvider>
    );
};
