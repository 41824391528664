import { Box, CircularProgress, Typography } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { getActivityComments } from '../../api/activities';
import { ActivityCommentDTO } from '../../api/dto';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { DefaultAccordion } from '../DefaultAccordion';
import { ActivityComment } from './ActivityComment';
import { NewComment } from './NewComment';

interface CommentListProps {}
export const CommentList: React.FC<CommentListProps> = () => {
    const [comments, setComments] = React.useState<ActivityCommentDTO[]>([]);
    const [loading, setLoading] = React.useState(false);
    const params = useParams();

    const loadComments = async (aborter?: AbortController) => {
        if (params?.id) {
            setLoading(true);
            const results = await getActivityComments({
                id: params.id,
                ...(aborter && {
                    config: {
                        signal: aborter.signal,
                    },
                }),
            });

            setComments(results);
            setLoading(false);
        }
    };

    useAsyncEffect(loadComments);

    return (
        <Box sx={{ margin: 2 }}>
            <DefaultAccordion title="Comments">
                {loading ? (
                    <Typography sx={{ margin: 2 }}>
                        Loading comments... <CircularProgress size={12} sx={{ color: 'inherit' }} />
                    </Typography>
                ) : (
                    comments.map((comment, idx) => (
                        <Box key={idx} sx={{ marginBottom: 1 }}>
                            <ActivityComment comment={comment} reloadComments={loadComments} />
                        </Box>
                    ))
                )}
                <NewComment activity={params?.id || ''} reloadComments={loadComments} />
            </DefaultAccordion>
        </Box>
    );
};
