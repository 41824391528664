import { IntlShape } from 'react-intl';
import { DocumentType } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { getDocumentTypeName } from '../../lib/records/documents/document';
import { AutocompleteFormElement, TFilterElement } from '../types';

interface DocumentTypeOption {
    type: DocumentType;
    label: string;
}

interface DocumentTypeTypePropertiesParameters {
    multiple?: boolean;
}

const documentTypeTypeProperties = (
    { multiple }: DocumentTypeTypePropertiesParameters,
    intl: IntlShape
): AutocompleteFormElement['typeProperties'] => ({
    idField: 'type',
    multiple,
    options: Object.keys(DocumentType)
        .map((type) => type as keyof typeof DocumentType)
        .map((type) => ({
            type: DocumentType[type],
            label: getDocumentTypeName(DocumentType[type], intl),
        })) satisfies DocumentTypeOption[],
    getOptionLabel: (option: DocumentTypeOption) => option.label,
});

interface DocumentTypeSchemaParameters extends DocumentTypeTypePropertiesParameters, Pick<TFilterElement, 'id'> {}

export const documentTypeFilterSchema = ({ id }: DocumentTypeSchemaParameters, intl: IntlShape): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE,
    label: intl.formatMessage({ id: 'task.field.documentType' }),
    id,
    valueGetter: (value: DocumentTypeOption) => value.type,
    typeProperties: documentTypeTypeProperties({}, intl),
});
