import { Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createWdpVersionAndMaster, updateWdpVersion } from '../../api/documents/wdps';
import {
    DocumentType,
    WdpParticipantReadDTO,
    WdpParticipantUpdateDTO,
    WdpVersionReadDTO,
    WdpVersionUpdateDTO,
} from '../../api/dto';
import { useConfirmationDialog } from '../../components/ConfirmationDialogContext';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { EntityType } from '../../lib/information/types/EntityType';
import { mapWdpToUpdateDTO } from '../../lib/records/documents/wdp';
import { filterObjectFieldsFromList, updateListIdxs } from '../../pages/documents/wdp/tabs/WdpUtils';
import { WdpContentHeader } from '../../pages/documents/wdp/WdpContentHeader';
import { WdpPageData } from '../../pages/documents/wdp/WdpPageData';
import { WdpPageVariant } from '../../pages/documents/wdp/WdpPageVariant';
import { WdpTabsRegion } from '../../pages/documents/wdp/WdpTabsRegion';
import { getRoute, ROUTES } from '../../routes/routes';
import { FormGenerator } from '../FormGenerator';
import { wdpFormSchema } from '../schemas/documents/wdp';

interface WdpFormProps {
    data: WdpPageData;
    refreshData: () => void;
    wdp: WdpVersionReadDTO;
    setWdp: React.Dispatch<React.SetStateAction<WdpVersionReadDTO>>;
}

const mapWdpParticipantToUpdateDTO = (part: WdpParticipantReadDTO): WdpParticipantUpdateDTO =>
    filterObjectFieldsFromList({ ...part }, [
        'estimatedDose',
        'name',
        'person',
        'replacement',
        'unknown',
        'wdpWorkingTeamIdxs',
    ]) as WdpParticipantUpdateDTO;

const mapWDPtoUpdateDTO = (wdp: WdpVersionReadDTO): WdpVersionUpdateDTO => {
    const { wdpWorkingTeams, wdpParticipants } = wdp;

    const wdpFiltered = filterObjectFieldsFromList({ ...wdp }, [
        'title',
        'wdpEditors',
        'wdpFacilities',
        'wdpParticipants',
        'wdpPerformedTasks',
        'wdpRpAssessmentWdpVersions',
        'wdpWorkSteps',
        'wdpWorkingPositions',
        'wdpWorkingTeams',
    ]) as WdpVersionUpdateDTO;

    wdpFiltered.wdpParticipants = updateListIdxs(
        wdpWorkingTeams,
        'teamsIdx',
        wdpParticipants,
        'wdpWorkingTeamIdxs'
    ).map(mapWdpParticipantToUpdateDTO);

    return wdpFiltered;
};

export const WdpForm: React.FC<WdpFormProps> = ({ data: pageData, refreshData, wdp, setWdp }) => {
    const intl = useIntl();
    const { variant } = pageData;
    const navigate = useNavigate();
    const defaultValues = pageData.variant === WdpPageVariant.READ ? pageData.wdpData : {};
    const form = useForm<WdpVersionReadDTO>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultValues,
        },
    });
    const { handleSubmit, clearErrors, formState } = form;

    const confirmation = useConfirmationDialog();

    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.WDP);

    const { mutate: saveWdp, isLoading: isSaveLoading } = useMutation(
        ({ data: formData, submit = false }: { data: WdpVersionReadDTO; submit?: boolean }) => {
            // const updateOrSubmit: (data: any) => any = formData.id ? createWdpVersionAndMaster : updateWdpVersion;
            if (variant === WdpPageVariant.READ) {
                return updateWdpVersion({
                    id: pageData.wdpData.wdpMasterId,
                    versionNumber: pageData.wdpData.versionNumber,
                    data: mapWdpToUpdateDTO(formData, false),
                });
            }
            if (submit) {
                throw new Error(); // Must be created first
            }
            return createWdpVersionAndMaster({
                data: mapWdpToUpdateDTO(formData, true),
            });
        },
        {
            onSuccess: (result) => {
                if (variant === WdpPageVariant.READ) {
                    refreshData();
                } else {
                    navigate(
                        getRoute({
                            path: ROUTES.wdp.view,
                            params: { id: String(result.id) }, // TODO
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: handleFormSaveError,
        }
    );
    const canBeSaved = true;
    const { isDirty: formIsDirty } = formState;
    const isLoading = isSaveLoading;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: variant === WdpPageVariant.READ && canBeSaved && (formIsDirty || isLoading),
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const uiSchemaWdp = useMemo(() => wdpFormSchema(intl), [intl]);

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                maxWidth={false}
                Header={
                    <WdpContentHeader
                        data={pageData}
                        editable={canBeSaved}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === WdpPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null} /* TODO signature here */
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {(pageData.variant !== WdpPageVariant.READ || true) && ( // TODO savable
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.SAVE}
                                        onClick={() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveWdp({ data }))();
                                        }}
                                        disabled={false}
                                        disablingReason="The button is disabled because the form does not contain any change"
                                    />
                                )}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* TODO */}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        {variant === WdpPageVariant.READ && (
                            <LinkedDocumentsPanel type={EntityType.Wdp} wdp={pageData.wdpData} />
                        )}
                        <ValidationErrorsPanel schema={uiSchemaWdp} />
                        <FormGenerator rootElement={uiSchemaWdp} form={form} disabled={isLoading} />
                        <WdpTabsRegion wdp={wdp} setWdp={setWdp!} />
                    </>
                }
            />
        </FormProvider>
    );
};
