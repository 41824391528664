import { AxiosResponse } from 'axios';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import { ListOfValuesReadDTO, WdpVersionGridReadDTO, WdpVersionReadDTO, WdpVersionUpdateDTO } from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, UpdateOne } from '../types';

export const WDP_PATH = 'wdp';

export const getWdpVersion: GetOne<{ id: string; versionNumber: number }, WdpVersionReadDTO> = async ({
    id,
    versionNumber,
    config,
}) => client.get(buildPath([WDP_PATH, id, versionNumber]), config).then((response: AxiosResponse) => response.data);

export const getWdpVersionLatest: GetOne<{ id: string }, WdpVersionReadDTO> = async ({ id, config }) =>
    client.get(buildPath([WDP_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getWdpVersions: GetManyPaged<WdpVersionGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([WDP_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getWdpStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([WDP_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const updateWdpVersion: UpdateOne<
    { id: number; versionNumber: number; data: WdpVersionUpdateDTO },
    WdpVersionReadDTO
> = async ({ id, versionNumber, data, config }) =>
    client.put(buildPath([WDP_PATH, id, versionNumber]), data, config).then((response: AxiosResponse) => response.data);

export const createWdpVersionAndMaster: CreateOne<{ data: WdpVersionUpdateDTO }, WdpVersionReadDTO> = async ({
    data,
    config,
}) => client.post(buildPath([WDP_PATH]), data, config).then((response: AxiosResponse) => response.data);
