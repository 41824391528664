import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDocumentInformation } from '../../lib/information/document';
import { EntityData } from '../../lib/information/types/EntityData';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';
import { ImpactLink } from '../ImpactLink';

type DocumentDetailsProps = EntityData;

export const DocumentDetails: React.FC<DocumentDetailsProps> = (props) => {
    const intl = useIntl();
    const { environment } = useApplicationSettings();
    const document = useMemo(() => getDocumentInformation(props, environment, intl), [...Object.keys(props), intl]);
    const details = useMemo(() => document.details.filter((d) => !d.type), [document]);
    return (
        <>
            {details
                .filter((detail) => !detail.type)
                .map((detail, i) => (
                    <Typography key={i} variant="body2" color="textSecondary">
                        <FormattedMessage
                            id="common.labelValue"
                            values={{
                                label: intl.formatMessage({ id: detail.labelKey }),
                                value: (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="span"
                                        fontStyle={detail.italic ? 'italic' : undefined}
                                    >
                                        {detail.value !== null &&
                                            (typeof detail.value === 'string' ? (
                                                detail.value
                                            ) : !Array.isArray(detail.value) ? (
                                                <ImpactLink to={detail.value.url}>{detail.value.label}</ImpactLink>
                                            ) : (
                                                detail.value.map((item, j) => (
                                                    <span key={j}>
                                                        <ImpactLink to={item.url}>{item.label}</ImpactLink>
                                                        {j < (detail.value as unknown[]).length - 1 ? ', ' : undefined}
                                                    </span>
                                                ))
                                            ))}
                                    </Typography>
                                ),
                            }}
                        />
                    </Typography>
                ))}
        </>
    );
};
