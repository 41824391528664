import { AxiosResponse } from 'axios';
import { PreciseRulesDTO } from '../../forms/rule/deserializer';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import {
    DimrVersionContextReadDTO,
    DimrVersionGridReadDTO,
    DimrVersionReadDTO,
    DimrVersionUpdateDTO,
    ListOfValuesReadDTO,
    TaskCommentUpdateDTO,
} from '../dto';
import { CreateOne, GetManyPaged, GetManyUnpaged, GetOne, RequestMethod, UpdateOne } from '../types';

export const DIMR_PATH = 'dimr';

export const getDimrVersion: GetOne<{ id: string; versionNumber: number }, DimrVersionReadDTO> = async ({
    id,
    versionNumber,
    config,
}) => client.get(buildPath([DIMR_PATH, id, versionNumber]), config).then((response: AxiosResponse) => response.data);

export const getDimrVersionLatest: GetOne<{ id: string }, DimrVersionReadDTO> = async ({ id, config }) =>
    client.get(buildPath([DIMR_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getDimrVersions: GetManyPaged<DimrVersionGridReadDTO> = async ({
    filter,
    page,
    size,
    sort,
    config,
} = {}) =>
    client
        .get(buildPath([DIMR_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getDimrStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);
export const getGeneralJobCodes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'general-job-codes']), config).then((response: AxiosResponse) => response.data);
export const getEquipmentJobCodes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'equipment-job-codes']), config).then((response: AxiosResponse) => response.data);

export const getDimrAlaraLevels: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'alara-levels']), config).then((response: AxiosResponse) => response.data);

export const getDimrCommentTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'comment-types']), config).then((response: AxiosResponse) => response.data);

export const getDimrRequirementPeriods: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'requirement-periods']), config).then((response: AxiosResponse) => response.data);

export const getDimrAreaClassification: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([DIMR_PATH, 'area-classification']), config).then((response: AxiosResponse) => response.data);

export const createDimr: CreateOne<{ data: DimrVersionUpdateDTO }, DimrVersionReadDTO> = async ({ data, config }) =>
    client.post(buildPath([DIMR_PATH]), data, config).then((response: AxiosResponse) => response.data);

export const updateDimr: UpdateOne<
    { id: number; taskId: string; data: DimrVersionUpdateDTO },
    DimrVersionReadDTO
> = async ({ id, taskId, data, config }) =>
    client
        .put(buildPath([DIMR_PATH, id]), data, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);

export const getDimrContext: RequestMethod<
    { dimr: DimrVersionUpdateDTO; taskId: string },
    PreciseRulesDTO<DimrVersionContextReadDTO>
> = async ({ dimr, taskId, config }) =>
    client
        .post(buildPath([DIMR_PATH, 'context']), dimr, { ...config, params: { taskId } })
        .then((response: AxiosResponse) => response.data);
export const submitDimr: UpdateOne<{ id: number; data: DimrVersionUpdateDTO }, DimrVersionReadDTO> = async ({
    id,
    data,
    config,
}) => client.put(buildPath([DIMR_PATH, id, 'submit']), data, config).then((response: AxiosResponse) => response.data);

export const validateSubmitDimr: UpdateOne<{ id: number; data: DimrVersionUpdateDTO }> = async ({ id, data, config }) =>
    client.put(buildPath([DIMR_PATH, id, 'validate']), data, config);
export const cancelDimr: RequestMethod<{ id: number; comment: TaskCommentUpdateDTO }, any> = async ({
    id,
    comment,
    config,
}) =>
    client.post(buildPath([DIMR_PATH, id, 'cancel']), comment, config).then((response: AxiosResponse) => response.data);

export const getDimrValidationRules: GetOne<
    { id: string | undefined },
    PreciseRulesDTO<DimrVersionContextReadDTO>
> = async ({ id, config }) =>
    client
        .get(buildPath([DIMR_PATH, 'rules']), { ...config, params: { id } })
        .then((response: AxiosResponse) => response.data);

export const createNewDimrVersion: UpdateOne<{ dimrVersionId: number }, DimrVersionReadDTO> = async ({
    dimrVersionId,
    config,
}) => client.post(buildPath([DIMR_PATH, dimrVersionId]), config).then((response: AxiosResponse) => response.data);
