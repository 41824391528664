import { HomeRepairService } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { EnvironmentDTO, WorkOrderReadDTO } from '../../api/dto';
import { getRoute } from '../../routes/routes';
import { formatDate } from '../date';
import { EntityInformation } from './types/EntityInformation';

const EAM_WO_ROUTE = 'eamlight.cern.ch/workorder/:woId';
const EAM_PREFIX = {
    [EnvironmentDTO.LOCAL]: 'dev',
    [EnvironmentDTO.DEVELOPMENT]: 'dev',
    [EnvironmentDTO.TEST]: 'test',
    [EnvironmentDTO.PRODUCTION]: '',
};

export const WorkOrderIcon = HomeRepairService;

export const getWorkOrderInformation = (
    workOrder: WorkOrderReadDTO,
    environment: EnvironmentDTO,
    intl: IntlShape
): EntityInformation => {
    const formatOptionalDate = (date: string | null): string =>
        date !== null ? formatDate(date, intl) : intl.formatMessage({ id: 'common.notApplicableAbbreviation' });
    const formatOptionalDateRange = (dateStart: string | null, dateEnd: string | null): string =>
        [formatOptionalDate(dateStart), formatOptionalDate(dateEnd)].join(' - ');
    return {
        url: getRoute({
            path: `https://${EAM_PREFIX[environment]}${EAM_WO_ROUTE}`,
            params: {
                woId: workOrder.id,
            },
        }),
        urlExternal: true,
        name: intl.formatMessage({ id: 'workOrder.nameWithId' }, { id: workOrder.id }),
        title: workOrder.description,
        status: null,
        details: [
            { labelKey: 'document.field.status', value: workOrder.statusCode, italic: false },
            { labelKey: 'workOrder.field.equipmentCode', value: workOrder.equipmentCode, italic: false },
            { labelKey: 'workOrder.field.equipmentDescription', value: workOrder.equipmentDescription, italic: false },
            {
                labelKey: 'workOrder.field.requestedDates',
                value: formatOptionalDateRange(workOrder.requestedStart, workOrder.requestedEnd),
                italic: true,
            },
            {
                labelKey: 'workOrder.field.scheduledDates',
                value: formatOptionalDateRange(workOrder.scheduledStart, workOrder.scheduledEnd),
                italic: true,
            },
            { labelKey: 'workOrder.field.mrcCode', value: workOrder.mrcCode, italic: false },
            { labelKey: 'workOrder.field.mrcDescription', value: workOrder.mrcDescription, italic: false },
        ],
    };
};
