import { Functions, Settings } from '@mui/icons-material';
import * as React from 'react';
import { IntlShape } from 'react-intl';
import { getFacilities } from '../../../api/values';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { numberWithUnitSchemaFor } from '../unit';
import { valueSchema } from '../value';

export const wdpFormSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        element: {
                            type: UiSchemaType.INPUT,
                            label: intl.formatMessage({ id: 'document.field.title' }),
                            path: 'title',
                        },
                    },
                    {
                        xs: 12,
                        element: valueSchema(
                            {
                                path: 'wdpFacilities',
                                label: intl.formatMessage({ id: 'document.field.facilities' }),
                                fetchOptions: getFacilities,
                                multiple: true,
                            },
                            intl.locale
                        ),
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'Calculated totals for WDP',
            typeProperties: {
                icon: <Functions color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    // TODO decide where these should be computed (ideally, backend)
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: 'Average estimated dose rate',
                                path: '',
                                unit: 'µSv/h',
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: 'Max. individual working time',
                                path: '',
                                unit: 'h',
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: 'Total collective working time',
                                path: '',
                                unit: 'person.h',
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: 'Max. Estimated individual dose',
                                path: '',
                                unit: 'µSv',
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: 'Estimated Collective Dose',
                                path: '',
                                unit: 'person.µSv',
                            },
                            intl
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: numberWithUnitSchemaFor(
                            {
                                label: 'Max. estimated dose rate',
                                path: '',
                                unit: 'µSv/h',
                            },
                            intl
                        ),
                    },
                ],
            },
        },
    ],
});
