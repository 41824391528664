import { Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createDimr, getDimrContext, updateDimr } from '../../api/documents/dimrs';
import { DimrVersionActionDTO, DimrVersionContextReadDTO, DimrVersionReadDTO, DocumentType } from '../../api/dto';
import { useApplicationSettings } from '../../components/application/ApplicationSettingsProvider';
import { useConfirmationDialog } from '../../components/ConfirmationDialogContext';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { LinkedDocumentsPanel } from '../../components/documents/LinkedDocumentsPanel';
import { useTaskContext } from '../../components/TaskContext';
import { TaskActions } from '../../components/tasks/TaskActions';
import { TaskHistory } from '../../components/tasks/TaskHistory';
import { useTasks } from '../../components/tasks/useTasks';
import { ValidationErrorsPanel } from '../../components/ValidationErrorsPanel';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { useUnsavedChangesPrompt } from '../../hooks/useUnsavedChangesPrompt';
import { useDocumentSaveErrorCallback } from '../../hooks/validation';
import { artificialDelay } from '../../lib/delay';
import { EntityType } from '../../lib/information/types/EntityType';
import { makeDimrDefaultValuesFromLinkedActivity, mapDimrToUpdateDTO } from '../../lib/records/documents/dimr';
import { DIMRContentHeader } from '../../pages/documents/dimr/DIMRContentHeader';
import { DIMRPageData } from '../../pages/documents/dimr/DIMRPageData';
import { DIMRPageVariant } from '../../pages/documents/dimr/DIMRPageVariant';
import { getRoute, ROUTES } from '../../routes/routes';
import { FieldsAttributesContextProvider } from '../FieldsAttributesContext';
import { FormGenerator } from '../FormGenerator';
import { PreciseRulesDTO } from '../rule/deserializer';
import { useIsSavableFromRules } from '../rule/useIsSavableFromRules';
import { useRules } from '../rule/useRules';
import { dimrFormSchema } from '../schemas/documents/dimr';

interface DimrFormProps {
    data: DIMRPageData;
    rules: PreciseRulesDTO<DimrVersionContextReadDTO>;
    refreshData: () => void;
}

export const DimrForm: React.FC<DimrFormProps> = ({ data: pageData, rules, refreshData }) => {
    const intl = useIntl();
    const { variant } = pageData;
    const { currentUser, preferredLanguage } = useApplicationSettings();
    const navigate = useNavigate();
    const { withDispatchTaskEvent } = useTaskContext();

    const defaultValues = useMemo(
        () =>
            pageData.variant === DIMRPageVariant.READ
                ? pageData.dimrData
                : makeDimrDefaultValuesFromLinkedActivity(pageData.activityData, currentUser),
        [pageData, currentUser]
    );
    const form = useForm<DimrVersionReadDTO>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultValues,
        },
    });

    const { handleSubmit, clearErrors, formState, watch, setError } = form;
    const handleFormSaveError = useDocumentSaveErrorCallback(form, DocumentType.DIMR);

    const confirmation = useConfirmationDialog();
    const tasks = useTasks(
        pageData.variant === DIMRPageVariant.READ
            ? {
                  documentTypes: [DocumentType.DIMR],
                  documentId: pageData.dimrData.id,
                  onTaskSubmit: (taskHandler) => {
                      clearErrors();
                      return handleSubmit((data) => taskHandler(data))();
                  },
                  onTaskError: (response: AxiosResponse) =>
                      handleFormSaveError(response, { validate: true, submit: false }),
                  refreshData,
              }
            : undefined
    );

    const { mutateAsync: saveDimr, isLoading: isSaveLoading } = useMutation(
        ({ data: formData }: { data: any }) => {
            if (variant === DIMRPageVariant.READ) {
                if (tasks.currentTask === null) {
                    throw new Error();
                }
                return updateDimr({
                    id: pageData.dimrData.id,
                    taskId: tasks.currentTask.id,
                    data: mapDimrToUpdateDTO(formData, false),
                });
            } else {
                return createDimr({ data: mapDimrToUpdateDTO(formData, true) }).then(artificialDelay);
            }
        },
        {
            onSuccess: (result) => {
                if (variant === DIMRPageVariant.READ) {
                    refreshData();
                } else {
                    navigate(
                        getRoute({
                            path: ROUTES.dimr.view,
                            params: { id: String(result.dimrMasterId), version: String(result.versionNumber) },
                        }),
                        {
                            replace: true,
                        }
                    );
                }
            },
            onError: (response: AxiosResponse) => handleFormSaveError(response),
        }
    );

    const { data: contextData, refetch: refetchContext } = useQuery(
        ['dimr', 'context', pageData.variant === DIMRPageVariant.READ ? pageData.dimrData.id : 0],
        () => getDimrContext({ dimr: form.getValues(), taskId: tasks.currentTask?.id ?? '' }),
        {
            enabled: tasks.currentTask !== null,
        }
    );

    const context: DimrVersionContextReadDTO | null = useMemo(
        () => ({
            ...rules.context!,
            ...contextData?.context,
            action: variant === DIMRPageVariant.CREATE ? DimrVersionActionDTO.CREATE : null,
            task: tasks.currentTask?.type?.type ?? null,
        }),
        [rules.context, tasks.currentTask?.type?.type, contextData?.context]
    );

    const attributes = useRules(form, rules, defaultValues, context as any);
    const isSavable = useIsSavableFromRules(attributes);
    const isCompletable = tasks.isLoading || !!tasks.assignableTasks.length;
    const { isDirty: formIsDirty } = formState;
    const isDirty = formIsDirty || variant !== DIMRPageVariant.READ;
    const isLoading = isSaveLoading || tasks.disabled;
    const withUnsavedChangesPrompt = useUnsavedChangesPrompt({
        when: variant === DIMRPageVariant.READ && (formIsDirty || isSaveLoading),
    });
    const refreshDataWithUnsavedChangesPrompt = () => withUnsavedChangesPrompt(refreshData);

    const uiSchemaDimr = useMemo(() => dimrFormSchema(intl), [intl]);
    const watchedRpAssessments = watch('rpAssessments');

    useEffect(
        () => {
            refetchContext();
        },
        watchedRpAssessments
            .flat()
            .flatMap((o) =>
                Object.values([
                    o.rpPresence,
                    o.locations,
                    o.avgEstDoseRate,
                    o.totalCollectiveWorkTime,
                    o.maxIndividualWorkTime,
                    o.maxEstDoseRate,
                    o.contaminatingWorks,
                    o.valuesForcedManual,
                ])
            )
    );

    return (
        <FormProvider {...form}>
            <DocumentPageStructure
                isLoading={isLoading}
                Header={
                    <DIMRContentHeader
                        data={pageData}
                        editable={isSavable || isCompletable}
                        isLoading={isLoading}
                        onRefreshClick={
                            variant === DIMRPageVariant.READ ? refreshDataWithUnsavedChangesPrompt : undefined
                        }
                        Main={null} /* TODO signature here */
                        Left={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {tasks.currentTask !== null && (
                                    <TaskActions
                                        task={tasks.currentTask}
                                        onValidate={tasks.validate}
                                        onSubmit={tasks.perform}
                                        disabled={isLoading}
                                        getValues={form.getValues}
                                        isDirty={formIsDirty}
                                    />
                                )}
                                {isSavable && (
                                    <DocumentButtonsStack.Action
                                        {...COMMON_DOCUMENT_BUTTONS.SAVE}
                                        onClick={withDispatchTaskEvent(() => {
                                            clearErrors();
                                            return handleSubmit((data) => saveDimr({ data }))();
                                        })}
                                        disabled={!isDirty}
                                        disablingReason={intl.formatMessage({
                                            id: 'document.form.disabledNoChange',
                                        })}
                                        important={!tasks.currentTask}
                                    />
                                )}
                            </Stack>
                        }
                        Right={
                            <Stack direction="row" spacing={1} alignItems="center">
                                {/* TODO */}
                            </Stack>
                        }
                    />
                }
                Body={
                    <>
                        <LinkedDocumentsPanel
                            type={EntityType.Dimr}
                            dimr={
                                variant === DIMRPageVariant.READ
                                    ? pageData.dimrData
                                    : {
                                          rpAssessments: [{ activity: pageData.activityData }],
                                          workDosePlanningVersions: [],
                                      }
                            }
                            collapsed
                        />
                        <ValidationErrorsPanel schema={uiSchemaDimr} />
                        <FieldsAttributesContextProvider value={attributes}>
                            <FormGenerator rootElement={uiSchemaDimr} form={form} disabled={isLoading} />
                            {variant === DIMRPageVariant.READ && (
                                <WorkflowHistory documentType={DocumentType.DIMR} documentId={pageData.dimrData.id} />
                            )}
                        </FieldsAttributesContextProvider>
                    </>
                }
                Drawer={
                    variant === DIMRPageVariant.READ ? (
                        <TaskHistory
                            tasks={tasks}
                            disabled={isLoading}
                            withUnsavedChangesPrompt={withUnsavedChangesPrompt}
                            onTaskChange={() => form.reset()}
                            onTaskUpdate={refreshData}
                        />
                    ) : null
                }
            />
        </FormProvider>
    );
};
