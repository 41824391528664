import { createContext, useContext } from 'react';
import { FieldAttributes } from './rule/deserializer';

const FieldsAttributesContext = createContext<Record<string, FieldAttributes> | undefined>(undefined);

export const useFieldsAttributesContext = (fields: (string | undefined)[][]): FieldAttributes[][] => {
    const record = useContext(FieldsAttributesContext);
    const defaultValues: FieldAttributes = {
        newValue: undefined,
        editable: true,
        visible: true,
        mandatory: false,
    };
    return fields.map((array) =>
        array.map((field) => {
            if (field !== undefined && record !== undefined) {
                const value = record[field];
                return value ?? defaultValues;
            } else {
                return defaultValues;
            }
        })
    );
};

export const FieldsAttributesContextProvider = FieldsAttributesContext.Provider;
