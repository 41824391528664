import { Engineering } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { ActivityReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { translateValue } from '../language';
import { getActivityStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const ActivityIcon = Engineering;

export const getActivityInformation = (activity: ActivityReadDTO, intl: IntlShape): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.activities.view, params: { id: activity.id } }),
        name: intl.formatMessage({ id: 'activity.titleExisting' }, { id: activity.id }),
        title: activity.title,
        status: getActivityStatus(activity, intl),
        details: [
            {
                labelKey: 'activity.field.facility',
                value: activity.facility ? translateValue(activity.facility, intl.locale).label : null,
                type: true,
            },
            {
                labelKey: 'document.field.responsible',
                value: activity.responsible?.searchLabel ?? '',
                italic: true,
            },
            // TODO
        ],
    };
};
