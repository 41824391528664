import { IntlShape } from 'react-intl';
import { WdpVersionGridReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchWdpVersionsById } from '../../lib/api';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, AutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const wdpTypeProperties = (intl: IntlShape): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchWdpVersionsById,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: ({ wdpMasterId, versionNumber }: WdpVersionGridReadDTO) =>
        intl.formatMessage({ id: 'wdp.titleExisting' }, { masterId: wdpMasterId, versionNumber }),
    autocompleteProps: {
        disableCloseOnSelect: false,
        forcePopupIcon: false,
    },
});

interface WdpSchemaParameters extends Pick<AutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const wdpSchema = ({ path, label, required }: WdpSchemaParameters, intl: IntlShape): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: wdpTypeProperties(intl),
});

interface WdpFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const wdpFilterSchema = ({ id, label }: WdpFilterSchemaParameters, intl: IntlShape): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: WdpVersionGridReadDTO) => value.id,
    typeProperties: wdpTypeProperties(intl),
});
