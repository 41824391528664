import { Paper, Table, TableContainer } from '@mui/material';
import * as React from 'react';
import { AccessGridBody } from './AccessGridBody';
import { AccessGridHead } from './AccessGridHead';
import { useAccessGridTableInstance } from './AccessGridSettings';

interface AccessGridProps {
    activities: any[];
}
export const AccessGrid: React.FC<AccessGridProps> = ({ activities }) => {
    const { headers, rows, prepareRow } = useAccessGridTableInstance(activities);

    return (
        <Paper>
            <TableContainer sx={{ width: '100%' }}>
                <Table size="small">
                    <AccessGridHead headers={headers} />
                    <AccessGridBody rows={rows} prepareRow={prepareRow} />
                </Table>
            </TableContainer>
        </Paper>
    );
};
