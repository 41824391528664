import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { TaskIdentifier } from './task';
import { ActionOne } from './types';

const SIGNATURES_PATH = 'signatures';

export const approveSignature: ActionOne<TaskIdentifier, void> = async ({ taskId, config }) =>
    client
        .post(buildPath([SIGNATURES_PATH, taskId, 'approve']), undefined, config)
        .then((response: AxiosResponse) => response.data);

export const rejectSignature: ActionOne<TaskIdentifier, void> = async ({ taskId, comment, config }) =>
    client
        .post(buildPath([SIGNATURES_PATH, taskId, 'reject']), comment, config)
        .then((response: AxiosResponse) => response.data);
