import { Error as ErrorIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Link as MuiLink,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { AccordionProps } from '@mui/material/Accordion/Accordion';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useMemo } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { getDocumentInformation } from '../../lib/information/document';
import { EntityData } from '../../lib/information/types/EntityData';
import { getDocumentIcon } from '../../lib/records/documents/document';
import { DocumentStatus } from '../../lib/status';
import { useApplicationSettings } from '../application/ApplicationSettingsProvider';
import { ImpactLink } from '../ImpactLink';
import { StatusChip } from '../StatusChip';
import { DocumentDetails } from './DocumentDetails';

export type LinkedDocumentDataAsync =
    | (EntityData & { async?: undefined })
    | {
          async: true;
          query: () => Promise<EntityData>;
          queryKey: (string | number)[];
          errorLabelKey: MessageDescriptor['id'];
      };

interface LinkedDocumentsContentProps {
    documents: LinkedDocumentDataAsync[];
}

interface LinkedDocumentAccordionSummaryProps {
    icon: React.ReactNode;
    to: string | null;
    toExternal?: boolean;
    name: string;
    title: string | null;
    status: DocumentStatus | null;
}

const LinkedDocumentAccordionSummary: React.FC<LinkedDocumentAccordionSummaryProps> = ({
    icon,
    to,
    toExternal,
    name,
    title,
    status,
}) => {
    return (
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack direction="column" spacing={1} width="100%" sx={{ mr: 1 }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems={{ sm: 'center' }}
                    width="100%"
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {icon}
                        {to !== null ? (
                            !toExternal ? (
                                <ImpactLink to={to}>{name}</ImpactLink>
                            ) : (
                                <MuiLink href={to} target="_blank">
                                    {name}
                                </MuiLink>
                            )
                        ) : (
                            <Box>{name}</Box>
                        )}
                    </Stack>
                    {!!status && (
                        <Box>
                            <StatusChip label={status.label} color={status.color} size="small" />
                        </Box>
                    )}
                </Stack>
                {!!title && (
                    <Typography variant="body2" color="textSecondary">
                        {title}
                    </Typography>
                )}
            </Stack>
        </AccordionSummary>
    );
};

const accordionProps: Omit<AccordionProps, 'children'> = {
    variant: 'outlined',
    sx: {
        '&:before': {
            display: 'none',
        },
    },
};

interface LinkedDocumentAccordionProps {
    document: EntityData;
}

const LinkedDocumentAccordion: React.FC<LinkedDocumentAccordionProps> = ({ document }) => {
    const intl = useIntl();
    const { environment } = useApplicationSettings();
    const information = useMemo(() => getDocumentInformation(document, environment, intl), [document]);
    const Icon = getDocumentIcon(document.type);
    return (
        <Accordion {...accordionProps}>
            <LinkedDocumentAccordionSummary
                icon={<Icon color="action" />}
                to={information.url}
                toExternal={information.urlExternal}
                name={information.name}
                title={information.title}
                status={information.status}
            />

            <AccordionDetails>
                <DocumentDetails {...document} />
            </AccordionDetails>
        </Accordion>
    );
};

interface LinkedDocumentAccordionAsyncProps {
    query: () => Promise<EntityData>;
    queryKey: (string | number)[];
    errorLabelKey: MessageDescriptor['id'];
}

const LinkedDocumentAccordionAsync: React.FC<LinkedDocumentAccordionAsyncProps> = ({
    query,
    queryKey,
    errorLabelKey,
}) => {
    const { data, isLoading, isError } = useQuery(queryKey, () => query());
    return data && !isLoading ? (
        <LinkedDocumentAccordion document={data} />
    ) : (
        <Accordion {...accordionProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {isError ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <ErrorIcon color="error" />
                        <Box>
                            <FormattedMessage id={errorLabelKey} />
                        </Box>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={1} sx={{ width: '75%' }}>
                        <Skeleton variant="text" sx={{ flexGrow: 4 }} />
                        <Skeleton variant="text" sx={{ flexGrow: 1 }} />
                    </Stack>
                )}
            </AccordionSummary>
        </Accordion>
    );
};

export const LinkedDocumentsContent: React.FC<LinkedDocumentsContentProps> = ({ documents }) => {
    return (
        <>
            {documents.map((doc, i) => {
                // Weird API, but convenient to use
                if ('async' in doc && doc.async) {
                    return (
                        <LinkedDocumentAccordionAsync
                            key={i}
                            query={doc.query}
                            queryKey={doc.queryKey}
                            errorLabelKey={doc.errorLabelKey}
                        />
                    );
                } else {
                    return <LinkedDocumentAccordion key={i} document={doc} />;
                }
            })}
        </>
    );
};
