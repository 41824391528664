import { useCallback } from 'react';
import { TaskDTO } from '../../api/dto';
import { ActionKind, DEFAULT_ACTION_KIND, TASK_CONFIGURATION } from './TaskTypeConfiguration';

export const useTaskIsImportant = () =>
    useCallback((task: TaskDTO) => {
        const taskConfiguration = TASK_CONFIGURATION[task.type.type];
        const actionsConfigurations = Object.values(
            taskConfiguration.all ? taskConfiguration.actions : taskConfiguration.actions[task.documentType] ?? {}
        ).flatMap((c) => (Array.isArray(c) ? c : [c]));
        const kinds = actionsConfigurations.map((a) => a.kind ?? DEFAULT_ACTION_KIND);
        // A task is considered important if it cannot be completed, or if there exists an important outcome
        return kinds.length === 0 || kinds.some((kind) => kind === ActionKind.MUST_DO);
    }, []);
