import { useQueries } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebounce } from '../../hooks/useDebounce';
import { AutocompleteElement, AutocompleteElementProps } from './AutocompleteElement';

export interface AsyncAutocompleteElementProps<T, M extends boolean | undefined, D extends boolean | undefined>
    extends Omit<AutocompleteElementProps<T, M, D>, 'options'> {
    queryKey?: string[];
    fetchOptions: (
        searchTerm: string,
        exactId: boolean | undefined,
        idField: string | undefined,
        values?: any[]
    ) => Promise<any[]>;
    fetchUseCases: [FetchUseCase, ...FetchUseCase[]];
    watchPaths?: string[];
    useValueDirectly?: boolean;
}

export enum FetchUseCase {
    ON_LOAD,
    ON_OPEN,
    ON_KEYSTROKE,
}

export const AsyncAutocompleteElement = <T, M extends boolean | undefined, D extends boolean | undefined>({
    field,
    fieldState,
    queryKey = [],
    fetchUseCases,
    fetchOptions,
    autocompleteProps,
    matchId,
    endAdornment,
    idField,
    textFieldProps,
    watchPaths = [],
    useValueDirectly = false,
    ...rest
}: AsyncAutocompleteElementProps<T, M, D>): React.ReactElement | null => {
    const intl = useIntl();
    const formContext = useFormContext();
    const queryParams = formContext !== null ? formContext.watch(watchPaths) : watchPaths?.map(() => null);

    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [lastInputReason, setLastInputReason] = useState('initial-loading');
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const baseQueryKey = ['autocomplete', ...queryKey];
    let computedQueryKey = baseQueryKey;
    if (fetchUseCases.includes(FetchUseCase.ON_OPEN)) {
        computedQueryKey = [...computedQueryKey, `open:${open}`];
    }
    if (fetchUseCases.includes(FetchUseCase.ON_KEYSTROKE)) {
        computedQueryKey = [...computedQueryKey, debouncedSearchTerm].filter(Boolean);
    }

    const searchExactId = matchId && lastInputReason === 'initial-loading';

    const results = useQueries({
        queries: [
            ...[]
                .concat(field.value)
                .filter(Boolean)
                .map((value) => ({
                    queryKey: [...baseQueryKey, 'exact', value],
                    queryFn: () => fetchOptions(value, true, idField, queryParams),
                    enabled: !!(searchExactId && !fetchUseCases.includes(FetchUseCase.ON_LOAD)),
                    keepPreviousData: true,
                })),
            {
                queryKey: computedQueryKey,
                queryFn: () => fetchOptions(debouncedSearchTerm, searchExactId, idField, queryParams),
                enabled:
                    fetchUseCases.includes(FetchUseCase.ON_LOAD) ||
                    (fetchUseCases.includes(FetchUseCase.ON_KEYSTROKE) &&
                        lastInputReason === 'input' &&
                        !!debouncedSearchTerm) ||
                    (fetchUseCases.includes(FetchUseCase.ON_OPEN) && open),
                keepPreviousData: true,
            },
        ],
    });

    const options = results
        .map((e) => e.data)
        .flat()
        .filter(Boolean);
    const isFetching = results.some((e) => e.isFetching);
    const isError = results.some((e) => e.isError);
    const hasFetched = results.some((e) => !!e.data);

    // Handler for updating value with through external component
    // this is injected into the endAdornment prop
    const updateValue = (value: unknown) => {
        field.onChange(value);
        setSearchTerm('');
        setLastInputReason('initial-loading'); // needed in order to trigger the query for the new value when using matchId
    };

    // Workaround for bug where `setValue` would not update the value rendered by this component
    const actualValue = useValueDirectly ? field.value : formContext ? formContext.watch(field.name) : undefined;
    const actualField = useMemo(
        () => (formContext ? { ...field, value: actualValue } : field),
        [formContext, actualValue, field]
    );

    return (
        <AutocompleteElement
            endAdornment={() =>
                !!endAdornment
                    ? endAdornment({
                          field: actualField,
                          updateValue,
                          multiple: rest.multiple,
                          matchId,
                          disabled: rest.disabled,
                          readOnly: rest.readOnly,
                      })
                    : null
            }
            idField={idField}
            field={actualField}
            fieldState={fieldState}
            options={options || []}
            loading={isFetching}
            matchId={matchId}
            required={textFieldProps?.InputProps?.required}
            {...rest}
            autocompleteProps={{
                open,
                onOpen: () => {
                    setOpen(true);
                },
                onClose: () => {
                    setOpen(false);
                },
                autoComplete: true,
                ...(fetchUseCases.includes(FetchUseCase.ON_KEYSTROKE) ? { filterOptions: (x) => x } : {}),
                onInputChange: (event, newInputValue, reason) => {
                    const isResetByOnChangeOrKeystroke =
                        event !== null && (reason === 'reset' || reason === 'input' || reason === 'clear');
                    const isInitialLoadingWithValue = reason === 'reset' && newInputValue.length;
                    if (isResetByOnChangeOrKeystroke || isInitialLoadingWithValue) {
                        setLastInputReason(reason);
                        setSearchTerm(newInputValue);
                    }
                },
                inputValue: searchTerm,
                noOptionsText:
                    !isFetching && !hasFetched ? intl.formatMessage({ id: 'document.autocomplete.hint' }) : undefined,
                ...autocompleteProps,
            }}
            textFieldProps={{
                ...textFieldProps,
                ...(isError
                    ? {
                          error: true,
                          helperText: intl.formatMessage({ id: 'document.autocomplete.error' }),
                      }
                    : undefined),
            }}
        />
    );
};
