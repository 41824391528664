import { Box, Breakpoint, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ButtonSet } from './ButtonSet';

interface ResponsiveButtonSetProps {
    buttons: Array<React.ReactNode>;
    breakpoint?: Breakpoint;
}
export const ResponsiveButtonSet: React.FC<ResponsiveButtonSetProps> = ({ buttons, breakpoint = 'md' }) => {
    const theme = useTheme();
    const shouldCollapse: boolean = useMediaQuery(theme.breakpoints.down(breakpoint));

    return shouldCollapse ? (
        <ButtonSet buttons={buttons} />
    ) : (
        <Stack direction="row" spacing={1}>
            {buttons.map((button, idx) => (
                //this wrapping avoids the error message of list items needing a key prop
                <Box key={idx}>{button}</Box>
            ))}
        </Stack>
    );
};
