import { AccessTime, AccountTree, Article, AttachFile, CallSplit, MenuBook, PictureAsPdf } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { getActivity } from '../../api/activities';
import { ActivityDTO } from '../../api/dto';
import { ButtonSet } from '../../components/ButtonSet';
import { CommentList } from '../../components/comments/CommentList';
import { COMMON_DOCUMENT_BUTTONS, DocumentButtonsStack } from '../../components/documents/DocumentButtonsStack';
import { DocumentContentProps, DocumentLoadingWrapper } from '../../components/documents/DocumentLoadingWrapper';
import { DocumentPageStructure } from '../../components/documents/DocumentPageStructure';
import { FavouriteButton } from '../../components/FavouriteActivityButton';
import { WorkflowHistory } from '../../components/WorkflowHistory';
import { ActivityForm } from '../../forms/documents/ActivityForm';

const ActivityPageContent: React.FC<DocumentContentProps<ActivityDTO>> = ({ documentData: activityData }) => {
    const informationButtons = [
        <Button startIcon={<MenuBook />} size="small">
            Approvals
        </Button>,
        <Button startIcon={<Article />} size="small">
            Change Requests
        </Button>,
        <Button startIcon={<AccountTree />} size="small">
            See Workflow
        </Button>,
        <Button startIcon={<AttachFile />} size="small">
            Attachments
        </Button>,
        <Button startIcon={<AccessTime />} size="small">
            View History
        </Button>,
        <Button startIcon={<PictureAsPdf />} size="small">
            Download PDF
        </Button>,
    ];

    return (
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            <DocumentPageStructure
                Header={
                    <>
                        <Stack direction="row" alignItems="center">
                            <FavouriteButton activityId={activityData.id} />
                            <Typography>
                                {activityData.id} - {activityData.status.label}
                            </Typography>
                        </Stack>
                        <DocumentButtonsStack>
                            <DocumentButtonsStack.Action {...COMMON_DOCUMENT_BUTTONS.SAVE} />
                            <DocumentButtonsStack.Action {...COMMON_DOCUMENT_BUTTONS.REFRESH} />
                            <DocumentButtonsStack.Action {...COMMON_DOCUMENT_BUTTONS.CLONE} />
                            <DocumentButtonsStack.Action icon={<CallSplit />} titleKey={'Split' as any} />
                            <Box sx={{ flexGrow: 1 }} />
                            <ButtonSet buttons={informationButtons} />
                        </DocumentButtonsStack>
                    </>
                }
                Body={
                    <>
                        <ActivityForm activityData={activityData} />
                        <WorkflowHistory documentType={'activity' as any /* TODO */} documentId={activityData.id} />
                        <CommentList />
                    </>
                }
            />
        </Box>
    );
};

export const ActivityPage: React.FC = () => {
    return (
        <DocumentLoadingWrapper documentType="activity" getOneDocument={getActivity}>
            {(args) => <ActivityPageContent {...args} />}
        </DocumentLoadingWrapper>
    );
};
