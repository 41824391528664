import { matchPath, Params } from 'react-router-dom';
import { ApplicationSettingsDTO, EnvironmentDTO, FeatureFlag } from '../api/dto';
import { getRoute, ROUTES, RouteType } from './routes';

type RichRedirect = string | ((params: Params) => Parameters<typeof getRoute>[0]);

const DEFAULT_OIM_ROUTE = '/impact/secure';

const oimUrl =
    (place: string | ((params: Params) => string)): RichRedirect =>
    (params) => ({
        path: '/impact/secure/',
        queryParams: { place: typeof place === 'string' ? place : place(params) },
    });

export const ROUTES_TO_OIM: Record<RouteType, RichRedirect> = {
    // Warning: order matters, place the static routes first (e.g. `/new` before `/:id`)
    [ROUTES.homepage]: oimUrl('searchActivity'),
    [ROUTES.news]: DEFAULT_OIM_ROUTE,
    [ROUTES.activities.search]: oimUrl('searchActivity'),
    [ROUTES.activities.favourites]: oimUrl('searchFavouriteActivity'),
    [ROUTES.activities.clusters]: oimUrl('cluster/searchActivityCluster'),
    [ROUTES.activities.create]: oimUrl('createActivity'),
    [ROUTES.activities.view]: oimUrl(({ id }) => `editActivity:${id}`),
    [ROUTES.dimr.search]: oimUrl('dimr/searchDimr'),
    [ROUTES.dimr.viewLatest]: oimUrl(({ id }) => `dimr/editDimr:${id}:1`), // Imperfect
    [ROUTES.dimr.view]: oimUrl(({ id }) => `dimr/editDimr:${id}:1`), // Imperfect
    [ROUTES.dimr.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.wdp.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.wdp.search]: oimUrl('searchWorkDosePlanning'),
    [ROUTES.wdp.view]: oimUrl(({ id }) => `dimr/editWorkDosePlanning:${id}:1`), //
    [ROUTES.wdp.viewLatest]: oimUrl(({ id }) => `dimr/editWorkDosePlanning:${id}:1`), //
    [ROUTES.vic.search]: oimUrl('safety/searchVic'),
    [ROUTES.vic.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.vic.view]: oimUrl(({ id }) => `safety/editSafetyDocument:VIC:${id}`),
    [ROUTES.lockout.search]: oimUrl('safety/searchLockout'),
    [ROUTES.lockout.view]: oimUrl(({ id }) => `safety/editSafetyDocument:LKO:${id}`),
    [ROUTES.lockout.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.lockout.clone]: DEFAULT_OIM_ROUTE,
    [ROUTES.firePermit.search]: oimUrl('safety/searchFirePermit'),
    [ROUTES.firePermit.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.firePermit.view]: oimUrl(({ id }) => `safety/editSafetyDocument:FPR:${id}`),
    [ROUTES.firePermit.clone]: DEFAULT_OIM_ROUTE,
    [ROUTES.is37.search]: oimUrl('safety/searchIS37'),
    [ROUTES.is37.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.is37.view]: oimUrl(({ id }) => `safety/editSafetyDocument:IS37N:${id}`),
    [ROUTES.is37.clone]: DEFAULT_OIM_ROUTE,
    [ROUTES.noteDeCoupure.search]: DEFAULT_OIM_ROUTE,
    [ROUTES.noteDeCoupure.create]: DEFAULT_OIM_ROUTE,
    [ROUTES.noteDeCoupure.clone]: DEFAULT_OIM_ROUTE,
    [ROUTES.noteDeCoupure.view]: DEFAULT_OIM_ROUTE,
    [ROUTES.tasks]: oimUrl('user/showDashboard'),
    [ROUTES.radiationDoses]: oimUrl('radiationDoseReports'),
    [ROUTES.accessControl]: oimUrl('user/searchAccessControl'),
    [ROUTES.locations]: '/impact/secure/locationList',
    [ROUTES.notFound]: DEFAULT_OIM_ROUTE,
};

// This controls what routes are enabled on NIM (if a route is disabled on NIM then the OIM route will be used instead)
// - false: the route always redirects to OIM
// - true: the route uses NIM and doesn't redirect to OIM
// - FeatureFlag: the route uses NIM only if the specified flag is enabled, otherwise it redirects to OIM
export const ENABLED_NIM_ROUTES: Record<RouteType, FeatureFlag | boolean> = {
    [ROUTES.homepage]: false,
    [ROUTES.news]: false,
    [ROUTES.activities.search]: FeatureFlag.V9,
    [ROUTES.activities.favourites]: FeatureFlag.V9,
    [ROUTES.activities.clusters]: FeatureFlag.V9,
    [ROUTES.activities.create]: FeatureFlag.V9,
    [ROUTES.activities.view]: FeatureFlag.V9,
    [ROUTES.dimr.search]: FeatureFlag.V4_V5,
    [ROUTES.dimr.viewLatest]: FeatureFlag.V4_V5,
    [ROUTES.dimr.view]: FeatureFlag.V4_V5,
    [ROUTES.dimr.create]: FeatureFlag.V4_V5,
    [ROUTES.wdp.search]: FeatureFlag.V4_V5,
    [ROUTES.wdp.create]: FeatureFlag.V4_V5,
    [ROUTES.wdp.view]: FeatureFlag.V4_V5,
    [ROUTES.wdp.viewLatest]: FeatureFlag.V4_V5,
    [ROUTES.vic.search]: FeatureFlag.V6,
    [ROUTES.vic.create]: FeatureFlag.V6,
    [ROUTES.vic.view]: FeatureFlag.V6,
    [ROUTES.lockout.search]: FeatureFlag.V7,
    [ROUTES.lockout.view]: FeatureFlag.V7,
    [ROUTES.lockout.create]: FeatureFlag.V7,
    [ROUTES.lockout.clone]: FeatureFlag.V7,
    [ROUTES.firePermit.search]: true,
    [ROUTES.firePermit.view]: true,
    [ROUTES.firePermit.create]: true,
    [ROUTES.firePermit.clone]: true,
    [ROUTES.is37.search]: true,
    [ROUTES.is37.create]: true,
    [ROUTES.is37.view]: true,
    [ROUTES.is37.clone]: true,
    [ROUTES.noteDeCoupure.search]: true,
    [ROUTES.noteDeCoupure.create]: true,
    [ROUTES.noteDeCoupure.clone]: true,
    [ROUTES.noteDeCoupure.view]: true,
    [ROUTES.tasks]: true,
    [ROUTES.radiationDoses]: FeatureFlag.V9,
    [ROUTES.accessControl]: false,
    [ROUTES.locations]: false,
    [ROUTES.notFound]: true,
};

const isRouteEnabled = (
    value: (typeof ENABLED_NIM_ROUTES)[keyof typeof ENABLED_NIM_ROUTES],
    features: Partial<Record<FeatureFlag, boolean>>
): boolean => (typeof value === 'boolean' ? value : !!features[value]);

const getOIMUrlFromRedirect = (
    to: RichRedirect,
    params: Params,
    origin: string,
    environment: EnvironmentDTO
): string => {
    const toResolved = getRoute(
        typeof to === 'string' ? { path: to, params: params as { [key: string]: string } } : to(params)
    );
    const newOrigin =
        environment === 'PRODUCTION' || environment === 'TEST' || environment === 'DEVELOPMENT'
            ? origin.replace(/-ng/, '')
            : 'https://testimpact.cern.ch';
    return `${newOrigin}${toResolved}`;
};

export const getOIMUrl = (
    place: string | ((params: Params) => string),
    params: Params,
    origin: string,
    environment: EnvironmentDTO
): string => getOIMUrlFromRedirect(oimUrl(place), params, origin, environment);

export const findOIMRedirectionFor = (
    pathname: string,
    origin: string,
    settings: Pick<ApplicationSettingsDTO, 'environment' | 'features'>
): string | undefined => {
    const redirections = Object.entries(ROUTES_TO_OIM).reduce<string[]>((acc, [from, to]) => {
        const matched = matchPath(from, pathname);
        const routeEnabled = isRouteEnabled(ENABLED_NIM_ROUTES[from as keyof typeof ROUTES_TO_OIM], settings.features);
        if (!routeEnabled && matched !== null) {
            return [...acc, getOIMUrlFromRedirect(to, matched.params, origin, settings.environment)];
        } else {
            return acc;
        }
    }, []);
    return redirections[0];
};

export const REDIRECT_NOT_IMPLEMENTED_TO_OIM: boolean = (() => {
    const rawValue = import.meta.env.VITE_REDIRECT_NOT_IMPLEMENTED_TO_OIM;
    if (rawValue === undefined) {
        return false;
    }
    const value = Object.fromEntries([false, true].map((b) => [String(b), b]))[rawValue];
    if (value === undefined) {
        throw new Error(
            `Incorrect value for environment variable VITE_REDIRECT_NOT_IMPLEMENTED_TO_OIM: ${String(rawValue)}`
        );
    }
    return value;
})();
