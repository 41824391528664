import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { createActivityComment } from '../../api/activities';

interface NewCommentProps {
    activity: string;
    reloadComments: () => void | Promise<void>;
}
export const NewComment: React.FC<NewCommentProps> = ({ activity, reloadComments }) => {
    const defaultText = 'Write new comment';
    const [editMode, setEditMode] = React.useState(false);
    const [text, setText] = React.useState('');

    const startEdit = () => {
        setEditMode(true);
    };
    const endEdit = () => {
        setEditMode(false);
    };
    const handleTextEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };
    const submitComment = async () => {
        endEdit();
        await createActivityComment({ id: activity, content: text });
        reloadComments();
    };

    return (
        <Card>
            <CardHeader title="You" avatar={<Avatar>FP</Avatar>} />
            <Divider />
            <CardContent>
                {editMode ? (
                    <>
                        <TextField
                            autoFocus
                            fullWidth
                            multiline
                            maxRows={4}
                            variant="standard"
                            placeholder={defaultText}
                            onChange={handleTextEdit}
                            onKeyDown={(e) => {
                                if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                    submitComment();
                                }
                            }}
                        />
                        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button onClick={endEdit}>Cancel</Button>
                            <Button onClick={submitComment}>Comment</Button>
                        </Box>
                    </>
                ) : (
                    <Typography color="text.secondary" onClick={startEdit}>
                        {defaultText}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};
