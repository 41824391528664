import { getIs37AlarmTypes } from '../../api/documents/is37s';
import { IS37AlarmTypeReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { Locale } from '../../i18n/Locale';
import { getLovLabel } from '../../lib/label';
import { translateValue } from '../../lib/language';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface IS37AlarmTypeTypePropertiesParameters {
    multiple?: boolean;
}

const is37AlarmTypeTypeProperties = (
    { multiple }: IS37AlarmTypeTypePropertiesParameters,
    locale: Locale
): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: () => getIs37AlarmTypes(),
    fetchUseCases: [FetchUseCase.ON_LOAD],
    getOptionLabel: (value: IS37AlarmTypeReadDTO) => getLovLabel(translateValue(value.type, locale)),
    multiple,
    autocompleteProps: {
        isOptionEqualToValue: (o: IS37AlarmTypeReadDTO | undefined, v: IS37AlarmTypeReadDTO | undefined) =>
            o && v ? o.type.code === v.type.code : o === v,
    },
});

interface IS37AlarmTypeSchemaParameters
    extends IS37AlarmTypeTypePropertiesParameters,
        Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const is37AlarmTypeSchema = (
    { path, label, multiple, required }: IS37AlarmTypeSchemaParameters,
    locale: Locale
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: is37AlarmTypeTypeProperties({ multiple }, locale),
});

interface IS37AlarmTypeFilterSchemaParameters
    extends IS37AlarmTypeTypePropertiesParameters,
        Pick<TFilterElement, 'id' | 'label'> {}

export const is37AlarmTypeFilterSchema = (
    { id, label }: IS37AlarmTypeFilterSchemaParameters,
    locale: Locale
): TFilterElement => {
    const typeProperties = is37AlarmTypeTypeProperties({}, locale);
    return {
        type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
        id,
        label,
        valueGetter: (value: IS37AlarmTypeReadDTO) => value.details.csamType,
        typeProperties: {
            ...typeProperties,
            autocompleteProps: {
                ...typeProperties.autocompleteProps,
                openOnFocus: true,
                disableCloseOnSelect: false,
            },
        },
    };
};
