import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GetOne } from '../../api/types';

export interface DocumentContentProps<T = any> {
    documentData: T;
}

interface DocumentLoadingWrapperProps<T = any> {
    documentType: string;
    getOneDocument: GetOne<{ id: string }, T>;
    children: (args: DocumentContentProps) => React.ReactNode;
}

/**
 * @deprecated
 */
export const DocumentLoadingWrapper = <T,>({
    documentType,
    getOneDocument,
    children,
}: DocumentLoadingWrapperProps<T>): React.ReactElement => {
    const { id: rawDocumentId } = useParams();
    // This should be caught later
    const documentId = rawDocumentId ?? '';
    const { isLoading, data } = useQuery([documentType, documentId], () => getOneDocument({ id: documentId }));

    if (isLoading) return <LinearProgress />;
    if (!data) return <Alert severity="error">There was an error fetching document data</Alert>;
    // Shouldn't happen:
    else return <>{children({ documentData: data })}</>;
};
