import { SpringFilterQueryBuilder as SFQB } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';

export const makeActivityDefaultFilter = (searchValue: string): string => {
    return SFQB.or(
        SFQB.like('whatTitle', `${escapeSfqbString(searchValue, true)}*`),
        SFQB.like('id', `${escapeSfqbString(searchValue, true)}*`)
    ).toString();
};

export const makeActivityIdFilter = (searchValue: string): string =>
    SFQB.like('id', `${escapeSfqbString(searchValue, true)}*`).toString();

export const makeAccessPointDefaultFilter = (searchValue: string): string =>
    SFQB.like('name', `${escapeSfqbString(searchValue, true)}*`).toString();
