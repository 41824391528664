import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { AttachmentDTO } from './dto';
import { GetOne, RequestMethod } from './types';

const ATTACHMENTS_PATH = 'attachments';

export const getAttachment: GetOne<{ id: number }, any> = async ({ id, config }) =>
    client
        .get(buildPath([ATTACHMENTS_PATH, id]), {
            ...config,
            responseType: 'blob',
        })
        .then((response) => response.data);

export const createAttachment: RequestMethod<
    {
        file: File;
        description: string;
    },
    AttachmentDTO
> = ({ file, description, config }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('body', new Blob([JSON.stringify({ description })], { type: 'application/json' }));
    return client.post(buildPath([ATTACHMENTS_PATH]), formData, config).then((response) => response.data);
};
