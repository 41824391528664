import { Business } from '@mui/icons-material';
import { Box, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { PersonReadDTO, SupplierReadDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchSupplierByName } from '../../lib/api';
import { getSupplierLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const supplierTypeProperties = ({
    multiple,
}: { multiple?: boolean } = {}): AsyncAutocompleteFormElement['typeProperties'] => ({
    fetchOptions: fetchSupplierByName,
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: getSupplierLabel,
    multiple,
    autocompleteProps: {
        forcePopupIcon: false,
    },
    endAdornment: ({ field, disabled }) => {
        const { value: supplier } = field as { value: SupplierReadDTO | undefined };
        return (
            <>
                {!!supplier && (
                    <Tooltip
                        title={
                            <Stack direction="column">
                                <Box>{supplier.phone}</Box>
                                <Box>{supplier.email}</Box>
                                {supplier.technicalEmail !== supplier.email && <Box>{supplier.technicalEmail}</Box>}
                            </Stack>
                        }
                    >
                        <Business color={!disabled ? 'action' : 'disabled'} sx={{ cursor: 'help' }} />
                    </Tooltip>
                )}
            </>
        );
    },
});

interface PersonSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    readOnly?: boolean;
    disabled?: boolean;
    multiple?: boolean;
}

export const supplierSchema = ({
    path,
    label,
    required,
    readOnly,
    disabled,
    multiple,
}: PersonSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    readOnly,
    disabled,
    typeProperties: supplierTypeProperties({ multiple }),
});

interface PersonFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const supplierFilterSchema = ({ id, label }: PersonFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: PersonReadDTO) => value.searchLabel,
    typeProperties: {
        ...supplierTypeProperties(),
        autocompleteProps: {
            disableCloseOnSelect: false,
            forcePopupIcon: false,
        },
    },
});
