import { IS37StatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const Is37StatusColorMapping: Record<IS37StatusCode, StatusColor> = {
    [IS37StatusCode.DRAFT]: StatusColor.COLOR_PRIMARY,
    [IS37StatusCode.WAITING_FOR_APPROVAL]: StatusColor.COLOR_WARNING,
    [IS37StatusCode.APPROVED]: StatusColor.COLOR_SUCCESS,
    [IS37StatusCode.WAITING_FOR_DISABLING]: StatusColor.COLOR_WARNING,
    [IS37StatusCode.DISABLED]: StatusColor.COLOR_SUCCESS,
    [IS37StatusCode.WAITING_FOR_SCHEDULING]: StatusColor.COLOR_SUCCESS,
    [IS37StatusCode.SCHEDULED]: StatusColor.COLOR_SUCCESS,
    [IS37StatusCode.WAITING_FOR_RECOMMISSIONING]: StatusColor.COLOR_WARNING,
    [IS37StatusCode.RECOMMISSIONED]: StatusColor.COLOR_SUCCESS,
    [IS37StatusCode.CLOSED]: StatusColor.COLOR_NORMAL,
    [IS37StatusCode.CANCELLED]: StatusColor.COLOR_ERROR,
    [IS37StatusCode.REJECTED]: StatusColor.COLOR_ERROR,
};
