import {
    Cell,
    CellStyle,
    CellTemplate,
    Compatible,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid';
import React from 'react';
import { PersonReadDTO } from '../../api/dto';
import { AsyncAutocompleteElement } from '../../forms/fields/AsyncAutocompleteElement';
import { personTypeProperties } from '../../forms/schemas/person';

const cellStyle: CellStyle = {};

export interface PersonCell extends Cell {
    type: 'person';
    person?: PersonReadDTO;
    setData: (data: PersonReadDTO[]) => void;
}

export class PersonAutocompleteTemplate implements CellTemplate<PersonCell> {
    getCompatibleCell(uncertainCell: Uncertain<PersonCell>): Compatible<PersonCell> {
        const text: string = uncertainCell.person?.searchLabel ?? '';
        const value: number = uncertainCell.person?.id ?? NaN;
        return { ...uncertainCell, text, value, setData: uncertainCell.setData! };
    }

    isFocusable?(cell: Compatible<PersonCell>): boolean {
        return true;
    }

    update?(cell: Compatible<PersonCell>, cellToMerge: UncertainCompatible<PersonCell>): Compatible<PersonCell> {
        return { ...cell, ...cellToMerge };
    }

    handleKeyDown?(
        cell: Compatible<PersonCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string | undefined
    ): { cell: Compatible<PersonCell>; enableEditMode: boolean } {
        if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
            return { cell: this.getCompatibleCell(cell), enableEditMode: false };
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd?(
        cell: Compatible<PersonCell>,
        eventData: string
    ): { cell: Compatible<PersonCell>; enableEditMode: boolean } {
        throw new Error('Method not implemented4.');
    }

    getStyle?(cell: Compatible<PersonCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    getClassName?(cell: Compatible<PersonCell>, isInEditMode: boolean): string {
        return 'person';
    }

    // eslint-disable-next-line class-methods-use-this
    render(
        cell: Compatible<PersonCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<PersonCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <div style={{ width: '100%' }}>
                <AsyncAutocompleteElement
                    field={{
                        name: '',
                        onBlur: () => 1,
                        onChange: cell.setData,
                        value: cell.person,
                        ref: () => 1,
                    }}
                    disabled={cell.nonEditable}
                    useValueDirectly={true}
                    strongValidateOptions={true}
                    fieldState={{
                        invalid: false,
                        isTouched: false,
                        isDirty: false,
                    }}
                    {...personTypeProperties({ multiple: false })}
                    idField={'id'}
                    labelField={'name'}
                    matchId={false}
                    autocompleteProps={{
                        fullWidth: true,
                        size: 'small',
                        disableCloseOnSelect: false,
                        forcePopupIcon: false,
                    }}
                />
            </div>
        );
    }
}
