import { SpringFilterQueryBuilder as SFQB } from 'spring-filter-query-builder';
import { escapeSfqbString } from '../sfqb';
import { removeAccents } from '../text';

const createNameQuery = (name: string) => {
    const searchLabelFieldName = 'searchLabel';
    const escapedInput = escapeSfqbString(name, true);
    return SFQB.or(
        SFQB.like(searchLabelFieldName, `${escapedInput}*`), // Label starts with
        SFQB.like(searchLabelFieldName, `* ${escapedInput}*`) // Or label contains a substring that starts with a space
    );
};

export const makePersonNameFilter = (name: string) => {
    const unaccentedName = removeAccents(name);
    const parts = removeAccents(unaccentedName).split(/[^A-Za-z]+/);
    if (parts.length <= 1) {
        return createNameQuery(unaccentedName);
    }
    const conditions = parts.map((part) => createNameQuery(part));
    return SFQB.and(...conditions);
};

export const makePersonIdFilter = (id: string) => {
    return SFQB.equal('id', escapeSfqbString(id));
};
