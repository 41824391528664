import { AxiosResponse } from 'axios';
import { getAccessPoints } from '../api/access-points';
import { getActivities } from '../api/activities';
import { getDimrVersions } from '../api/documents/dimrs';
import { getIs37Sensors } from '../api/documents/is37s';
import { getWdpVersions } from '../api/documents/wdps';
import {
    AccessPointDTO,
    ActivityReadDTO,
    ApiErrorDTO,
    CsamSensorReadDTO,
    DimrVersionGridReadDTO,
    EgroupDTO,
    EquipmentReadDTO,
    LocationDTO,
    OrgUnitReferenceDTO,
    PersonReadDTO,
    SupplierReadDTO,
    WdpVersionGridReadDTO,
} from '../api/dto';
import { getEgroups } from '../api/egroups';
import { getActiveEquipments, getEquipments } from '../api/equipment';
import { getLocationsByName } from '../api/locations';
import { getOrgUnits } from '../api/orgUnits';
import { getFrsOfficers, getPersons, getPersonsForFiltering } from '../api/persons';
import { getSuppliers } from '../api/suppliers';
import { Pageable } from '../api/types';
import { makeAccessPointDefaultFilter, makeActivityIdFilter } from './filters/activity';
import { makeDimrMasterIdFilter } from './filters/dimr';
import { makeEgroupDefaultFilter, makeSensorsDefaultFilter } from './filters/egroup';
import { makeOrgUnitFilter, makeOrgUnitIdFilter } from './filters/orgUnit';
import { makePersonIdFilter, makePersonNameFilter } from './filters/person';
import { createSupplierNameQuery } from './filters/supplier';
import { makeWdpMasterIdFilter } from './filters/wdp';

export const fetchPeopleByName = async (searchTerm: string, exactId: boolean | undefined): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersons({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchPeopleForFiltering = async (
    searchTerm: string,
    exactId: boolean | undefined
): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getPersonsForFiltering({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchFrsOfficersByName = async (
    searchTerm: string,
    exactId: boolean | undefined
): Promise<PersonReadDTO[]> => {
    const result: Pageable<PersonReadDTO> = await getFrsOfficers({
        filter: exactId ? makePersonIdFilter(searchTerm) : makePersonNameFilter(searchTerm),
    });
    return result.content;
};

export const fetchOrgUnits = async (
    searchTerm: string,
    exactId: boolean | undefined,
    idField: string | undefined
): Promise<OrgUnitReferenceDTO[]> => {
    const result: Pageable<OrgUnitReferenceDTO> = await getOrgUnits({
        filter: exactId ? makeOrgUnitIdFilter(searchTerm, idField) : makeOrgUnitFilter(searchTerm),
        sort: ['orgLevel,asc', 'code,asc'],
    });
    return result.content;
};

export const fetchEquipment = async (searchTerm: string): Promise<EquipmentReadDTO[]> => {
    const equipment: EquipmentReadDTO[] = await getEquipments({
        searchTerm,
    });
    return equipment;
};

export const fetchActiveEquipment = async (searchTerm: string): Promise<EquipmentReadDTO[]> => {
    const equipment: EquipmentReadDTO[] = await getActiveEquipments({
        searchTerm,
    });
    return equipment;
};

export const fetchLocationsByName = async ({
    searchTerm,
    selectable,
}: {
    searchTerm: string;
    selectable: boolean;
}): Promise<LocationDTO[]> => {
    return await getLocationsByName({
        query: searchTerm,
        onlySelectable: selectable,
    });
};
export const fetchSensorsByName = async (searchTerm: string): Promise<CsamSensorReadDTO[]> => {
    const result: Pageable<CsamSensorReadDTO> = await getIs37Sensors({
        filter: makeSensorsDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchAccessPointsByName = async (searchTerm: string): Promise<AccessPointDTO[]> => {
    const result: Pageable<AccessPointDTO> = await getAccessPoints({
        filter: makeAccessPointDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchEgroups = async (searchTerm: string): Promise<EgroupDTO[]> => {
    const result: Pageable<EgroupDTO> = await getEgroups({
        filter: makeEgroupDefaultFilter(searchTerm),
    });
    return result.content;
};

export const fetchActivitiesById = async (searchTerm: string): Promise<ActivityReadDTO[]> => {
    const result: Pageable<ActivityReadDTO> = await getActivities({
        filter: makeActivityIdFilter(searchTerm),
    });
    return result.content;
};

export const fetchDimrVersionsById = async (searchTerm: string): Promise<DimrVersionGridReadDTO[]> => {
    const result: Pageable<DimrVersionGridReadDTO> = await getDimrVersions({
        filter: makeDimrMasterIdFilter(searchTerm),
    });
    return result.content;
};

export const fetchWdpVersionsById = async (searchTerm: string): Promise<WdpVersionGridReadDTO[]> => {
    const result: Pageable<WdpVersionGridReadDTO> = await getWdpVersions({
        filter: makeWdpMasterIdFilter(searchTerm),
    });
    return result.content;
};

export const isErrorResponseWithItems = (response: AxiosResponse): response is AxiosResponse<ApiErrorDTO<any>> =>
    !!response?.data?.errorItems;

export const fetchSupplierByName = async (searchTerm: string): Promise<SupplierReadDTO[]> => {
    const result: Pageable<SupplierReadDTO> = await getSuppliers({
        filter: createSupplierNameQuery(searchTerm),
    });
    return result.content;
};
