import queryString from 'query-string';
import { IntlShape } from 'react-intl';
import { EnvironmentDTO } from '../api/dto';
import { ENVIRONMENT_SLUGS } from '../components/application/ApplicationSettingsProvider';
import { PageContextState } from '../components/application/PageContextProvider';
import { getDocumentName, getDocumentTypeName, getDocumentUrl } from '../lib/records/documents/document';

const getDocsRoute = (route: string, environment: EnvironmentDTO): string =>
    `https://impact-ng.docs.cern.ch/${
        ENVIRONMENT_SLUGS[environment !== EnvironmentDTO.LOCAL ? environment : EnvironmentDTO.DEVELOPMENT]
    }${route}`;

const getServiceNowTicketRoute =
    (type: 'request' | 'incident') =>
    (context: PageContextState, intl: IntlShape): string => {
        let subject: string | undefined;
        let description: string | undefined;
        if ('documentType' in context) {
            if ('documentId' in context && context.documentId !== undefined) {
                const ctx = {
                    ...context,
                    documentVersion:
                        'documentVersion' in context && context.documentVersion !== undefined
                            ? context.documentVersion
                            : null,
                };
                subject = getDocumentName(ctx, intl);
                description = `${window.location.origin}${getDocumentUrl(ctx)}`;
            } else {
                subject = getDocumentTypeName(context.documentType, intl);
            }
        }
        const params = {
            id: 'sc_cat_item',
            name: type,
            fe: 'impact-new',
            short_description: subject,
            comments: description,
        };
        return `https://cern.service-now.com/service-portal?${queryString.stringify(params)}`;
    };

export const EXTERNAL_ROUTES = {
    support: {
        help: (environment: EnvironmentDTO) => getDocsRoute('/documentation/', environment),
        question: getServiceNowTicketRoute('request'),
        incidentReport: getServiceNowTicketRoute('incident'),
    },
    privacyNotice:
        'https://cern.service-now.com/service-portal?id=privacy_policy&se=intervention-scheduling-app-support&notice=impact',
    changelog: (environment: EnvironmentDTO) => getDocsRoute('/documentation/IMPACT%20Upgrade/Versions/', environment),
} as const;
