import { CheckCircle, Close, DoNotDisturbAlt } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    Typography,
    useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import { getRoute } from '../../../routes/routes';

const GrantedIcon: React.FC = () => {
    const theme = useTheme();
    return <CheckCircle sx={{ marginLeft: 'auto', color: theme.palette.success.main }} />;
};

const RefusedIcon: React.FC = () => {
    const theme = useTheme();
    return <DoNotDisturbAlt sx={{ marginLeft: 'auto', color: theme.palette.error.main }} />;
};

const AccessDetailsCell: React.FC = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { cell } = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const openDialog = () => {
        setOpen(true);
    };
    const closeDialog = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="text" size="small" onClick={openDialog}>
                View details
            </Button>
            <Dialog open={open} onClose={closeDialog}>
                <DialogTitle>
                    Access Log Details (OK)
                    <IconButton
                        aria-label="close"
                        onClick={closeDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>Some mysterious code</Typography>
                    <Grid container>
                        {/*TODO: set these ternary operators properly once we know how get the infor */}
                        <Grid item xs={6} sx={{ display: 'flex', padding: 2 }}>
                            <Typography>Contract</Typography>
                            {false ? <GrantedIcon /> : <RefusedIcon />}
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', padding: 2 }}>
                            <Typography variant="caption">Card</Typography>
                            {true ? <GrantedIcon /> : <RefusedIcon />}
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', padding: 2 }}>
                            <Typography variant="caption">Training</Typography>
                            {true ? <GrantedIcon /> : <RefusedIcon />}
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', padding: 2 }}>
                            <Typography variant="caption">Dosimetry</Typography>
                            {true ? <GrantedIcon /> : <RefusedIcon />}
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', padding: 2 }}>
                            <Typography variant="caption">IMPACT</Typography>
                            {true ? <GrantedIcon /> : <RefusedIcon />}
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', padding: 2 }}>
                            <Typography variant="caption">Request</Typography>
                            {true ? <GrantedIcon /> : <RefusedIcon />}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

const AdamsCell: React.FC = (props: any) => {
    const { cell } = props;
    const { personId, accessPoint } = cell.value;
    const adamsPath = 'https://ords.cern.ch/ords/edmsdb/adams3/api/situationatacp/:personId/:accessPoint/Y';
    const url = getRoute({
        path: adamsPath,
        params: {
            personId: personId,
            accessPoint: accessPoint,
        },
    });

    const openNewTab = () => {
        window.open(url);
    };

    return <Link onClick={openNewTab}>ADaMS</Link>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AccessStatusCell: React.FC = (props: any) => {
    // TODO: set this ternary expression properly
    return false ? <GrantedIcon /> : <RefusedIcon />;
};

interface NoWrappedTextProps {
    text: string;
}
const NoWrappedText: React.FC<NoWrappedTextProps> = ({ text }) => {
    return (
        <Typography noWrap variant="body2">
            {text}
        </Typography>
    );
};

const DateCell: React.FC = (props: any) => {
    const { cell } = props;
    return <NoWrappedText text={cell.value ? format(new Date(cell.value), 'd-MMM-y') : ''} />;
};

const useDefaultColumns = () => {
    return useMemo(
        () => [
            {
                Header: 'Activity',
                accessor: 'activity',
            },
            {
                Header: '',
                accessor: 'tic',
                Cell: AccessStatusCell,
            },
            {
                Header: 'Access Details',
                accessor: 'accessDetails',
                Cell: AccessDetailsCell,
            },
            {
                Header: 'ADaMS',
                accessor: 'adams',
                Cell: AdamsCell,
            },
            {
                Header: 'Participant',
                accessor: 'participant',
            },
            {
                Header: 'Access point',
                accessor: 'accessPoint',
            },
            {
                Header: 'Facility',
                accessor: 'facility',
            },
            {
                Header: 'Access Start',
                accessor: 'accessStart',
                Cell: DateCell,
            },
            {
                Header: 'Access End',
                accessor: 'accessEnd',
                Cell: DateCell,
            },
            {
                Header: 'Granted Time',
                accessor: 'grantedTime',
                Cell: (props: any) => {
                    const { cell } = props;
                    return <NoWrappedText text={cell.value} />;
                },
            },
            {
                Header: 'Title',
                accessor: 'title',
            },
        ],
        []
    );
};

const preprocessActivities = (activities: any[]) => {
    let accesses = [];

    for (const activity of activities) {
        // TODO: switch to proper preprocessing when the list of participant is
        // returned by backend
        // for (const participant of activity.who.participants || []) {
        accesses.push({
            activity: activity.id,
            tic: '',
            accessDetails: {},
            adams: {
                personId: '634737',
                accessPoint: 'YSALC-01405',
            },
            // participant: participant.id,
            participant: activity.who.person.id,
            accessPoint: '',
            facility: activity.facility?.description || '',
            accessStart: activity.when.interventionPeriod?.start || '',
            accessEnd: activity.when.interventionPeriod?.end || '',
            grantedTime: activity.when.workingTime.description,
            title: activity.description,
        });
        // }
    }

    return accesses;
};

export const useAccessGridTableInstance = (activities: Array<any>) => {
    const data: Array<any> = useMemo(() => preprocessActivities(activities), [activities]);
    const columns: Array<Column<any>> = useDefaultColumns();
    return useTable({
        columns,
        data,
    });
};
