import { Search } from '@mui/icons-material';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    LinearProgress,
    List,
    ListItemButton,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getActivities } from '../../../api/activities';
import { useDebounce } from '../../../hooks/useDebounce';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { makeActivityDefaultFilter } from '../../../lib/filters/activity';
import { getRoute, ROUTES } from '../../../routes/routes';
import { searchQueryParam } from '../../searching/utils';

const defaultTitle = 'Search for Activities';

interface ISearchDialogProps {
    open: boolean;
    close: () => void;
    changeTitle: (newTitle: string) => void;
}

const SearchDialog: React.FunctionComponent<ISearchDialogProps> = ({ open, close, changeTitle }) => {
    const [textFieldValue, setTextFieldValue] = React.useState('');

    const navigate = useNavigate();
    const { isMobile } = useDeviceType();

    const textFieldDebounced = useDebounce(textFieldValue, 300);
    const { data, isFetching, isError } = useQuery(
        ['global-search', textFieldDebounced],
        () => getActivities({ filter: makeActivityDefaultFilter(textFieldDebounced) }),
        { enabled: !!textFieldDebounced }
    );
    const suggestions = data ? data.content : [];
    const closeComponents = () => {
        close();
    };
    const handleTitleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setTextFieldValue(value);
    };
    const handleSearch = async () => {
        changeTitle(textFieldValue);
        navigate(`${ROUTES.activities.search}?${searchQueryParam}=${textFieldValue}`);
        closeComponents();
    };

    return (
        <Dialog
            open={open}
            fullScreen={isMobile}
            onClose={close}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    minHeight: '300px',
                },
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <TextField
                    fullWidth
                    autoFocus
                    placeholder={defaultTitle}
                    value={textFieldValue}
                    variant="outlined"
                    onChange={handleTitleSearchChange}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    onFocus={(event) => event.target.select()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    aria-label="search activities"
                                    onClick={handleSearch}
                                    onMouseDown={handleSearch}
                                >
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogTitle>
            <DialogContent dividers>
                {isFetching ? (
                    <LinearProgress />
                ) : isError ? (
                    <Alert severity="error">There was an error fetching results</Alert>
                ) : (
                    <List>
                        {suggestions.map((result: any) => (
                            <ListItemButton
                                key={result.id}
                                onClick={() => {
                                    navigate(getRoute({ path: ROUTES.activities.view, params: { id: result.id } }));
                                    closeComponents();
                                }}
                            >
                                <ListItemText primary={result.id} secondary={result.whatTitle} />
                            </ListItemButton>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
                <Button onClick={handleSearch}>Search</Button>
            </DialogActions>
        </Dialog>
    );
};

const SearchElement = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    width: useDeviceType().isMobile ? 'auto' : '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

interface SearchBarProps {}
export const SearchBar: React.FunctionComponent<SearchBarProps> = ({}) => {
    const { isMobile } = useDeviceType();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const changeTitle = (newTitle: string) => {
        setTitle(newTitle);
    };

    return (
        <SearchElement>
            <IconButton
                onClick={handleClickOpen}
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Search />
            </IconButton>
            {isMobile ? null : (
                <Typography onClick={handleClickOpen} sx={{ width: '100%' }} color="inherit">
                    {title || defaultTitle}
                </Typography>
            )}
            <SearchDialog open={open} close={handleClose} changeTitle={changeTitle} />
        </SearchElement>
    );
};
