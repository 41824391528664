import { Pageview, PageviewOutlined, QuestionMark, Refresh, Start, Terminal, Update } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDocumentAudit } from '../../api/admin/audit';
import { DocumentType } from '../../api/dto';
import { ImpactFormattedDate } from '../ImpactFormattedDate';

interface AuditDataProps {
    documentType: DocumentType;
    documentId: number;
    open: boolean;
    onClose: () => void;
}

const getAuditEntryIcon = (type: string) => {
    switch (type) {
        case 'INITIAL':
            return <Start />;
        case 'UPDATE':
            return <Update />;
        case 'TERMINAL':
            return <Terminal />;
        default:
            return <QuestionMark />;
    }
};

export const AuditData = ({ documentType, documentId, open, onClose }: AuditDataProps) => {
    const [selected, setSelected] = React.useState<number | null>(null);

    const {
        data: auditDataEntries,
        isLoading: isAuditLoading,
        refetch: refetchAuditDataEntries,
        isRefetching,
    } = useQuery(['audit', documentType, documentId], () => getDocumentAudit({ documentType, documentId }));

    const handleToggle = (index: number) => () => {
        setSelected(selected === null || selected !== index ? index : null);
    };

    const hasSelected = selected !== null;

    return (
        <>
            <Dialog open={open} fullScreen onClose={onClose}>
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h6">{`Audit: ${documentType} - ${documentId}`}</Typography>

                        <IconButton onClick={() => refetchAuditDataEntries()} disabled={isRefetching}>
                            <Refresh />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                {isAuditLoading || isRefetching ? (
                    <Box sx={{ height: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <>
                        <Divider />
                        <Box display="flex" overflow="hidden" height="100%">
                            <Box sx={{ flexBasis: hasSelected ? '50%' : '100%', overflow: 'auto' }}>
                                <List dense>
                                    {/* any type for auditEntry is used on purpose to save time since this is an admin only feature currently */}
                                    {auditDataEntries?.map((auditEntry: any, index: number) => (
                                        <ListItemButton
                                            key={auditEntry.commitMetadata.id}
                                            onClick={handleToggle(index)}
                                            dense
                                        >
                                            <ListItem
                                                alignItems="flex-start"
                                                secondaryAction={
                                                    <Checkbox
                                                        edge="end"
                                                        icon={<PageviewOutlined />}
                                                        checkedIcon={<Pageview />}
                                                        onChange={handleToggle(index)}
                                                        checked={selected === index}
                                                        color="primary"
                                                    />
                                                }
                                                disablePadding
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: selected === index ? 'primary.main' : undefined,
                                                        }}
                                                    >
                                                        {getAuditEntryIcon(auditEntry.type)}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Typography component="span">
                                                            {`${auditEntry.type} [${auditEntry.version}]`}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography variant="body2" component="span">
                                                                <ImpactFormattedDate
                                                                    date={auditEntry.commitMetadata.commitDate}
                                                                    time
                                                                />
                                                                {' by '}
                                                                <Typography component="span" variant="subtitle2">
                                                                    {auditEntry.commitMetadata.author}
                                                                </Typography>
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                            {index < auditDataEntries.length - 1 && <Divider component="li" />}
                                        </ListItemButton>
                                    ))}
                                </List>
                            </Box>

                            {hasSelected && auditDataEntries && (
                                <Box
                                    sx={{
                                        flexBasis: hasSelected ? '50%' : '100%',
                                        overflow: 'auto',
                                        background: grey[200],
                                        p: 1,
                                    }}
                                >
                                    <pre>
                                        <code>{JSON.stringify(auditDataEntries[selected], null, 2)}</code>
                                    </pre>
                                </Box>
                            )}
                        </Box>
                        <Divider />
                    </>
                )}

                <DialogActions>
                    <Button onClick={onClose}>
                        <FormattedMessage id="common.close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
