import { ArrowRightAlt, Error as ErrorIcon } from '@mui/icons-material';
import { Box, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { IntlShape, useIntl } from 'react-intl';
import { TFormElement } from '../forms/types';
import { UiSchemaType } from '../forms/UiSchemaType';
import { fieldPathsEqual, joinFieldPath, splitFieldPath } from '../forms/utils';
import { DefaultAccordion } from './DefaultAccordion';

interface ErrorItem {
    path: string[];
    message: string;
}

interface LabeledErrorItem extends ErrorItem {
    labels: string[];
}

const fieldErrorsToArray = (errors: FieldErrors<{}> | undefined): ErrorItem[] => {
    const concatPath = <T extends { path: string[] }>(prefix: string[], t: T): T => ({
        ...t,
        path: [...prefix, ...t.path].filter((v) => !!v),
    });
    return Object.entries(errors ?? {}).flatMap(([key, value]) => {
        if (Array.isArray(value)) {
            return value.flatMap((o, i) => fieldErrorsToArray(o).map((v) => concatPath([key, `${i}`], v)));
        } else {
            const { type, message, ref, ...rest } = value;
            const additional =
                type !== undefined && message !== undefined ? [{ path: [], message: message ?? '' }] : [];
            return fieldErrorsToArray(rest)
                .concat(additional)
                .map((v) => concatPath([key], v));
        }
    });
};

const getAttributeOrUndefined = (o: any, attribute: string | number): any | undefined =>
    attribute in o ? o[attribute] : undefined;

const matchSchemaAndErrorRecursive = (
    pathPrefix: string[],
    labelsPrefix: string[],
    errors: ErrorItem[],
    schema: TFormElement,
    formData: any,
    intl: IntlShape
): LabeledErrorItem[] => {
    switch (schema.type) {
        case UiSchemaType.LAYOUT_VERTICAL:
        case UiSchemaType.LAYOUT_HORIZONTAL:
            return schema.elements.flatMap((element) =>
                matchSchemaAndErrorRecursive(pathPrefix, labelsPrefix, errors, element, formData, intl)
            );
        case UiSchemaType.LAYOUT_GRID:
        case UiSchemaType.LAYOUT_RESPONSIVE:
            return schema.elements.flatMap(({ element }) =>
                matchSchemaAndErrorRecursive(pathPrefix, labelsPrefix, errors, element, formData, intl)
            );
        case UiSchemaType.PANEL:
            return schema.element
                ? matchSchemaAndErrorRecursive(
                      pathPrefix,
                      [...labelsPrefix, schema.label],
                      errors,
                      schema.element,
                      formData,
                      intl
                  )
                : [];
        case UiSchemaType.INPUT:
        case UiSchemaType.AUTOCOMPLETE:
        case UiSchemaType.AUTOCOMPLETE_ASYNC:
        case UiSchemaType.CHECKBOX:
        case UiSchemaType.DATE:
        case UiSchemaType.DATETIME:
        case UiSchemaType.TIME:
        case UiSchemaType.RICH_TEXT: {
            const currentPath = [...pathPrefix, ...splitFieldPath(schema.path)];
            return errors
                .filter((e) => fieldPathsEqual(e.path, currentPath))
                .map((e) => ({
                    ...e,
                    labels: [...labelsPrefix, schema.label],
                }))
                .slice(0, 1);
        }
        case UiSchemaType.FIELD_ARRAY: {
            const currentPath = [...pathPrefix, ...splitFieldPath(schema.path)];
            const collected = errors
                .filter((e) => fieldPathsEqual(e.path.slice(0, pathPrefix.length + 1), currentPath))
                .map((e) => ({
                    ...e,
                    // If we want a more specific label than "Add an <entity>", then we should define an additional attribute
                    // For now this is simple and good enough
                    labels: [...labelsPrefix, schema.appendLabel],
                }));
            // Errors on the field array field itself (e.g. array must contain at least one element)
            const fieldArrayRootErrors = collected.filter((e) => e.path.length === pathPrefix.length + 1).slice(0, 1);
            // Errors on a field inside a field array
            const fieldArrayChildrenErrors = collected.filter((e) => e.path.length > pathPrefix.length + 1);
            return fieldArrayRootErrors.concat(
                fieldArrayChildrenErrors.flatMap((e) => {
                    const index = parseInt(e.path[pathPrefix.length + 1]);
                    return matchSchemaAndErrorRecursive(
                        [...pathPrefix, ...splitFieldPath(schema.path), String(index)],
                        // Same remark as above
                        [
                            ...labelsPrefix,
                            schema.appendLabel,
                            `${intl.formatMessage({ id: 'document.field.nested.item' })} #${index + 1}`,
                        ],
                        [e],
                        schema.element,
                        getAttributeOrUndefined(getAttributeOrUndefined(formData, schema.path), index),
                        intl
                    );
                })
            );
        }
        case UiSchemaType.FIELD_ARRAY_TABS: {
            const currentPath = [...pathPrefix, ...splitFieldPath(schema.path)];
            const collected = errors
                .filter((e) => fieldPathsEqual(e.path.slice(0, pathPrefix.length + 1), currentPath))
                .map((e) => {
                    const index = parseInt(e.path[pathPrefix.length + 1]);
                    const formDataLocal = getAttributeOrUndefined(
                        getAttributeOrUndefined(formData, schema.path),
                        index
                    );
                    return {
                        ...e,
                        labels: [...labelsPrefix, schema.tabLabel(formDataLocal)],
                    };
                });
            const fieldArrayRootErrors = collected.filter((e) => e.path.length === pathPrefix.length + 1).slice(0, 1);
            const fieldArrayChildrenErrors = collected.filter((e) => e.path.length > pathPrefix.length + 1);
            return fieldArrayRootErrors.concat(
                fieldArrayChildrenErrors.flatMap((e) => {
                    const index = parseInt(e.path[pathPrefix.length + 1]);
                    const formDataLocal = getAttributeOrUndefined(
                        getAttributeOrUndefined(formData, schema.path),
                        index
                    );
                    return matchSchemaAndErrorRecursive(
                        [...pathPrefix, ...splitFieldPath(schema.path), String(index)],
                        [...labelsPrefix, schema.tabLabel(formDataLocal)],
                        [e],
                        schema.element,
                        formDataLocal,
                        intl
                    );
                })
            );
        }
        case UiSchemaType.CUSTOM:
        case UiSchemaType.REGION:
        case UiSchemaType.RANGE:
        case UiSchemaType.FILTER_GROUP:
            return [];
    }
};

const matchSchemaAndErrors = (
    errors: FieldErrors<{}>,
    schema: TFormElement,
    formData: any,
    intl: IntlShape
): LabeledErrorItem[] => {
    const flatErrors = fieldErrorsToArray(errors);
    const data = matchSchemaAndErrorRecursive([], [], flatErrors, schema, formData, intl);

    const collectedErrorPathsSet = new Set(data.map((e) => joinFieldPath(e.path)));
    const unknownErrors = flatErrors.filter((e) => !collectedErrorPathsSet.has(joinFieldPath(e.path)));
    // Display all other errors at the end
    return data.concat(
        unknownErrors.map((e) => ({
            ...e,
            labels: [intl.formatMessage({ id: 'document.field.nested.unknown' }), joinFieldPath(e.path)],
        }))
    );
};

interface ValidationErrorsPanelProps {
    schema: TFormElement;
}

export const ValidationErrorsPanel: React.FC<ValidationErrorsPanelProps> = ({ schema }) => {
    const intl = useIntl();
    const { formState, getValues } = useFormContext();
    const formData = useMemo(() => getValues(), [getValues, formState.errors]);
    const data = matchSchemaAndErrors(formState.errors, schema, formData, intl);

    const handleItemClickFor = (path: string[]) => () => {
        const errorPath = joinFieldPath(path);
        const results = Array.from(document.getElementsByName(errorPath)).concat(
            [document.getElementById(errorPath)].filter((e): e is HTMLElement => e !== null)
        );
        if (results.length) {
            const element = results[0];
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return data.length > 0 ? (
        <Box sx={{ mb: 2 }}>
            <DefaultAccordion
                title={intl.formatMessage({ id: 'document.incorrectFields.panel' })}
                icon={<Chip label={data.length} size="small" color="error" />}
                disableGutters
            >
                <List dense sx={{ py: 0 }}>
                    {data.map(({ path, labels, message }) => (
                        <ListItem key={joinFieldPath(path)} disablePadding>
                            <ListItemButton onClick={handleItemClickFor(path)}>
                                <ListItemIcon>
                                    <ErrorIcon color="error" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            {labels.map((label, i) => (
                                                <Fragment key={i}>
                                                    {i > 0 && <ArrowRightAlt color="action" />}
                                                    <Box fontWeight={i === labels.length - 1 ? 'bold' : undefined}>
                                                        {label}
                                                    </Box>
                                                </Fragment>
                                            ))}
                                        </Stack>
                                    }
                                    secondary={message}
                                    sx={{ my: 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DefaultAccordion>
        </Box>
    ) : null;
};
