import { AccessPointDTO } from '../../api/dto';
import { FilterTypeEnum } from '../../components/searching/filters';
import { fetchAccessPointsByName } from '../../lib/api';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement } from '../types';

const accessPointTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'accessPointName',
    fetchOptions: (searchTerm) => fetchAccessPointsByName(searchTerm),
    fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
    getOptionLabel: (value) => value.name,
    multiple: false,
});
interface AccessPointFilterSchemaParameters extends Pick<TFilterElement, 'id' | 'label'> {}

export const accessPointFilterSchema = ({ id, label }: AccessPointFilterSchemaParameters): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: AccessPointDTO) => value.name,
    typeProperties: {
        ...accessPointTypeProperties(),
        autocompleteProps: {
            openOnFocus: true,
            disableCloseOnSelect: false,
        },
    },
});
