import { Lock, NoEncryptionGmailerrorred, Settings } from '@mui/icons-material';
import * as React from 'react';
import { IntlShape } from 'react-intl';
import { getLockoutWorkingTimes } from '../../../api/documents/lockouts';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { locationSchema } from '../location';
import { valueSchema } from '../value';

export const lockoutFormSchema = (intl: IntlShape): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                typeProperties: {
                    direction: {
                        xs: 'column',
                    },
                },
                elements: [
                    {
                        element: locationSchema({
                            label: 'Locations',
                            path: 'lockoutLocations',
                            multiple: true,
                        }),
                    },
                    // TODO equipment (cryo)
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Equipment',
                            path: 'electricalEquipmentText',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.CHECKBOX,
                            label: 'Full lockout',
                            path: 'full',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.DATETIME,
                                        label: 'Requested start',
                                        path: 'requestStartDate',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.DATETIME,
                                        label: 'Requested end',
                                        path: 'requestEndDate',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Comments',
                            path: 'comments',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'Performing lockout',
            typeProperties: {
                icon: <Lock color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                elements: [
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: 'Procedure',
                                        path: 'lockEdmsProcedure',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: 'Other procedure',
                                        path: 'lockOtherProcedure',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Power converter(s)',
                            path: 'powerConvertersText',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: 'Initial lockout certificate ID', // How do we translate "Attestation de Premiere Etape de Consignation ID"?
                                        path: 'lockout1level',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: 'Work authorization ID',
                                        path: 'lockout2level',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: valueSchema(
                            {
                                path: 'grantedWorkingTime',
                                label: 'Granted time',
                                fetchOptions: getLockoutWorkingTimes,
                            },
                            intl.locale
                        ),
                    },
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.TIME,
                                        label: 'Granted start',
                                        path: 'grantedWorkingTimeStart',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.TIME,
                                        label: 'Granted end',
                                        path: 'grantedWorkingTimeEnd',
                                    },
                                },
                            ],
                        },
                    },

                    {
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: 'Execution date',
                            path: 'requestEndDate',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Comments',
                            path: 'lockoutComments',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: 'Removing lockout',
            typeProperties: {
                icon: <NoEncryptionGmailerrorred color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_RESPONSIVE,
                elements: [
                    {
                        element: {
                            type: UiSchemaType.LAYOUT_RESPONSIVE,
                            typeProperties: {
                                direction: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                            },
                            elements: [
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: 'Procedure',
                                        path: 'unlockEdmsProcedure',
                                    },
                                },
                                {
                                    element: {
                                        type: UiSchemaType.INPUT,
                                        label: 'Other procedure',
                                        path: 'unlockOtherProcedure',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: 'Execution date',
                            path: 'unlockExecutionDate',
                        },
                    },
                    {
                        element: {
                            type: UiSchemaType.INPUT,
                            label: 'Comments',
                            path: 'unlockExecutionComments',
                            typeProperties: {
                                multiline: true,
                            },
                        },
                    },
                ],
            },
        },
    ],
});
