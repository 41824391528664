import { ChevronRight, Close, ExpandMore, Groups } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import {
    Alert,
    Badge,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { EgroupWithMembersDTO, NdcReadDTO, NdcUpdateDTO, PersonReadDTO } from '../api/dto';
import { mapNdcToUpdateDTO } from '../lib/records/documents/noteDeCoupure';

interface CommonListProps<DTO> {
    fetchElements: (ndcDTO: NdcUpdateDTO) => Promise<DTO[]>;
    title: string;
    path: string;
    hidden?: boolean;
    disabled?: boolean;
}

type PersonListProps = CommonListProps<PersonReadDTO>;
type EgroupListProps = CommonListProps<EgroupWithMembersDTO>;

interface PersonLayoutProps {
    isLoading: boolean;
    children: React.ReactNode;
    title: string;
    hidden?: boolean;
    disabled?: boolean;
    count: number | undefined;
}

const PersonLayout: React.FC<PersonLayoutProps> = ({ isLoading, children, title, hidden, disabled, count }) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true);
    const [cachedCount, setCachedCount] = useState(count);

    useEffect(
        () => setCachedCount(hidden ? undefined : count ?? cachedCount),
        [hidden, count, cachedCount, setCachedCount]
    );

    return hidden ? (
        <></>
    ) : (
        <>
            <Tooltip title={intl.formatMessage({ id: 'ndc.distribution.tooltip' })} placement="right">
                <span>
                    <IconButton onClick={handleClick} disabled={disabled}>
                        <Badge
                            badgeContent={cachedCount}
                            showZero
                            max={Number.MAX_SAFE_INTEGER}
                            color={cachedCount !== 0 ? 'primary' : 'error'}
                        >
                            <Groups />
                        </Badge>
                    </IconButton>
                </span>
            </Tooltip>

            <Dialog
                maxWidth={'sm'}
                fullWidth
                PaperProps={{
                    sx: {
                        maxHeight: '40vh',
                        minHeight: '40vh',
                    },
                }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    {title}
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>{isLoading ? <LinearProgress /> : children}</DialogContent>
            </Dialog>
        </>
    );
};

const getAffectedLocationsKey = (ndc: NdcUpdateDTO & NdcReadDTO): string => {
    const affectedLocations: (number | undefined | null)[] = ndc.affectedNetworksAndEquipment.map(
        (o) => o.location?.id
    );
    return JSON.stringify(affectedLocations);
};

export const PersonList: React.FC<PersonListProps> = ({ fetchElements, title, path, hidden, disabled }) => {
    const { watch } = useFormContext();

    const values = watch();
    const ndcDto = mapNdcToUpdateDTO(values);
    const { isLoading, isError, data } = useQuery(
        [path, getAffectedLocationsKey(ndcDto)],
        () => fetchElements(ndcDto),
        {
            enabled: !hidden,
        }
    );

    return (
        <PersonLayout isLoading={isLoading} title={title} hidden={hidden} disabled={disabled} count={data?.length}>
            {isError ? (
                <Alert severity="error">
                    <FormattedMessage id="ndc.distribution.compute.error" />
                </Alert>
            ) : (
                <List dense disablePadding>
                    {data?.length ? (
                        data?.map((person) => {
                            return (
                                <ListItem key={person.id} disablePadding>
                                    <ListItemButton>
                                        <ListItemText primary={person.searchLabel} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    ) : (
                        <Typography>
                            <FormattedMessage id="ndc.distribution.noPeople" />
                        </Typography>
                    )}
                </List>
            )}
        </PersonLayout>
    );
};

export const EgroupList: React.FC<EgroupListProps> = ({ fetchElements, title, path, hidden, disabled }) => {
    const { watch } = useFormContext();

    const values = watch();
    const ndcDto = mapNdcToUpdateDTO(values);
    const { isLoading, isError, data } = useQuery(
        [path, getAffectedLocationsKey(ndcDto)],
        () => fetchElements(ndcDto),
        {
            enabled: !hidden,
        }
    );
    return (
        <PersonLayout
            isLoading={isLoading}
            title={title}
            hidden={hidden}
            disabled={disabled}
            count={data?.map((egroup) => egroup.members.length).reduce((a, b) => a + b, 0)}
        >
            {isError ? (
                <Alert severity="error">
                    <FormattedMessage id="ndc.distribution.compute.error" />
                </Alert>
            ) : data?.length ? (
                data?.map((egroup) => {
                    return (
                        <TreeView
                            key={egroup.name}
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpandIcon={<ChevronRight />}
                            disableSelection
                        >
                            <TreeItem
                                nodeId={egroup.name as string}
                                label={`${egroup.name ?? ''} ${
                                    egroup.members?.length ? ' (' + egroup.members?.length + ')' : ' (0)'
                                }`}
                            >
                                {egroup.members?.map((member) => {
                                    return (
                                        <TreeItem
                                            nodeId={member.id.toString()}
                                            label={member.searchLabel}
                                            key={member.id}
                                        />
                                    );
                                })}
                            </TreeItem>
                        </TreeView>
                    );
                })
            ) : (
                <Typography>
                    <FormattedMessage id="ndc.distribution.noPeople" />
                </Typography>
            )}
        </PersonLayout>
    );
};
