import { Alert, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getWdpVersion, getWdpVersionLatest } from '../../../api/documents/wdps';
import { DocumentType, WdpVersionReadDTO } from '../../../api/dto';
import { WdpForm } from '../../../forms/documents/WdpForm';
import { getRoute, ROUTES } from '../../../routes/routes';
import { WdpPageVariant } from './WdpPageVariant';

interface WdpPageProps {
    variant: WdpPageVariant;
}

export const WdpPage: React.FC<WdpPageProps> = ({ variant }) => {
    const { id: wdpId, version: wdpVersionNumber } = useParams();
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        data: wdpData,
        isLoading: wdpDataLoading,
        isError: wdpDataFailed,
        remove,
        refetch,
    } = useQuery(
        [DocumentType.WDP, wdpId, wdpVersionNumber],
        () =>
            wdpVersionNumber !== undefined
                ? getWdpVersion({ id: String(wdpId), versionNumber: parseInt(wdpVersionNumber) })
                : getWdpVersionLatest({ id: String(wdpId) }),
        {
            enabled: wdpId !== undefined,
            onError: () => toast.error('There was an error fetching the WDP'),
        }
    );

    const [wdp, setWdp] = React.useState<WdpVersionReadDTO>(wdpData!);

    useEffect(() => {
        if (wdpData) {
            if (variant === WdpPageVariant.READ) {
                navigate(
                    getRoute({
                        path: ROUTES.wdp.view,
                        params: { id: wdpData.wdpMasterId, version: wdpData.versionNumber },
                    }),
                    {
                        replace: true,
                    }
                );
            }
            setWdp(wdpData);
        }
    }, [wdpData, navigate]);

    const refreshData = useCallback(() => {
        remove();
        return refetch();
    }, [remove, refetch]);

    if (wdpDataLoading && wdpId !== undefined) {
        return <LinearProgress />;
    }

    if (wdpDataFailed) {
        return (
            <Alert severity="error" sx={{ m: 2 }}>
                There has been an error fetching document data
            </Alert>
        );
    }

    if (variant === WdpPageVariant.CREATE || (variant === WdpPageVariant.READ && wdpData)) {
        return (
            <WdpForm
                data={variant === WdpPageVariant.CREATE ? { variant } : { variant, wdpData: wdpData! }}
                refreshData={refreshData}
                wdp={wdp!}
                setWdp={setWdp}
            />
        );
    }
    return null;
};
