import { FirePermitStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const FirePermitStatusColorMapping: Record<FirePermitStatusCode, StatusColor> = {
    [FirePermitStatusCode.NEW]: StatusColor.COLOR_PRIMARY,
    [FirePermitStatusCode.SAVED]: StatusColor.COLOR_PRIMARY,
    [FirePermitStatusCode.WAITING_APPROVAL]: StatusColor.COLOR_WARNING,
    [FirePermitStatusCode.APPROVED]: StatusColor.COLOR_SUCCESS,
    [FirePermitStatusCode.WAITING_END]: StatusColor.COLOR_WARNING,
    [FirePermitStatusCode.FINISHED]: StatusColor.COLOR_NORMAL,
    [FirePermitStatusCode.CLOSED]: StatusColor.COLOR_NORMAL,
    [FirePermitStatusCode.REJECTED]: StatusColor.COLOR_ERROR,
    [FirePermitStatusCode.CANCELLED]: StatusColor.COLOR_ERROR,
};
