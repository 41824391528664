import { AccountTree, Bookmark, Business, RoomPreferences, Sensors } from '@mui/icons-material';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListSubheader, Toolbar } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { ActivityIcon } from '../../../lib/information/activity';
import { DimrIcon } from '../../../lib/information/dimr';
import { FirePermitIcon } from '../../../lib/information/firePermit';
import { Is37Icon } from '../../../lib/information/is37';
import { LockoutIcon } from '../../../lib/information/lockout';
import { NdcIcon } from '../../../lib/information/noteDeCoupure';
import { VicIcon } from '../../../lib/information/vic';
import { WdpIcon } from '../../../lib/information/wdp';
import { ROUTES, RouteType } from '../../../routes/routes';
import { CreateDropdown } from '../../CreateDropdown';
import { TaskMenuEntry } from '../../tasks/TaskMenuEntry';

interface ISideMenuProps {
    closeMenu: () => void;
}

interface MenuEntry {
    labelKey: MessageDescriptor['id'];
    icon: React.ReactElement;
    route: RouteType;
}
interface MenuSection {
    nameKey?: MessageDescriptor['id'];
    entries: MenuEntry[];
}

const documents: MenuEntry[] = [
    { labelKey: 'sidebar.activities', icon: <ActivityIcon />, route: ROUTES.activities.search },
    { labelKey: 'sidebar.favouriteActivities', icon: <Bookmark />, route: ROUTES.activities.favourites },
    { labelKey: 'sidebar.activityCluster', icon: <AccountTree />, route: ROUTES.activities.clusters },
    { labelKey: 'sidebar.dimrs', icon: <DimrIcon />, route: ROUTES.dimr.search },
    { labelKey: 'sidebar.wdps', icon: <WdpIcon />, route: ROUTES.wdp.search },
    { labelKey: 'sidebar.vics', icon: <VicIcon />, route: ROUTES.vic.search },
    { labelKey: 'sidebar.lockouts', icon: <LockoutIcon />, route: ROUTES.lockout.search },
    { labelKey: 'sidebar.firePermits', icon: <FirePermitIcon />, route: ROUTES.firePermit.search },
    { labelKey: 'sidebar.is37s', icon: <Is37Icon />, route: ROUTES.is37.search },
    { labelKey: 'sidebar.ndcs', icon: <NdcIcon />, route: ROUTES.noteDeCoupure.search },
    { labelKey: 'sidebar.tasks', icon: <TaskMenuEntry />, route: ROUTES.tasks },
];
const reports: MenuEntry[] = [
    { labelKey: 'sidebar.radiationDoses', icon: <Sensors />, route: ROUTES.radiationDoses },
    { labelKey: 'sidebar.accessControl', icon: <RoomPreferences />, route: ROUTES.accessControl },
    { labelKey: 'sidebar.locations', icon: <Business />, route: ROUTES.locations },
];

const sections: MenuSection[] = [{ entries: documents }, { nameKey: 'sidebar.reports', entries: reports }];

export const SideMenu: React.FunctionComponent<ISideMenuProps> = ({ closeMenu }) => {
    const isMobile: boolean = useDeviceType().isMobile;
    const { pathname } = useLocation();
    const bestRouteMatch: string | null = useMemo(
        () =>
            sections
                .flatMap((section) => section.entries)
                .map((entry) => entry.route)
                .filter((route) => matchPath({ path: route, end: false }, pathname))
                .sort((a, b) => b.length - a.length)[0] ?? null,
        [pathname]
    );
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Toolbar />
            <Box sx={{ px: 1.33, py: 2 }}>
                <CreateDropdown buttons={undefined} />
            </Box>

            {sections.map((section, sectionIdx) => {
                return (
                    <Box key={sectionIdx}>
                        <List
                            subheader={
                                <>
                                    <Divider />
                                    {section.nameKey !== undefined && (
                                        <ListSubheader sx={{ fontWeight: 'bold', lineHeight: '40px' }}>
                                            <FormattedMessage id={section.nameKey} />
                                        </ListSubheader>
                                    )}
                                </>
                            }
                            sx={{ pb: 0 }}
                        >
                            {section.entries.map(({ labelKey, icon, route }, idx) => {
                                return (
                                    <ListItemButton
                                        key={idx}
                                        onClick={() => {
                                            if (isMobile) closeMenu();
                                        }}
                                        selected={bestRouteMatch === route}
                                        component={Link}
                                        to={route}
                                    >
                                        <ListItemIcon>{icon}</ListItemIcon>
                                        <FormattedMessage id={labelKey} />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </Box>
                );
            })}
        </Box>
    );
};
