import { AddCircle, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box } from '@mui/material';
import { CellLocation } from '@silevis/reactgrid';
import { Close } from 'mdi-material-ui';
import React, { useCallback, useMemo } from 'react';
import { WdpVersionReadDTO } from '../../../../api/dto';
import { ResponsiveButton } from '../../../../components/ResponsiveButton';
import { ResponsiveButtonSet } from '../../../../components/ResponsiveButtonSet';
import { NEW_ENTITY_FIELD, Spreadsheet, SpreadsheetProps } from '../../../../components/spreadsheet/Spreadsheet';
import { FieldMetadata, MenuEntry, TableHeader, WdpDataKey } from '../WdpInterfaces';

export interface WdpTabProps {
    menuEntries: MenuEntry[];
    headers: TableHeader[];
    wdp: WdpVersionReadDTO;
    setWdp: React.Dispatch<React.SetStateAction<WdpVersionReadDTO>>;
    newRowSupplier: () => { unknown: boolean } | (() => {});
    metadata: FieldMetadata;
    spreadsheetProps: Omit<SpreadsheetProps, 'data' | 'headers' | 'keyField' | 'setData'>;
    customTypes: WdpVersionReadDTO | undefined;
}

export const WdpTab: React.FC<WdpTabProps> = ({
    menuEntries,
    headers,
    wdp,
    setWdp,
    newRowSupplier,
    metadata,
    spreadsheetProps,
    customTypes,
}) => {
    const [cellLocation, setCellLocation] = React.useState<CellLocation | null>(null);
    const rowId = cellLocation?.rowId;
    const dataKey: WdpDataKey = metadata.dataKey as WdpDataKey;
    const idKey = metadata.idKey;

    const data = wdp[dataKey];
    const setData = useCallback(
        (callbackOrData: ((any: any) => any) | typeof data) =>
            setWdp((prevData: WdpVersionReadDTO) => {
                return {
                    ...(prevData ?? {}),
                    [metadata.dataKey]:
                        typeof callbackOrData === 'function' ? callbackOrData(prevData[dataKey]) : callbackOrData,
                };
            }),
        [metadata]
    ) as (callbackOrData: ((any: any) => any) | unknown[]) => void;

    const addRow = useCallback(() => {
        setData((prevData: Record<string, number>[]) => {
            const newData = Array.isArray(prevData) ? [...prevData] : [];
            const newRow = {
                [metadata.idKey]: newData.reduce((acc, t) => (t[idKey] < acc ? t[idKey] : acc), 0) - 1,
                idx: newData.length,
                [NEW_ENTITY_FIELD]: true,
                ...newRowSupplier(),
            };
            return [...newData, newRow];
        });
    }, [setData, metadata]);

    const removeRow = useCallback(
        () =>
            setData((prevData: Record<string, number>[]) => {
                const newData = Array.isArray(prevData) ? [...prevData] : [];
                return newData.filter((t) => t[idKey] !== rowId);
            }),
        [setData, metadata, rowId]
    );

    const moveRow = useCallback(
        (direction: -1 | 1) => {
            setData((prevData: Record<string, number>[]) => {
                const tempData = prevData.slice();
                const i = tempData.findIndex((t) => t?.[idKey] === rowId);
                if (i > -1 && i + direction >= 0 && i + direction < tempData.length) {
                    const temp = tempData[i];
                    tempData[i] = tempData[i + direction];
                    tempData[i + direction] = temp;
                    return tempData;
                }
                return prevData;
            });
        },
        [setData, metadata, rowId]
    );

    const defaultMenuEntries: MenuEntry[] = useMemo(
        () => [
            { label: `Add ${metadata.label}`, Icon: AddCircle, onClick: addRow },
            { label: `Remove ${metadata.label}`, Icon: Close, onClick: removeRow },
            { label: `Move ${metadata.label} up`, Icon: ArrowUpward, onClick: () => moveRow(-1) },
            { label: `Move ${metadata.label} down`, Icon: ArrowDownward, onClick: () => moveRow(1) },
            ...menuEntries,
        ],
        [addRow, removeRow, moveRow]
    );

    return (
        <>
            <ResponsiveButtonSet
                buttons={defaultMenuEntries.map(({ label, onClick, Icon, tooltip }) => (
                    <ResponsiveButton
                        buttonVariant="contained"
                        onClick={onClick}
                        icon={<Icon />}
                        tooltipText={tooltip ?? label}
                    >
                        {label}
                    </ResponsiveButton>
                ))}
            />
            <Box>
                <Box sx={{ overflowX: 'scroll', paddingBottom: '10px', paddingTop: '10px' }}>
                    <Spreadsheet
                        headers={headers}
                        data={data}
                        setData={setData}
                        keyField={metadata.idKey}
                        menuEntries={defaultMenuEntries}
                        onFocus={setCellLocation}
                        headerHeight={80}
                        rowHeight={50}
                        addIdxHeader={true}
                        customTypes={customTypes}
                        {...spreadsheetProps}
                    />
                </Box>
            </Box>
        </>
    );
};
