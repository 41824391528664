import { ListOfValuesReadDTO } from '../../api/dto';
import { GetManyUnpaged } from '../../api/types';
import { FilterTypeEnum } from '../../components/searching/filters';
import { Locale } from '../../i18n/Locale';
import { getLovLabel } from '../../lib/label';
import { translateValue } from '../../lib/language';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFilterElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface ValueTypePropertiesParameters {
    fetchOptions: GetManyUnpaged<{}, ListOfValuesReadDTO>;
    multiple?: boolean;
}

const valueTypeProperties = (
    { fetchOptions, multiple }: ValueTypePropertiesParameters,
    locale: Locale
): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'code',
    fetchOptions: () => fetchOptions(),
    fetchUseCases: [FetchUseCase.ON_LOAD],
    getOptionLabel: (value) => getLovLabel(translateValue(value, locale)),
    multiple,
});

interface ValueSchemaParameters
    extends ValueTypePropertiesParameters,
        Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const valueSchema = (
    { path, label, fetchOptions, multiple, required }: ValueSchemaParameters,
    locale: Locale
): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: valueTypeProperties({ fetchOptions, multiple }, locale),
});

interface ValueFilterSchemaParameters extends ValueTypePropertiesParameters, Pick<TFilterElement, 'id' | 'label'> {}

export const valueFilterSchema = (
    { id, label, fetchOptions }: ValueFilterSchemaParameters,
    locale: Locale
): TFilterElement => ({
    type: FilterTypeEnum.AUTOCOMPLETE_ASYNC,
    id,
    label,
    valueGetter: (value: ListOfValuesReadDTO) => value.code,
    typeProperties: {
        ...valueTypeProperties({ fetchOptions }, locale),
        autocompleteProps: {
            openOnFocus: true,
            disableCloseOnSelect: false,
        },
    },
});
