/* eslint-disable react/destructuring-assignment*/
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DocumentType } from '../../../api/dto';
import { DocumentHeader } from '../../../components/documents/DocumentHeader';
import { DimrIcon } from '../../../lib/information/dimr';
import { getDimrStatus } from '../../../lib/status';
import { getRoute, ROUTES } from '../../../routes/routes';
import { DIMRPageData } from './DIMRPageData';
import { DIMRPageVariant } from './DIMRPageVariant';

interface DIMRContentHeaderProps {
    data: DIMRPageData;
    editable: boolean;
    isLoading: boolean;
    onRefreshClick?: () => void;
    Main?: React.ReactNode;
    Left?: React.ReactNode;
    Right?: React.ReactNode;
}

export const DIMRContentHeader: React.FC<DIMRContentHeaderProps> = ({ data, ...rest }) => {
    const { variant } = data;
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <DocumentHeader
            documentType={DocumentType.DIMR}
            documentId={variant === DIMRPageVariant.READ ? data.dimrData.id : null}
            icon={<DimrIcon />}
            title={variant === DIMRPageVariant.READ ? `DIMR ${data.dimrData.dimrMasterId}` : 'New DIMR'}
            version={
                variant === DIMRPageVariant.READ
                    ? {
                          currentVersionNumber: data.dimrData.versionNumber,
                          versions: data.dimrData.versionNumbers.map(({ versionNumber, status }) => ({
                              versionNumber,
                              status: getDimrStatus({ status }, intl),
                          })),
                          onSelect: (versionNumber) =>
                              navigate(
                                  getRoute({
                                      path: ROUTES.dimr.view,
                                      params: {
                                          id: data.dimrData.dimrMasterId,
                                          version: versionNumber,
                                      },
                                  })
                              ),
                      }
                    : undefined
            }
            status={variant === DIMRPageVariant.READ ? getDimrStatus(data.dimrData, intl) : undefined}
            creation={variant === DIMRPageVariant.READ ? data.dimrData : undefined}
            {...rest}
        />
    );
};
