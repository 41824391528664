import {
    AccessTime,
    Build,
    ElectricalServices,
    Email,
    Groups,
    Person,
    Send,
    Settings,
    Warehouse,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import {
    DISTRIBUTION_TYPES,
    getNdcFacilityCoordinators,
    getNdcInterventionTypes,
    getNdcNetworks,
    getNdcOccupantsOfBuildings,
    getNdcOccupantsOfBuildingsLevels,
    getNdcTSOsAndDTSOs,
    getNdcTypes,
    NdcDistributionTargetFormValue,
} from '../../../api/documents/noteDeCoupures';
import { EgroupDTO, PersonReadDTO } from '../../../api/dto';
import { getLanguages } from '../../../api/values';
import { EgroupList, PersonList } from '../../../components/PeopleList';
import { fetchEgroups, fetchPeopleByName } from '../../../lib/api';
import { getEgroupLabel, getLovLabel, getPersonLabel } from '../../../lib/label';
import { makeDistributionTargetsFormValues } from '../../../lib/records/documents/noteDeCoupure';
import { FetchUseCase } from '../../fields/AsyncAutocompleteElement';
import { DistributionList } from '../../fields/specific/DistributionList';
import { TFormElement } from '../../types';
import { UiSchemaType } from '../../UiSchemaType';
import { equipmentSchema } from '../equipment';
import { locationSchema } from '../location';
import { orgUnitSchema } from '../orgUnit';
import { personSchema } from '../person';
import { valueSchema } from '../value';

const uiSchemaLocationOfIntervention = (
    { allowAnyLocation }: { allowAnyLocation: boolean },
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            md: 6,
            lg: 4,
            element: locationSchema({
                label: intl.formatMessage({ id: 'document.field.location' }),
                path: 'location',
                allowAnyLocation,
            }),
        },
        {
            xs: 12,
            md: 6,
            lg: 4,
            element: equipmentSchema({
                label: intl.formatMessage({ id: 'document.field.equipment' }),
                path: 'equipment',
                multiple: true,
                active: true,
            }),
        },
        {
            xs: 12,
            lg: 4,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'ndc.field.locationEquipmentDesignation' }),
                path: 'designation',
            },
        },
    ],
});

const uiSchemaAffectedNetwork = (
    { allowAnyLocation }: { allowAnyLocation: boolean },
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.LAYOUT_GRID,
    elements: [
        {
            xs: 12,
            md: 6,
            lg: 4,
            element: locationSchema({
                label: intl.formatMessage({ id: 'document.field.location' }),
                path: 'location',
                allowAnyLocation,
            }),
        },
        {
            xs: 12,
            md: 6,
            lg: 4,
            element: {
                type: UiSchemaType.AUTOCOMPLETE_ASYNC,
                label: intl.formatMessage({ id: 'ndc.field.networks' }),
                path: 'affectedNetworks',
                typeProperties: {
                    fetchOptions: (searchTerm, exactId, idField, values) => {
                        return values ? getNdcNetworks({ orgUnit: values[0].id }) : getNdcNetworks();
                    },
                    fetchUseCases: [FetchUseCase.ON_LOAD, FetchUseCase.ON_OPEN],
                    getOptionLabel: getLovLabel,
                    multiple: true,
                    watchPaths: ['responsibleGroup'],
                },
            },
        },
        {
            xs: 12,
            lg: 4,
            element: equipmentSchema({
                label: intl.formatMessage({ id: 'document.field.equipment' }),
                path: 'equipment',
                multiple: true,
                active: true,
            }),
        },
        {
            xs: 12,
            element: {
                type: UiSchemaType.INPUT,
                label: intl.formatMessage({ id: 'ndc.field.locationNetworksEquipmentComplementaryInformation' }),
                path: 'complementaryInformation',
                typeProperties: {
                    multiline: true,
                },
            },
        },
    ],
});

export const uiSchemaNoteDeCoupureFor = (
    { isDone, allowAnyLocation }: { isDone: boolean; allowAnyLocation: boolean },
    intl: IntlShape
): TFormElement => ({
    type: UiSchemaType.LAYOUT_VERTICAL,
    elements: [
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.general' }),
            typeProperties: {
                icon: <Settings color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        sm: 6,
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'ndc.field.interventionType' }),
                                path: 'interventionType',
                                fetchOptions: getNdcInterventionTypes,
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: valueSchema(
                            {
                                label: intl.formatMessage({ id: 'document.field.language' }),
                                path: 'language',
                                fetchOptions: getLanguages,
                            },
                            intl.locale
                        ),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'ndc.field.cutExecutor' }),
                            path: 'cutExecutor',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: orgUnitSchema({
                            label: intl.formatMessage({ id: 'document.field.responsibleGroup' }),
                            path: 'responsibleGroup',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'ndc.field.requestedBy' }),
                            path: 'requestedBy',
                        }),
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: personSchema({
                            label: intl.formatMessage({ id: 'ndc.field.approvedBy' }),
                            path: 'approvedBy',
                            readOnly: true,
                        }),
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'ndc.panel.what' }),
            typeProperties: {
                icon: <Build color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.LAYOUT_GRID,
                        elements: [
                            {
                                xs: 12,
                                lg: 6,
                                element: {
                                    type: UiSchemaType.INPUT,
                                    label: intl.formatMessage({ id: 'document.field.title' }),
                                    path: 'title',
                                },
                            },
                            {
                                xs: 12,
                                lg: 6,
                                element: {
                                    type: UiSchemaType.INPUT,
                                    label: intl.formatMessage({ id: 'document.field.subtitle' }),
                                    path: 'subtitle',
                                },
                            },
                        ],
                    },
                    {
                        type: UiSchemaType.RICH_TEXT,
                        label: intl.formatMessage({ id: 'ndc.field.workToBePerformed' }),
                        path: 'workToBePerformed',
                    },
                    {
                        type: UiSchemaType.RICH_TEXT,
                        label: intl.formatMessage({ id: 'ndc.field.specialInstructions' }),
                        path: 'note',
                    },
                    valueSchema(
                        {
                            label: intl.formatMessage({ id: 'ndc.field.type' }),
                            path: 'severity',
                            fetchOptions: getNdcTypes,
                        },
                        intl.locale
                    ),
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'ndc.panel.when' }),
            typeProperties: {
                icon: <AccessTime color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_GRID,
                elements: [
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: intl.formatMessage({ id: 'document.field.startDate' }),
                            path: 'startDate',
                            typeProperties: {
                                minDate: new Date(),
                            },
                        },
                    },
                    {
                        xs: 12,
                        sm: 6,
                        element: {
                            type: UiSchemaType.DATETIME,
                            label: intl.formatMessage({ id: 'document.field.endDate' }),
                            path: 'endDate',
                            typeProperties: {
                                minDate: new Date(),
                            },
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'ndc.panel.locationsOfIntervention' }),
            typeProperties: {
                icon: <Warehouse color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.FIELD_ARRAY,
                        path: 'locations',
                        element: uiSchemaLocationOfIntervention({ allowAnyLocation }, intl),
                        appendLabel: intl.formatMessage({ id: 'ndc.field.locationOfIntervention.add' }),
                        removeLabel: intl.formatMessage({ id: 'ndc.field.locationOfIntervention.remove' }),
                        initialValue: {
                            location: null,
                            equipment: [],
                            designation: null,
                        },
                    },
                ],
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'ndc.panel.affectedNetworksAndEquipment' }),
            typeProperties: {
                icon: <ElectricalServices color="action" />,
            },
            element: {
                type: UiSchemaType.FIELD_ARRAY,
                path: 'affectedNetworksAndEquipment',
                element: uiSchemaAffectedNetwork({ allowAnyLocation }, intl),
                appendLabel: intl.formatMessage({ id: 'ndc.field.affectedNetwork.add' }),
                removeLabel: intl.formatMessage({ id: 'ndc.field.affectedNetwork.remove' }),
                cloneable: true,
                cloneLabel: intl.formatMessage({ id: 'ndc.field.affectedNetwork.clone' }),
                initialValue: {
                    location: null,
                    affectedNetworks: [],
                    equipment: [],
                    complementaryInformation: null,
                },
            },
        },
        {
            type: UiSchemaType.PANEL,
            label: intl.formatMessage({ id: 'document.panel.distribution' }),
            typeProperties: {
                icon: <Send color="action" />,
            },
            element: {
                type: UiSchemaType.LAYOUT_VERTICAL,
                elements: [
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: intl.formatMessage({ id: 'ndc.field.distribution.fireRescue' }),
                        path: 'fireService',
                        disabled: true,
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: intl.formatMessage({ id: 'ndc.field.distribution.ti' }),
                        path: 'controlRoom',
                        disabled: true,
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: intl.formatMessage({ id: 'ndc.field.distribution.facilityCoordinators' }),
                        path: 'facilityCoordinatorsAffected',
                        typeProperties: {
                            endAdornment: ({ field, disabled }) => (
                                <PersonList
                                    title={intl.formatMessage({ id: 'ndc.field.distribution.facilityCoordinators' })}
                                    fetchElements={(ndc) => getNdcFacilityCoordinators({ data: ndc })}
                                    path="facilityCoordinatorsAffected"
                                    hidden={!field.value || isDone} // Hidden if done, because the computed list may change
                                    disabled={disabled}
                                />
                            ),
                        },
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: intl.formatMessage({ id: 'ndc.field.distribution.occupantsBuildings' }),
                        path: 'bldgOccupantsAffected',
                        typeProperties: {
                            endAdornment: ({ field, disabled }) => (
                                <EgroupList
                                    title={intl.formatMessage({ id: 'ndc.field.distribution.occupantsBuildings' })}
                                    fetchElements={(ndc) => getNdcOccupantsOfBuildings({ data: ndc })}
                                    path="bldgOccupantsAffected"
                                    hidden={!field.value || isDone}
                                    disabled={disabled}
                                />
                            ),
                        },
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: intl.formatMessage({ id: 'ndc.field.distribution.occupantsLevels' }),
                        path: 'bldgLevelsOccupantsAffected',
                        typeProperties: {
                            endAdornment: ({ field, disabled }) => (
                                <EgroupList
                                    title={intl.formatMessage({ id: 'ndc.field.distribution.occupantsLevels' })}
                                    fetchElements={(ndc) => getNdcOccupantsOfBuildingsLevels({ data: ndc })}
                                    path="bldgLevelsOccupantsAffected"
                                    hidden={!field.value || isDone}
                                    disabled={disabled}
                                />
                            ),
                        },
                    },
                    {
                        type: UiSchemaType.CHECKBOX,
                        label: intl.formatMessage({ id: 'ndc.field.distribution.tsoDtso' }),
                        path: 'bldgTsosAndDtsosAffected',
                        typeProperties: {
                            endAdornment: ({ field, disabled }) => (
                                <PersonList
                                    title={intl.formatMessage({ id: 'ndc.field.distribution.tsoDtso' })}
                                    fetchElements={(ndc) => getNdcTSOsAndDTSOs({ data: ndc })}
                                    path="bldgTsosAndDtsosAffected"
                                    hidden={!field.value || isDone}
                                    disabled={disabled}
                                />
                            ),
                        },
                    },
                    {
                        type: UiSchemaType.AUTOCOMPLETE_ASYNC,
                        label: intl.formatMessage({ id: 'ndc.field.additionalTargets' }),
                        path: 'distributionTargets',
                        typeProperties: {
                            multiple: true,
                            fetchOptions: async (searchTerm): Promise<NdcDistributionTargetFormValue[]> => {
                                const persons: PersonReadDTO[] = await fetchPeopleByName(searchTerm, false);
                                const eGroups: EgroupDTO[] = await fetchEgroups(searchTerm);
                                const emailValidator = yup.string().email();
                                const isEmail = await emailValidator.isValid(searchTerm);

                                return isEmail
                                    ? [
                                          ...makeDistributionTargetsFormValues([searchTerm], DISTRIBUTION_TYPES.EMAIL),
                                          ...makeDistributionTargetsFormValues(persons, DISTRIBUTION_TYPES.PERSON),
                                          ...makeDistributionTargetsFormValues(eGroups, DISTRIBUTION_TYPES.EGROUP),
                                      ]
                                    : [
                                          ...makeDistributionTargetsFormValues(persons, DISTRIBUTION_TYPES.PERSON),
                                          ...makeDistributionTargetsFormValues(eGroups, DISTRIBUTION_TYPES.EGROUP),
                                      ];
                            },
                            fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
                            getOptionLabel: (option: NdcDistributionTargetFormValue) => {
                                return getPersonLabel(option.value) || getEgroupLabel(option.value) || option.value;
                            },
                            autocompleteProps: {
                                isOptionEqualToValue: (
                                    option: NdcDistributionTargetFormValue,
                                    value: NdcDistributionTargetFormValue
                                ) => {
                                    return option.value.id === value.value.id || option.value === value.value;
                                },
                                renderOption: (optionProps, option: NdcDistributionTargetFormValue) => {
                                    const labelers = [getPersonLabel, getEgroupLabel, (o: string) => o];
                                    const icons = [<Person />, <Groups />, <Email />];

                                    let label, icon;
                                    for (const i in labelers) {
                                        label = labelers[i](option.value);
                                        if (label) {
                                            icon = icons[i];
                                            break;
                                        }
                                    }

                                    return (
                                        <li {...optionProps}>
                                            {icon}
                                            <Typography sx={{ ml: 1 }}>{label}</Typography>
                                        </li>
                                    );
                                },
                            },
                        },
                    },
                    {
                        type: UiSchemaType.CUSTOM,
                        Component: () => <DistributionList done={isDone} />,
                    },
                ],
            },
        },
    ],
});
