import { getCompanies } from '../../api/documents/firePermits';
import { ActivityReadDTO } from '../../api/dto';
import { getCompanyLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

const fpCompanyTypeProperties = (): AsyncAutocompleteFormElement['typeProperties'] => ({
    idField: 'id',
    fetchOptions: (searchTerm, exactId, idField, values) =>
        getCompanies({ activityId: values ? (values[0] as ActivityReadDTO).id : undefined }),
    watchPaths: ['activity'],
    fetchUseCases: [FetchUseCase.ON_OPEN, FetchUseCase.ON_LOAD], // "On open" since it depends on the FP location
    getOptionLabel: (value) => getCompanyLabel(value),
});

interface FpCompanySchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {}

export const fpCompanySchema = ({ path, label, required }: FpCompanySchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    path,
    label,
    required,
    typeProperties: fpCompanyTypeProperties(),
});
