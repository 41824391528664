import { useLayoutEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FieldAttributes, PreciseRulesDTO, registerValidator } from './deserializer';

export const useRules = <S extends Record<string, any>>(
    form: UseFormReturn<any>,
    rules: PreciseRulesDTO<S> | undefined,
    originalValues: any,
    context: S | undefined
) => {
    const [attributes, setAttributes] = useState<Record<string, FieldAttributes>>({});
    // To avoid blinking, we use `useLayoutEffect` instead of `useEffect`
    // This ensures that we don't perform a re-render until we have properly determined the attributes
    useLayoutEffect(() => {
        if (rules !== undefined) {
            if (context === undefined) {
                throw new Error();
            }
            const register = (callback: (data: any, name: string | null) => Record<string, FieldAttributes>) => {
                // Initial trigger
                setAttributes(callback(form.getValues(), null));
                const { unsubscribe } = form.watch((data, { name, type }) => {
                    const newAttributes = callback(data, name ?? null);
                    setAttributes(newAttributes);
                });
                return unsubscribe;
            };
            return form !== null
                ? registerValidator(register, form.setValue, rules, originalValues, context)
                : () => {};
        }
    }, [form, form?.watch, form?.getValues, form?.setValue, rules, originalValues, context, setAttributes]);
    return attributes;
};
