import { AxiosResponse } from 'axios';
import { buildPath } from '../../lib/url';
import { backendClient as client } from '../clients';
import { ListOfValuesReadDTO, VicGridReadDTO, VicReadDTO } from '../dto';
import { GetManyPaged, GetManyUnpaged, GetOne } from '../types';

export const VIC_PATH = 'vic';

export const getVic: GetOne<{ id: string }, VicReadDTO> = async ({ id, config }) =>
    client.get(buildPath([VIC_PATH, id]), config).then((response: AxiosResponse) => response.data);

export const getVics: GetManyPaged<VicGridReadDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([VIC_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getVicStatuses: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'statuses']), config).then((response: AxiosResponse) => response.data);

export const getVicApplicability: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'applicabilities']), config).then((response: AxiosResponse) => response.data);

export const getVicDistributionTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'distribution-types']), config).then((response: AxiosResponse) => response.data);

export const getVicSafetyDocs: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client.get(buildPath([VIC_PATH, 'safety-docs']), config).then((response: AxiosResponse) => response.data);

export const getVicParticipantCompanyTypes: GetManyUnpaged<{}, ListOfValuesReadDTO> = async ({ config } = {}) =>
    client
        .get(buildPath([VIC_PATH, 'participant-company-types']), config)
        .then((response: AxiosResponse) => response.data);
