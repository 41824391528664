import {
    Article,
    AttachFile,
    Cancel,
    CheckCircle,
    ContentCopy,
    DoDisturb,
    MenuBook,
    Print,
    Replay,
    SaveSharp,
    Send,
    StopCircle,
} from '@mui/icons-material';
import { Menu, MenuItem, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { ResponsiveButton } from '../ResponsiveButton';

interface DocumentButtonsStackContextSchema {
    disabled: boolean;
    disablingReason: string;
}
const DocumentButtonsStackContext = React.createContext<DocumentButtonsStackContextSchema | undefined>(undefined);

interface DocumentButtonsStackActionProps {
    icon: JSX.Element;
    titleKey: MessageDescriptor['id'];
    important?: boolean;
    onClick?: () => void;
    options?: { label: string; onClick: () => void }[];
    disabled?: boolean;
    disablingReason?: string;
    large?: boolean;
    split?: boolean;
}

const DocumentButtonsStackAction: React.FC<DocumentButtonsStackActionProps> = ({
    icon,
    titleKey,
    important,
    onClick,
    options,
    disabled = false,
    disablingReason = '',
    large = false,
    split = false,
}) => {
    const stackContext = useContext(DocumentButtonsStackContext);
    if (stackContext === undefined) {
        throw new Error();
    }
    const { disabled: stackDisabled, disablingReason: stackDisablingReason } = stackContext;
    const isButtonDisabled = stackDisabled || disabled;
    const reason = stackDisabled ? stackDisablingReason : disablingReason;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = !!anchorEl;
    const dropdown = !!options;

    const handleClickExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (dropdown) {
            setAnchorEl(anchorEl === null ? event.currentTarget : null);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOptionClick = (handler: () => void) => () => {
        handleClose();
        handler();
    };

    return (
        <>
            <ResponsiveButton
                icon={icon}
                buttonVariant={important ? 'contained' : 'outlined'}
                disabled={isButtonDisabled}
                onClick={onClick}
                onClickExpand={handleClickExpand}
                tooltipText={isButtonDisabled ? reason : ''}
                dropdown={dropdown}
                large={large}
                split={split}
            >
                <FormattedMessage id={titleKey} />
            </ResponsiveButton>
            {dropdown && (
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {options.map(({ label, onClick: onOptionClick }, i) => (
                        <MenuItem key={i} onClick={handleOptionClick(onOptionClick)}>
                            {label}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

interface DocumentButtonsStackProps {
    disabled?: boolean;
    disablingReason?: string;
    children?: React.ReactNode;
}

export const DocumentButtonsStack: React.FC<DocumentButtonsStackProps> & {
    Action: typeof DocumentButtonsStackAction;
} = ({ disabled = false, children, disablingReason = '' }) => {
    return (
        <DocumentButtonsStackContext.Provider value={{ disabled: disabled, disablingReason: disablingReason }}>
            <Stack direction="row" spacing={1}>
                {children}
            </Stack>
        </DocumentButtonsStackContext.Provider>
    );
};

DocumentButtonsStack.Action = DocumentButtonsStackAction;

export const COMMON_DOCUMENT_BUTTONS = {
    SAVE: { icon: <SaveSharp />, titleKey: 'document.action.save', important: true },
    SUBMIT: { icon: <Send />, titleKey: 'document.action.submit', important: true },
    REFRESH: { icon: <Replay />, titleKey: 'document.action.refresh' },
    APPROVE: { icon: <CheckCircle />, titleKey: 'document.action.approve', important: true },
    REJECT: { icon: <Cancel />, titleKey: 'document.action.reject', important: false },
    CLONE: { icon: <ContentCopy />, titleKey: 'document.action.clone' },
    PREVIEW: { icon: <Print />, titleKey: 'document.action.preview' },
    PRINT: { icon: <Print />, titleKey: 'document.action.print' },
    CHANGE: { icon: <Article />, titleKey: 'document.action.change' },
    ATTACHMENTS: { icon: <AttachFile />, titleKey: 'document.action.attachments' },
    VERSIONS: { icon: <MenuBook />, titleKey: 'document.action.versions' },
    CANCEL: { icon: <DoDisturb />, titleKey: 'document.action.cancel' },
    FINISH: { icon: <StopCircle />, titleKey: 'document.action.finish' },
} as const;
