import { NdcStatusCode } from '../../api/dto';
import { StatusColor } from './StatusColor';

export const NdcStatusColorMapping: Record<NdcStatusCode, StatusColor> = {
    [NdcStatusCode.SAVED]: StatusColor.COLOR_PRIMARY,
    [NdcStatusCode.WAITING_FOR_APPROVAL]: StatusColor.COLOR_WARNING,
    [NdcStatusCode.APPROVED]: StatusColor.COLOR_SUCCESS,
    [NdcStatusCode.DISTRIBUTED]: StatusColor.COLOR_SUCCESS,
    [NdcStatusCode.IN_PROGRESS]: StatusColor.COLOR_SUCCESS,
    [NdcStatusCode.REJECTED]: StatusColor.COLOR_ERROR,
    [NdcStatusCode.CANCELLED]: StatusColor.COLOR_ERROR,
    [NdcStatusCode.WAITING_NOTIFY_CANCEL]: StatusColor.COLOR_ERROR,
    [NdcStatusCode.FINISHED]: StatusColor.COLOR_NORMAL,
    [NdcStatusCode.WAITING_NOTIFY_FINISH]: StatusColor.COLOR_NORMAL,
};
