import { BorderAll } from '@mui/icons-material';
import { IntlShape } from 'react-intl';
import { WdpVersionDimrReadDTO, WdpVersionGridReadDTO } from '../../api/dto';
import { getRoute, ROUTES } from '../../routes/routes';
import { getLovLabel } from '../label';
import { translateValue } from '../language';
import { getWdpStatus } from '../status';
import { EntityInformation } from './types/EntityInformation';

export const WdpIcon = BorderAll;

export const getWdpInformation = (
    wdp: WdpVersionGridReadDTO | WdpVersionDimrReadDTO,
    intl: IntlShape
): EntityInformation => {
    return {
        url: getRoute({ path: ROUTES.wdp.view, params: { id: wdp.wdpMasterId, version: wdp.versionNumber } }),
        name: intl.formatMessage(
            { id: 'wdp.titleExisting' },
            { masterId: wdp.wdpMasterId, versionNumber: wdp.versionNumber }
        ),
        status: getWdpStatus(wdp, intl),
        title: wdp.title,
        details: [
            ...('dimr' in wdp && wdp.dimr && wdp.dimr.alaraLevel
                ? [
                      {
                          labelKey: 'document.field.alara' as const,
                          value: getLovLabel(translateValue(wdp.dimr.alaraLevel, intl.locale)),
                          type: true,
                      },
                  ]
                : []),
        ],
    };
};
