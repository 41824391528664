import { getIs37s } from '../../api/documents/is37s';
import { makeIs37Filter } from '../../lib/filters/is37';
import { getIs37Label } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface Is37SchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
}

export const is37Schema = ({ path, label, required, multiple }: Is37SchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: {
        fetchOptions: async (query) => {
            const result = await getIs37s({ filter: makeIs37Filter(query) });
            return result.content;
        },
        fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
        multiple,
        getOptionLabel: getIs37Label,
        autocompleteProps: {
            forcePopupIcon: false,
        },
    },
});
