import {
    Cell,
    CellStyle,
    CellTemplate,
    Compatible,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid';
import React from 'react';
import { AutocompleteElement } from '../../forms/fields/AutocompleteElement';

const cellStyle: CellStyle = {};

export interface BaseDropdownCell extends Cell {
    type: 'multiautocomplete' | 'autocomplete';
    values: unknown[];
    selectedValue: string | undefined;
    idKey: string;
    labelKey: string;
    setData: (data: unknown[]) => void;
}

export interface MultiDropdownCell extends BaseDropdownCell {
    type: 'multiautocomplete';
    valueList: unknown[];
}

export interface DropdownCell extends BaseDropdownCell {
    type: 'autocomplete';
    value: unknown;
}

export type DropdownsCell = MultiDropdownCell | DropdownCell;

export class MultiDropdownCellTemplate implements CellTemplate<MultiDropdownCell> {
    getCompatibleCell(uncertainCell: Uncertain<MultiDropdownCell>): Compatible<MultiDropdownCell> {
        const { valueList, values, idKey, labelKey, setData, ...cell } = uncertainCell;
        const value = NaN;
        return {
            ...cell,
            text: '',
            value,
            valueList: valueList!,
            idKey: idKey!,
            labelKey: labelKey!,
            values: values!,
            setData: setData!,
            selectedValue: undefined,
        };
    }

    isFocusable?(cell: Compatible<MultiDropdownCell>): boolean {
        // console.log(cell);
        // throw new Error('Method not implemented2.');
        return true;
    }

    update?(
        cell: Compatible<MultiDropdownCell>,
        cellToMerge: UncertainCompatible<MultiDropdownCell>
    ): Compatible<MultiDropdownCell> {
        //TODO
        //throw new Error('Method not implemented2.');
        // console.log('cell, cellToMerge');
        // console.log(cell, cellToMerge);
        return { ...cell, ...cellToMerge };
    }

    handleKeyDown?(
        cell: Compatible<MultiDropdownCell>,
        keyCode: number,
        ctrl: boolean,
        shift: boolean,
        alt: boolean,
        key?: string | undefined
    ): { cell: Compatible<MultiDropdownCell>; enableEditMode: boolean } {
        if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
            return { cell: this.getCompatibleCell(cell), enableEditMode: false };
        return { cell, enableEditMode: false };
    }

    handleCompositionEnd?(
        cell: Compatible<MultiDropdownCell>,
        eventData: string
    ): { cell: Compatible<MultiDropdownCell>; enableEditMode: boolean } {
        throw new Error('Method not implemented4.');
    }

    getStyle?(cell: Compatible<MultiDropdownCell>, isInEditMode: boolean): CellStyle {
        return cellStyle;
    }

    getClassName?(cell: Compatible<MultiDropdownCell>, isInEditMode: boolean): string {
        return 'multidropdown';
    }

    render(
        cell: Compatible<MultiDropdownCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<MultiDropdownCell>, commit: boolean) => void
    ): React.ReactNode {
        return (
            <div
                style={{ width: '100%', height: '100%', marginTop: '8px', overflowY: 'scroll' }}
                // onPointerDown={(e) => onCellChanged({ ...cell, isOpen: true }, true)}
            >
                <AutocompleteElement
                    field={{
                        name: '',
                        onBlur: () => 1,
                        onChange: cell.setData,
                        value: cell.valueList,
                        ref: () => 1,
                    }}
                    disabled={cell.nonEditable}
                    strongValidateOptions={true}
                    fieldState={{
                        invalid: false,
                        isTouched: false,
                        isDirty: false,
                    }}
                    idField={cell.idKey}
                    labelField={cell.labelKey}
                    options={cell.values}
                    multiple={true}
                    matchId={false}
                    autocompleteProps={{
                        fullWidth: true,
                        size: 'small',
                        openOnFocus: true,
                    }}
                />
            </div>
        );
    }
}
