import { AxiosResponse } from 'axios';
import { buildPath } from '../lib/url';
import { backendClient as client } from './clients';
import { LocationDTO, LocationNodeDTO } from './dto';
import { GetManyPaged, GetManyUnpaged, GetOne } from './types';

const RESOURCE_PATH = 'locations';

export type LocationType =
    | 'BARAQUE'
    | 'BATIMENT'
    | 'CUSTOM_EXPERIMENT'
    | 'CUSTOM_ZONE SÉCURITÉ'
    | 'EQUIP_SOUT'
    | 'ESPACE_VERT'
    | 'ETAGE'
    | 'GROUPING_FACILITY'
    | 'GROUPING_FACILITY_NODE'
    | 'LOCAL'
    | 'MACHINE'
    | 'OUVRAGE'
    | 'VOIRIE'
    | 'ZONE';

export type LocationWithNodeDTO = LocationDTO & { node: LocationNodeDTO };

export const getLocation: GetOne<{ id: string }, LocationDTO> = async ({ id, config }) =>
    client.get(buildPath([RESOURCE_PATH, id]), config).then((response) => response.data);

export const getLocations: GetManyPaged<LocationDTO> = async ({ filter, page, size, sort, config } = {}) =>
    client
        .get(buildPath([RESOURCE_PATH]), { ...config, params: { filter, page, size, sort } })
        .then((response: AxiosResponse) => response.data);

export const getLocationsByName: GetManyUnpaged<
    { query: string; onlySelectable: boolean | undefined },
    LocationDTO
> = async ({ query, onlySelectable, config }) =>
    client
        .get(buildPath([RESOURCE_PATH, 'selectable']), { ...config, params: { query, onlySelectable } })
        .then((response: AxiosResponse) => response.data);

export const getLocationRoots: GetManyUnpaged<{}, LocationWithNodeDTO> = async () =>
    client.get(buildPath([RESOURCE_PATH, 'roots'])).then((response: AxiosResponse) => response.data);

export const getLocationChildren: GetManyUnpaged<{ parent: LocationWithNodeDTO }, LocationWithNodeDTO> = async ({
    parent,
}) => client.get(buildPath([RESOURCE_PATH, parent.id, 'children'])).then((response: AxiosResponse) => response.data);

export const isDescendantOfProvidedLocations: GetOne<
    { location: LocationDTO; locationsList: LocationDTO[] },
    boolean
> = async ({ location, locationsList, config }) =>
    client
        .post(buildPath([RESOURCE_PATH, location.id, 'isDescendant']), locationsList, config)
        .then((response: AxiosResponse) => response.data);
