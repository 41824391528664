import { Delete, Edit } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import * as React from 'react';
import { deleteActivityComment, updateActivityComment } from '../../api/activities';
import { ActivityCommentDTO } from '../../api/dto';

interface ActivityCommentProps {
    comment: ActivityCommentDTO;
    reloadComments: () => void | Promise<void>;
}
export const ActivityComment: React.FC<ActivityCommentProps> = ({ comment, reloadComments }) => {
    const [editMode, setEditMode] = React.useState(false);
    const [modifiedText, setModifiedText] = React.useState(comment.content);
    const canModify = true;

    const startEdit = () => {
        setEditMode(true);
    };
    const endEdit = () => {
        setEditMode(false);
    };

    const handleTextEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModifiedText(event.target.value);
    };
    const updateComment = async () => {
        endEdit();
        if (modifiedText !== comment.content) {
            await updateActivityComment({
                activity: comment.activity ?? '',
                commentId: comment.id,
                content: modifiedText ?? '',
            });
            reloadComments();
        }
    };
    const deleteComment = async () => {
        endEdit();
        await deleteActivityComment({ activity: comment.activity ?? '', commentId: comment.id });
        reloadComments();
    };

    return (
        <Card>
            <CardHeader
                avatar={<Avatar>{comment.createdBy?.id?.toString()?.substring(0, 2)}</Avatar>}
                action={
                    editMode ? (
                        <IconButton onClick={deleteComment}>
                            <Delete />
                        </IconButton>
                    ) : (
                        <IconButton onClick={startEdit} disabled={!canModify}>
                            <Edit />
                        </IconButton>
                    )
                }
                title={comment.createdBy?.id}
                subheader={comment.createdDate && format(new Date(comment.createdDate), "'Posted on' y/MMM/d H:mm")}
            />
            <Divider />
            <CardContent>
                {editMode ? (
                    <>
                        <TextField
                            autoFocus
                            fullWidth
                            multiline
                            maxRows={4}
                            variant="standard"
                            defaultValue={comment.content}
                            onChange={handleTextEdit}
                            onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                                const end = event.target.value.length;
                                event.target.setSelectionRange(end, end);
                            }}
                            onKeyDown={(e) => {
                                if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
                                    updateComment();
                                }
                            }}
                        />
                        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button onClick={endEdit}>Cancel</Button>
                            <Button onClick={updateComment}>Save</Button>
                        </Box>
                    </>
                ) : (
                    <Typography>{comment.content}</Typography>
                )}
            </CardContent>
        </Card>
    );
};
