import { getFirePermits } from '../../api/documents/firePermits';
import { makeFirePermitFilter } from '../../lib/filters/firePermit';
import { getFirePermitLabel } from '../../lib/label';
import { FetchUseCase } from '../fields/AsyncAutocompleteElement';
import { AsyncAutocompleteFormElement, TFormElement } from '../types';
import { UiSchemaType } from '../UiSchemaType';

interface FirePermitSchemaParameters extends Pick<AsyncAutocompleteFormElement, 'path' | 'label' | 'required'> {
    multiple?: boolean;
}

export const firePermitSchema = ({ path, label, required, multiple }: FirePermitSchemaParameters): TFormElement => ({
    type: UiSchemaType.AUTOCOMPLETE_ASYNC,
    label,
    path,
    required,
    typeProperties: {
        fetchOptions: async (query) => {
            const result = await getFirePermits({ filter: makeFirePermitFilter(query) });
            return result.content;
        },
        fetchUseCases: [FetchUseCase.ON_KEYSTROKE],
        multiple,
        getOptionLabel: getFirePermitLabel,
        autocompleteProps: {
            forcePopupIcon: false,
        },
    },
});
